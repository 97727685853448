import React from 'react';
import {connect} from 'react-redux';
import {get, set, isEqual, find, filter, isEqualWith, cloneDeep} from 'lodash';
import {isInternalUser} from '@peoplenet/vi-components-ui/src/app/user';
import {postReview, hidePostSuccess} from '../../actions/player';

export const editedReview = (oldReview, newReview) => {
  const editedOtherCategories = !isEqual(oldReview.otherCategories, newReview.otherCategories);
  const editedMainCategories = !isEqualWith(get(oldReview, 'mainCategory.reviewOptions'), get(newReview, 'mainCategory.reviewOptions'), (oldValue, newValue) => {
    let oldReviews = find(oldValue, {custom: true});
    let newReviews = find(newValue, {custom: true});
    const customEquals = oldReviews && newReviews &&
      isEqualWith(oldReviews, newReviews, (oldCustom, newCustom) => isEqual(oldCustom.name || '', newCustom.name || ''));
    oldReviews = filter(oldValue, it => !it.custom);
    newReviews = filter(newValue, it => !it.custom);
    const notCustomEquals = isEqual(oldReviews, newReviews);
    return customEquals && notCustomEquals;
  });
  const editedReviewComplete = !isEqual(oldReview.completed, newReview.completed);
  return editedOtherCategories || editedMainCategories || editedReviewComplete;
};

class Review extends React.Component {
  constructor(props) {
    super(props);
    const {review} = props;
    this.state = {
      review: {...cloneDeep(review)}
    };
  }

  handleCheckReviewCompleted = e => {
    const {review} = this.state;
    set(review, 'completed', e.target.checked);
    this.updateReview(review);
  }

  cancelEditReview = () => {
    const {review} = this.props;

    this.setState({
      review: {
        ...cloneDeep(review),
        showReviewSubmittedChip: false
      }
    });
  }

  handleReviewSubmit = () => {
    const {user, media, coach} = this.props;
    const {review} = this.state;
    this.props.postReview({
      review,
      user,
      media,
      coach
    });
  };

  handleCustomReviewChange = (value, scoring) => {
    const {review} = this.state;
    const {mainCategory: {reviewOptions}} = review;
    const customField = reviewOptions.find(o => o.custom);
    if (customField) {
      customField.name = value;
      customField.points = scoring ? 5 : 0;
      customField.marked = !!value;
    }
    this.updateReview(review);
  }

  updateReview = newReviewState => {
    const edited = editedReview(this.props.review, newReviewState);
    this.setState({review: {...newReviewState, edited}});
  }

  handleCheckReviewOption = (categoryName, name) => {
    const {review} = this.state;
    const {mainCategory, otherCategories} = review;
    let {reviewOptions} = mainCategory;
    if (categoryName !== mainCategory.categoryName) {
      ({reviewOptions} = otherCategories.find(o => o.categoryName === categoryName));
    }

    const item = reviewOptions.find(o => o.name === name);
    if (item) {
      item.marked = !item.marked;
    }

    this.updateReview(review);
  }

  openCloseReviewSubmittedChip = () => {
    const {review} = this.state;
    set(review, 'showReviewSubmittedChip', !get(review, 'showReviewSubmittedChip', false));
    this.setState(review);
    this.props.hidePostSuccess();
  }

  render() {
    if (!this.props.visible) return null;
    const {props} = this;
    if (!this.state.review || !this.props.review) {
      return null;
    }

    // Get component as props so we can mount different components based on view mode
    const {component: Component} = props;
    return (
      <Component
        tabIndex={props.tabIndex}
        media={props.media}
        address={get(props, 'media.address', {})}
        review={this.state.review}
        textSearch={props.textSearch}
        onChanteTextSearch={props.onChanteTextSearch}
        onChangePage={props.onChangePage}
        currentPage={props.currentPage}
        onSearchReview={props.onSearchReview}
        onChangeReviewSort={props.onChangeReviewSort}
        onLoadReview={props.onLoadReview}
        getSortFieldDirection={props.getSortFieldDirection}
        onChangeHeaderVisibility={props.onChangeHeaderVisibility}
        isSameSortField={props.isSameSortField}
        previouslyReviews={props.previouslyReviews}
        previouslyReviewsTotal={props.previouslyReviewsTotal}
        searchingReviews={props.searchingReviews}
        searchingMoreReviews={props.searchingMoreReviews}
        savedReview={props.review}
        coach={props.coach}
        canSubmit={!props.isInternalSalesUser}
        handleCheckReviewOption={this.handleCheckReviewOption}
        handleCustomReviewChange={this.handleCustomReviewChange}
        handleReviewSubmit={this.handleReviewSubmit}
        openCloseReviewSubmittedChip={this.openCloseReviewSubmittedChip}
        handleCheckReviewCompleted={this.handleCheckReviewCompleted}
        cancelEditReview={this.cancelEditReview}
        showScore={get(props, 'customer.data.settings.scoring', false)}
      />
    );
  }
}


function mapStateToProps(state) {
  const {player, customer, user} = state;
  return {
    ...player,
    customer,
    user,
    isInternalSalesUser: isInternalUser(),
    locale: state.i18n.locale
  };
}

export default connect(mapStateToProps, {
  postReview,
  hidePostSuccess
})(Review);
