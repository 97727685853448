/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {Icon} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {filter, each, replace, padStart, get, cloneDeep} from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoCompleteInput from '@peoplenet/vi-components-ui/src/Form/AutoCompleteInput';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import ChannelsAvailable from './ChannelsAvailable'; // eslint-disable-line import/no-named-as-default
import TextMaskMinuteSeconds from '../component/TextMaskMinuteSeconds';
import {MACHINE_TYPE} from '@peoplenet/vi-components-ui/src/app/configuration/cameras';
import {VEHICLE_ONDEMAND_KEY} from '@peoplenet/vi-components-ui/src/app/configuration/localstorageKeys';
import {popQueryParameter, VEHICLE_NUMBER_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';

import {
  searchVehicles,
  searchDVRs,
  loadChannels,
  clearChannels,
  loadTotalRequested,
  postFetchAsync,
  scanVideoAvailable,
  changeWifiCheckbox,
  goToLibrary,
  reset
} from '../OnDemandActions';

const initialState = {
  step: 1,
  vehicleInvalid: false,
  dvrInvalid: false,
  vehicle: null,
  dvr: null,
  confirmIsOpen: false,
  cameras: [
    {
      front: false,
      right: false,
      back: false,
      left: false
    },
    {
      front: false,
      back: false
    }
  ],
  startDate: moment().format('YYYY-MM-DD'),
  startDateTime: moment(),
  startTime: moment().format('HH:mm:ss'),
  endDateTime: moment(),
  duration: '00:01',
  durationTotal: null,
  durationNotFound: false,
  durationInvalid: false,
  durationInFuture: false,
  camerasInvalid: false,
  dvrScanIntervals: [],
  dvrScanTimeLabels: [],
  submitDisabled: true,
  autocompleteValue: '',
  camerasTotalSize: 0,
  pairingMethod: 'dsn',
  workflow: 'vehicle'
};

class RequestForm extends React.Component {
  constructor(props) {
    super(props);

    initialState.pairingMethod = get(this.props.customer, 'data.dvrPairing.method', 'dsn');
    initialState.workflow = get(props.customer, 'data.onDemand.workflow', 'vehicle');

    this.state = initialState;

    const vehicleNumber = popQueryParameter(VEHICLE_NUMBER_KEY);
    if (vehicleNumber) {
      localStorage.setItem(VEHICLE_ONDEMAND_KEY, vehicleNumber);
    }
  }

  componentDidMount = () => {
    this.props.loadTotalRequested();

    this.setVehicleByQuery();
  }

  componentWillReceiveProps = nextProps => {
    this.updateScanIntervals(nextProps);
  }

  componentWillUnmount = () => {
    this.props.reset();
  }

  getScanIntervalWidth = (start, end) => {
    const diff = moment.duration(moment(end).diff(moment(start))).asMilliseconds();
    return (diff * 100) / 21600000;
  }

  getDurationTotal = (startDateTime, endDateTime) => {
    const totalSeconds = (endDateTime.diff(startDateTime, 's') * this.state.camerasTotalSize);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - (minutes * 60);
    return `${padStart(minutes, 2, 0)}:${padStart(seconds, 2, 0)}`;
  }

  setVehicleByQuery = () => {
    const vehicleNumber = localStorage.getItem(VEHICLE_ONDEMAND_KEY);
    if (vehicleNumber) {
      if (initialState.workflow === 'dvr') {
        this.props.searchDVRs({text: vehicleNumber}).then(dvrs => {
          const name = get(dvrs, '[0].name', null);
          const serial = get(dvrs, '[0].serial', null);
          if (name && serial) {
            this.setState({autocompleteValue: name}, () => {
              this.handleOnSelectedDvrAutocomplete(null, {suggestion: {name, serial, type: 'dvr'}});
            });
          }
        });
      } else {
        const suggestion = {serial: vehicleNumber, name: vehicleNumber, type: 'vehicle'};
        this.setState({autocompleteValue: vehicleNumber}, () => {
          this.handleOnSelectedAutocomplete(null, {suggestion});
        });
      }
    }
  }

  resetForm = () => {
    this.props.reset();
    this.props.loadTotalRequested();
    this.setState(initialState, this.setVehicleByQuery);
  }

  fetchTimer = null;
  handleOnFetchAutocomplete = value => {
    if (this.fetchTimer) {
      window.clearTimeout(this.fetchTimer);
    }

    this.fetchTimer = window.setTimeout(() => {
      this.props.searchVehicles({text: value});
      this.fetchTimer = null;
    }, 550);
  }

  handleOnFetchDvrAutocomplete = value => {
    if (this.fetchTimer) {
      window.clearTimeout(this.fetchTimer);
    }
    this.fetchTimer = window.setTimeout(() => {
      this.props.searchDVRs({text: value});
      this.fetchTimer = null;
    }, 550);
  }

  handleOnSelectedAutocomplete = (event, {suggestion}) => {
    this.setState({
      vehicle: suggestion,
      vehicleInvalid: false,
      cameras: cloneDeep(initialState.cameras)
    }, this.validateForm);
    this.props.loadChannels({vehicle: {...suggestion}});
    localStorage.setItem(VEHICLE_ONDEMAND_KEY, suggestion.name);
  }

  handleOnSelectedDvrAutocomplete = (event, {suggestion}) => {
    this.setState({
      dvr: suggestion,
      dvrInvalid: false,
      cameras: cloneDeep(initialState.cameras)
    }, this.validateForm);
    this.props.loadChannels({dvr: {...suggestion}});
    localStorage.setItem(VEHICLE_ONDEMAND_KEY, suggestion.name);
  }

  handleOnChangeCamera = _cameras => {
    const {cameras} = this.state;
    cameras[MACHINE_TYPE.get(this.props.machineType)] = _cameras;
    this.setState({cameras}, this.validateForm);
  }

  handleChangeWifi = event => {
    this.props.changeWifiCheckbox(event.target.checked);
  }

  handleOnBlurAutocomplete = event => {
    if (typeof event.target.value !== 'undefined') {
      this.props.clearChannels();
      this.setState({
        vehicleInvalid: true,
        vehicle: null,
        dvr: null,
        dvrInvalid: true
      });
    }
  }

  handleOnChangeStartDate = event => {
    const startDate = event.target.value;
    if (startDate.length > 0) {
      this.setState({
        startDate
      }, this.validateForm);
    } else {
      this.setState({
        startDate: moment().format('YYYY-MM-DD')
      }, this.validateForm);
    }
  }

  handleOnChangeStartTime = event => {
    const startTime = event.target.value;
    if (startTime.length > 0) {
      this.setState({
        startTime
      }, this.validateForm);
    } else {
      this.setState({
        startTime: moment().format('HH:mm:ss')
      }, this.validateForm);
    }
  }

  handleOnChangeDuration = event => {
    const duration = event.target.value;

    this.setState({
      duration
    }, this.validateForm);
  }

  handleOnBlurDuration = event => {
    let duration = event.target.value;
    duration = replace(duration, new RegExp('_', 'g'), '0');
    this.setState({duration}, this.validateForm);
  }
  
  handleOnSubmit = () => {
    const {
      vehicle,
      dvr,
      cameras,
      startDate,
      startTime,
      duration,
      pairingMethod
    } = this.state;

    const {
      machineType,
      vehicle: vehicleNumber,
      user: {
        userInformation: {
          userName: requestedBy
        }
      },
      wifiEnabled,
      customer
    } = this.props;

    const channels = [];
    let i = 1;
    each(cameras[MACHINE_TYPE.get(machineType)], c => {
      if (c) {
        channels.push(i);
      }
      i++; // eslint-disable-line no-plusplus
    });

    const startDateTime = moment(`${startDate} ${startTime}`);
    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);
    const customerWifi = get(customer, 'data.onDemand.wifi.enabled', false);

    const params = {
      vehicleNumber: dvr ? vehicleNumber : vehicle.name,
      startDate: moment(startDateTime).toDate().getTime(),
      endDate: moment(startDateTime).add({minutes, seconds}).toDate().getTime(),
      channels,
      machineType,
      wifi: wifiEnabled && customerWifi,
      requestedBy,
      pairingMethod
    };
    if (dvr) {
      params.serialNo = dvr.serial;
    }
    this.props.postFetchAsync(params).then(() => this.setState({confirmIsOpen: false}));
  }

  handleOnCheckAvailability = () => {
    const {
      vehicle,
      dvr,
      workflow,
      startDate,
      startTime,
      duration,
      pairingMethod
    } = this.state;

    const startDateTime = moment(`${startDate} ${startTime}`);
    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);

    const params = {
      pairingMethod,
      startDate: moment(startDateTime).toDate().getTime(),
      endDate: moment(startDateTime).add({minutes, seconds}).toDate().getTime()
    };
    if (workflow === 'vehicle') {
      params.truckNumber = vehicle.name;
    }
    if (workflow === 'dvr') {
      params.serial = dvr.serial;
    }
    this.props.scanVideoAvailable(params);
  }

  handleOnClear = () => {
    localStorage.removeItem(VEHICLE_ONDEMAND_KEY);
    this.props.reset();
    this.setState(initialState)
  }

  handleOnConfirmation = () => {
    const {
      startDate,
      startTime,
      duration
    } = this.state;

    const startDateTime = moment(`${startDate} ${startTime}`);
    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);
    const endDateTime = moment(startDateTime).add({minutes, seconds});

    const durationTotal = this.getDurationTotal(startDateTime, endDateTime);
    this.setState({
      step: 2,
      startDateTime,
      endDateTime,
      durationTotal
    });
  }

  handleOnCancel = () => {
    this.setState({step: 1});
  }

  handleOnCancelDialog = () => {
    this.setState({confirmIsOpen: false});
  }

  handleOnOpenDialog = () => {
    this.setState({confirmIsOpen: true});
  }

  handleAutocompleteChange = (event, {newValue}) => {
    this.setState({autocompleteValue: newValue});
  }

  validateTime = () => {
    const {startDate, startTime, duration} = this.state;

    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);

    let durationNotFound = (!Number.isInteger(minutes) || !Number.isInteger(seconds));
    if (!durationNotFound) {
      durationNotFound = (minutes <= 0 && seconds <= 0);
    }

    let durationInvalid = false;
    if (!durationNotFound) {
      durationInvalid = (minutes > 30) || (minutes === 30 && seconds > 0);
    }

    const durationInFuture = !durationInvalid && moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss').add({minutes, seconds}).isAfter(moment());

    this.setState({durationNotFound, durationInvalid, durationInFuture});

    return !durationNotFound && !durationInvalid && !durationInFuture;
  }

  validateForm = () => {
    const invalidDateTimeDuration = !this.validateTime();
    const {
      vehicle,
      dvr,
      cameras,
      workflow
    } = this.state;
    const camerasTotalSize = filter(cameras[MACHINE_TYPE.get(this.props.machineType)], c => {
      return c;
    }).length;

    const camerasCheckeds = camerasTotalSize > 0;

    const submitDisabled =
      workflow === 'dvr'
        ? dvr == null || !camerasCheckeds || invalidDateTimeDuration
        : vehicle == null || !camerasCheckeds || invalidDateTimeDuration;

    this.setState({
      camerasTotalSize,
      submitDisabled
    });
  }

  updateScanIntervals = props => {
    const {startDate, startTime} = this.state;
    const dvrScanTimeLabels = [];

    const requestedTime = get(props, 'dvrScanRequested.start', false);

    if (props.dvrScanErrorMessage) {
      return;
    }

    if (requestedTime) {
      const labelAux = moment(`${startDate} ${startTime}`).add(-3, 'hours');
      const format = I18n.t('page.onDemand.form.videoAvailability.hourFormat');
      dvrScanTimeLabels.push(labelAux.format(format));
      for (let i = 0; i < 6; i++) {
        dvrScanTimeLabels.push(labelAux.add(1, 'hour').format(format));
      }
    }

    if (props.dvrScanIntervals.length > 0) {
      const intervalDateTimeFormat = I18n.t('page.onDemand.form.videoAvailability.intervalDateTimeFormat');
      const selStartDate = moment(`${startDate} ${startTime}`).add(-3, 'hours').startOf('hour');
      const selEndDate = moment(`${startDate} ${startTime}`).add(3, 'hours').startOf('hour');

      // create intervals
      const dvrScanIntervals = props.dvrScanIntervals.map(e => {
        const start = e.startTime * 1000;
        const end = e.endTime * 1000;

        const result = {start, end};
        if (selStartDate.diff(moment(result.start), 's') > 0) {
          result.start = selStartDate.toDate().getTime();
        }
        if (moment(result.end).diff(selEndDate, 's') > 0) {
          result.end = selEndDate.toDate().getTime();
        }

        return {
          ...result,
          width: `${this.getScanIntervalWidth(result.start, result.end)}%`,
          startDate: moment(result.start),
          endDate: moment(result.end),
          label: `${moment(result.start).format(intervalDateTimeFormat)} - ${moment(result.end).format(intervalDateTimeFormat)}`,
          blank: false
        };
      }, this);

      // create blank intervals
      let startAux = selStartDate.toDate().getTime();
      const dvrScanIntervalsResult = [];
      dvrScanIntervals.forEach(e => {
        if (e.end < selStartDate.toDate().getTime() || e.start > selEndDate.toDate().getTime()) {
          return true;
        }

        if (startAux < e.start) {
          dvrScanIntervalsResult.push({
            start: startAux,
            end: e.start,
            width: `${this.getScanIntervalWidth(startAux, e.start)}%`,
            blank: true
          });
        }
        startAux = e.end;
        dvrScanIntervalsResult.push(e);
        return true;
      });
      this.setState({
        dvrScanIntervals: dvrScanIntervalsResult,
        dvrScanTimeLabels
      });
    } else {
      this.setState({
        dvrScanIntervals: [],
        dvrScanTimeLabels
      });
    }
  }

  formatDuration = total => {
    const minutes = parseInt(total / 60, 0);
    const seconds = total % 60;

    const minStr = (minutes > 0) ? `${minutes} ${I18n.t('page.onDemand.requestQueue.minute')} ` : '';
    const secStr = (seconds > 0) ? `${seconds} ${I18n.t('page.onDemand.requestQueue.second')}` : '';

    return minStr + secStr;
  }

  render() {
    const {
      step,
      vehicle,
      dvr,
      startDateTime,
      endDateTime,
      cameras,
      duration,
      durationTotal,
      autocompleteValue,
      confirmIsOpen,
      workflow
    } = this.state;

    const {
      channelsAvailable,
      machineType,
      vehiclesList,
      dvrList,
      isLoading,
      channelsNotFound,
      dvrScanVisible
    } = this.props;

    const camType = MACHINE_TYPE.get(machineType);

    const cameraOptions = {
      order: channelsAvailable,
      machineType,
      cameras: cameras[camType]
    };
    const customerWifi = get(this.props, 'customer.data.onDemand.wifi.enabled', false);
    const hasWifi = get(this.props, 'hasWifi', false);
    const wifiCheckEnabled = customerWifi && hasWifi;

    return (
      <React.Fragment>
        <Grid item className={step === 1 ? '' : 'hideme'}>
          <Card className="ondemand__card">
            <CardContent>
              <List className="ondemand__list">
                <ListItem>
                  <FormControl error aria-describedby="error-text" className="ondemand__field">
                    {workflow === 'dvr' &&
                      <AutoCompleteInput
                        id="serialNumber"
                        label={I18n.t('page.onDemand.form.serialVehicleNumber')}
                        list={dvrList}
                        startFetchLength={3}
                        keepValueSelected
                        onSelect={this.handleOnSelectedDvrAutocomplete}
                        onFetchRequested={this.handleOnFetchDvrAutocomplete}
                        onBlur={this.handleOnBlurAutocomplete}
                        isLoading={isLoading}
                        value={autocompleteValue}
                        onChange={this.handleAutocompleteChange}
                      />}
                    {workflow === 'vehicle' &&
                      <AutoCompleteInput
                        id="vehicleNumber"
                        label={I18n.t('page.onDemand.form.vehicleNumber')}
                        list={vehiclesList}
                        startFetchLength={3}
                        keepValueSelected
                        onSelect={this.handleOnSelectedAutocomplete}
                        onFetchRequested={this.handleOnFetchAutocomplete}
                        onBlur={this.handleOnBlurAutocomplete}
                        isLoading={isLoading}
                        value={autocompleteValue}
                        onChange={this.handleAutocompleteChange}
                      />}
                    {workflow === 'vehicle' && this.state.vehicleInvalid && <FormHelperText id="error-text"><span>{I18n.t('page.onDemand.form.vehicleNumberMissing')}</span></FormHelperText>}
                    {workflow === 'dvr' && this.state.dvrInvalid && <FormHelperText id="error-text"><span>{I18n.t('page.onDemand.form.serialVehicleNumberMissing')}</span></FormHelperText>}
                  </FormControl>
                </ListItem>
                <ListItem className="ondemand__listitem">
                  <FormControl error aria-describedby="error-text" className="ondemand__field">
                    <ChannelsAvailable
                      options={cameraOptions}
                      onChange={this.handleOnChangeCamera}
                      isLoading={isLoading}
                    />
                    {channelsNotFound && <FormHelperText id="error-text"><span>{I18n.t('page.onDemand.form.camerasNotFound')}</span></FormHelperText>}
                  </FormControl>
                </ListItem>
                <ListItem>
                  <FormControl error aria-describedby="error-text" className="ondemand__date">
                    <TextField id="startDate" label={I18n.t('page.onDemand.form.date')} margin="normal" type="date" onChange={this.handleOnChangeStartDate} value={this.state.startDate} InputLabelProps={{shrink: true}} />
                  </FormControl>
                  <FormControl error aria-describedby="error-text" className="ondemand__time">
                    <TextField id="startTime" label={I18n.t('page.onDemand.form.time')} margin="normal" type="time" onChange={this.handleOnChangeStartTime} value={this.state.startTime} inputProps={{step: 1}} InputLabelProps={{shrink: true}} />
                  </FormControl>
                  <FormControl error={this.state.durationNotFound || this.state.durationInvalid || this.state.durationInFuture} aria-describedby="error-text" className="ondemand__duration">
                    <div className="ondemand__durationcont">
                      <InputLabel htmlFor="duration">{I18n.t('page.onDemand.form.duration')}</InputLabel>
                      <Input
                        id="duration"
                        value={duration}
                        onChange={this.handleOnChangeDuration}
                        onBlur={this.handleOnBlurDuration}
                        inputComponent={TextMaskMinuteSeconds}
                      />
                    </div>
                    {this.state.durationNotFound && <FormHelperText id="error-text">{I18n.t('page.onDemand.form.required')}</FormHelperText>}
                    {this.state.durationInvalid && <FormHelperText id="error-text">{I18n.t('page.onDemand.form.videoTooLongError')}</FormHelperText>}
                    {!this.state.durationNotFound && this.state.durationInFuture && <FormHelperText id="error-text">{I18n.t('page.onDemand.form.videoInFutureError')}</FormHelperText>}
                  </FormControl>
                </ListItem>
                <ListItem className="availablevideo">
                  {dvrScanVisible && <label className="availablevideo__label">{I18n.t('page.onDemand.form.videoAvailability.label')}</label>}
                  {dvrScanVisible &&
                    <div className="availablevideo__cont">
                      <div className="availablevideo__timerangecont">
                        {this.state.dvrScanIntervals.map((item, index) => {
                          if (!item.blank) {
                            return (<div key={`dvrScanInterval-${index}`} className="availablevideo__timerange" title={item.label} style={{width: item.width}} />);
                          }
                          return (<div key={`dvrScanInterval-${index}`} className="availablevideo__timerange-blank" title={item.label} style={{width: item.width}} />);
                        })}
                      </div>
                      <div className="availablevideo__markercont">
                        {this.state.dvrScanTimeLabels.map((item, index) => {
                          return (
                            <div key={`dvrScanInterval-${index}`} className="availablevideo__marker">
                              <span className="availablevideo__time">{item}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                  {this.props.dvrScanErrorMessage &&
                    <p className="availablevideo__feedback">
                      <Icon className="availablevideo__feebackicon">videocam_off</Icon>
                      {I18n.t('page.onDemand.form.videoAvailability.error')}
                    </p>
                  }
                  {(this.props.dvrScanStatus === false && this.props.dvrScanErrorMessage === false) &&
                    <p className="availablevideo__feedback">
                      <Icon className="availablevideo__feebackicon">videocam_off</Icon>
                      {I18n.t('page.onDemand.form.videoAvailability.missing')}
                    </p>
                  }
                  <Button
                    id="checkVideoAvailabilityButton"
                    disabled={this.state.submitDisabled}
                    onClick={this.handleOnCheckAvailability}
                    variant="contained"
                    color="default"
                    className="btn btn-terciery"
                  >
                    {this.props.dvrScanLoading &&
                      <CircularProgress className="availablevideo__loading" />
                    }
                    {!this.props.dvrScanLoading &&
                      <Icon className="availablevideo__cameicon">videocam</Icon>
                    }
                    {I18n.t('page.onDemand.form.videoAvailability.button')}
                  </Button>
                  <div className="col-md-12" />
                </ListItem>
              </List>
            </CardContent>
            <CardActions className="ondemand__actions">
              <label className={!wifiCheckEnabled && 'hideme'}>
                {I18n.t('page.onDemand.form.wifi')}
                <Checkbox
                  id="wifiUpload"
                  disabled={!wifiCheckEnabled}
                  checked={this.props.wifiEnabled}
                  onChange={this.handleChangeWifi}
                />
              </label>
              <Button id="onDemandClear" onClick={this.handleOnClear} variant="contained" color="default" className="btn btn-secondary">{I18n.t('page.onDemand.form.clear')}</Button>
              <Button id="onDemandSubmit" disabled={this.state.submitDisabled} onClick={this.handleOnConfirmation} variant="contained" color="primary" className="btn btn-primary">{I18n.t('page.onDemand.form.submit')}</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item className={!this.props.fetchSuccess && this.state.step === 2 ? '' : 'hideme'}>
          <Card className="ondemand__card">
            <CardHeader className="ondemand__header" title={I18n.t('page.onDemand.confirmation.title')} />
            <CardContent>
              <List>
                <ListItem className="ondemand__row">
                  <Grid container>
                    <Grid item xs={7}>
                      {workflow === 'dvr' && I18n.t('page.onDemand.form.serialVehicleNumber')}
                      {workflow === 'vehicle' && I18n.t('page.onDemand.form.vehicleNumber')}
                    </Grid>
                    <Grid item xs={5}>
                      {workflow === 'dvr' && dvr && dvr.name}
                      {workflow === 'vehicle' && vehicle && vehicle.name}
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem className="ondemand__row">
                  <Grid container>
                    <Grid item xs={7}>
                      {I18n.t('page.onDemand.form.date')}
                    </Grid>
                    <Grid item xs={5}>
                      {startDateTime.format(I18n.t('configuration.dateFormat'))}
                      {
                        (startDateTime.day() !== endDateTime.day()) && (
                          ` - ${endDateTime.format(I18n.t('configuration.dateFormat'))}`
                        )
                      }
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem className="ondemand__row">
                  <Grid container>
                    <Grid item xs={7}>
                      {I18n.t('page.onDemand.form.time')}
                    </Grid>
                    <Grid item xs={5}>
                      {startDateTime.format(I18n.t('configuration.timeFormat'))} - {endDateTime.format(I18n.t('configuration.timeFormat'))}
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem className="ondemand__row">
                  <Grid container>
                    <Grid item xs={7}>
                      {I18n.t('page.onDemand.form.duration')}
                    </Grid>
                    <Grid item xs={5}>
                      {duration}
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem className="ondemand__row ondemand ondemand__requested">
                  <Grid container>
                    <Grid item xs={12} className="ondemand__selectedviews-cont">
                      <label className="ondemand__label">{I18n.t('page.onDemand.confirmation.selectedCameras')}</label>
                      {cameras[camType].front &&
                        <label>
                          <div className="ondemand__cameraviews ff-cam">
                            <Icon className="videothumb__camview">arrow_upward</Icon>{I18n.t('page.onDemand.form.camera.ff')}
                          </div>
                        </label>
                      }
                      {cameras[camType].back &&
                        <label>
                          <div className="ondemand__cameraviews re-cam">
                            <Icon className="videothumb__camview">arrow_downward</Icon>{I18n.t('page.onDemand.form.camera.re')}
                          </div>
                        </label>
                      }
                      {cameras[camType].left &&
                        <label>
                          <div className="ondemand__cameraviews dr-cam">
                            <Icon className="videothumb__camview">arrow_back</Icon>{I18n.t('page.onDemand.form.camera.dr')}
                          </div>
                        </label>
                      }
                      {cameras[camType].right &&
                        <label>
                          <div className="ondemand__cameraviews pa-cam">
                            {I18n.t('page.onDemand.form.camera.pa')}<Icon className="videothumb__camview">arrow_forward</Icon>
                          </div>
                        </label>
                      }
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem className="ondemand__row ondemand__totalcharges">
                  <Grid container>
                    <Grid item xs={7}>
                      {I18n.t('page.onDemand.confirmation.totalMinutes')}
                    </Grid>
                    <Grid item xs={5}>
                      {durationTotal}
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </CardContent>
            <CardActions className="ondemand__actions">
              <Button id="onDemandCancel" onClick={this.handleOnCancel} variant="contained" color="default" className="btn btn-secondary">{I18n.t('page.onDemand.form.cancel')}</Button>
              <Button id="onDemandConfirm" disabled={this.props.isInternalUser} onClick={this.handleOnOpenDialog} variant="contained" color="primary" className="btn btn-primary">{I18n.t('page.onDemand.form.confirm')}</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item className={this.props.fetchSuccess && this.state.step === 2 ? '' : 'hideme'}>
          <Card>
            <CardContent>
              <ListItem className="ondemand__centerrow">
                <Icon className="ondemand__successiconrow">check_circle</Icon>
              </ListItem>
              <List>
                <ListItem className="ondemand__centerrow">
                  {I18n.t('page.onDemand.successMessage')}
                </ListItem>
              </List>
            </CardContent>
            <CardActions className="ondemand__actions ondemand__actions-centered">
              <Button id="onDemandRequestAnother" onClick={this.resetForm} variant="contained" color="default" className="btn btn-secondary btn-biglabel">{I18n.t('page.onDemand.requestAnother')}</Button>
              <Button id="onDemandGoToLibrary" onClick={this.props.goToLibrary} variant="contained" color="primary" className="btn btn-primary btn-biglabel">{I18n.t('page.onDemand.videoLibrary')}</Button>
            </CardActions>
          </Card>
        </Grid>
        <Dialog open={confirmIsOpen} className={!this.props.fetchSuccess && this.state.step === 2 ? '' : 'hideme'}>
          <DialogTitle>
            {I18n.t('page.onDemand.confirmation.dialog.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span className="ondemand__info">
                <Icon className="ondemand__info-icon">info</Icon><span>{I18n.t('page.onDemand.chargeWarning')}</span>
              </span>
              {this.props.requestedSeconds != null && (
                <span className="ondemand__info">
                  <Icon className="ondemand__timeicon">access_time</Icon>
                  <label>
                    <Translate
                      value="page.onDemand.confirmation.totalMinutesRequested"
                      dangerousHTML
                      count={Math.round(this.props.requestedSeconds / 60)}
                    />
                  </label>
                </span>
              )}
            </DialogContentText>
            <DialogActions className="ondemand__dialog-actions">
              <Button id="onDemandCancelDialog" disabled={this.props.isLoadingRequest} className="btn btn-secondary" onClick={this.handleOnCancelDialog} >{I18n.t('defaults.cancel')}</Button>
              <Button id="onDemandConfirmDialog" disabled={this.props.isLoadingRequest} onClick={this.handleOnSubmit} variant="contained" color="primary" className="btn btn-primary">{I18n.t('defaults.confirm')}</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {onDemand, customer, user} = state;
  return {
    ...state.i18n.locale,
    ...onDemand,
    customer,
    user
  };
}

export default connect(mapStateToProps, {
  searchVehicles,
  searchDVRs,
  loadChannels,
  clearChannels,
  loadTotalRequested,
  postFetchAsync,
  scanVideoAvailable,
  changeWifiCheckbox,
  goToLibrary,
  reset
})(RequestForm);
