import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';


import {isMobile} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import MobileLibrary from './MobileLibrary';
import DesktopLibrary from './DesktopLibrary';
import Filters from '@peoplenet/vi-components-ui/src/LibraryFilter/component/LibraryFilters';
import ViAppBar from '../../components/ViAppBar';

class Library extends React.PureComponent {
  renderLibrary() {
    if (this.props.isMobile) {
      return (
        <div>
          <MobileLibrary {...this.props} />
        </div>
      );
    }
    return <DesktopLibrary {...this.props} />;
  }

  render() {
    let className = 'bodycontent';
    className += (this.props.isMobile) ? ' bodycontent__librarymobile' : ' bodycontent__librarydesktop';

    return (
      <div>
        <div className="whitecover" />
        <ViAppBar auth={this.props.auth} title={I18n.t('page.library.title')} >
          {this.props.isMobile && <Filters />}
        </ViAppBar>
        <div className={className}>
          {this.renderLibrary()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {isMobile: isMobile(state)};
}

export default connect(mapStateToProps)(Library);
