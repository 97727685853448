import React from 'react';
import {connect} from 'react-redux';
import {Icon, IconButton, Menu, MenuItem} from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import VideocamIcon from '@material-ui/icons/Videocam';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import {I18n} from 'react-redux-i18n';
import {toast} from 'react-toastify';
import {has} from 'lodash';

import {getUrlVideo, requestVideoWithDashboard, clearUrl} from '../DownloadVideoActions';
import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';
import ConfirmationDialog from './ConfirmationDialog';

class DownloadVideo extends React.Component {
  state = {
    confimationDialogOpen: false,
    videoSize: null,
    requestBlock: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clipUrl) {
      const tempLink = document.createElement('a');

      tempLink.style.display = 'none';
      tempLink.href = nextProps.clipUrl;
      tempLink.setAttribute('download', `${nextProps.id}.mp4`);
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(nextProps.clipUrl);
      nextProps.clearUrl();
    }
  }

  handleConfirmDownload = () => {
    this.setState({confimationDialogOpen: false});
  }

  handleCancelDownload = () => {
    this.setState({confimationDialogOpen: false});
  }

  handleDownloadVideoClick = () => {
    if (this.props.isInternalSalesUser) {
      return;
    }
    const {clip} = this.props;

    if (clip) {
      const videoSize = `${Math.round(clip.size * 100) / 100} MB`;

      this.setState({videoSize});
    }

    if (this.props.isMobile) {
      this.setState({confimationDialogOpen: true});
    } else {
      this.downloadVideo();
      this.closeMenu();
    }
  }

  downloadVideo = () => {
    const {clip, media, systemMeasure} = this.props;
    this.props.getUrlVideo({
      url: clip.rawUrl, clipId: clip.id, mediaId: media.mediaId, systemMeasure
    });
  }

  openMenu = event => {
    const dashboardRequested = this.props.clip.systemMeasure === 'imperial' ?
      this.props.clip.imperialDashboardDownloadRequested : this.props.clip.metricDashboardDownloadRequested;
    this.setState({
      requestBlock: dashboardRequested
    });

    if (this.props.media.metadata.triggerType === 'onDemand' && !has(this.props, 'media.relatedItems.oerEvent')) {
      this.handleDownloadVideoClick();
      return;
    }
    this.setState({
      anchorEl: event.currentTarget
    });
  }
  closeMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleDownloadDashBoard = () => {
    toast.info(<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><VideocamIcon /><span style={{marginLeft: 10}}>{I18n.t('page.playerPage.pleaseWait')}</span></div>);
    this.closeMenu();
    this.setState({
      requestBlock: true
    });
    this.props.requestVideoWithDashboard(this.props.media.mediaId, this.props.clip.id, this.props.clip.systemMeasure);
  }
  render() {
    if (!this.props.canDownloadVideo) {
      return null;
    }

    return (
      <React.Fragment>
        <div>
          <IconButton onClick={this.openMenu} id="playerDownload" disabled={this.props.isInternalSalesUser}>
            <Icon className="downloadvideo">file_download</Icon>
          </IconButton>
          <Menu
            id="donload-menu"
            keepMounted
            style={{marginTop: 45}}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.closeMenu}
          >
            <MenuItem
              onClick={this.handleDownloadVideoClick}
            >
              <PlayCircleOutlineIcon />
              <span style={{marginLeft: 10}}>{I18n.t('page.playerPage.videoOnly')}</span>
            </MenuItem>
            <Tooltip
              title={I18n.t('page.playerPage.videoWithDashboardProcessing')}
              disableFocusListener={!this.state.requestBlock}
              disableHoverListener={!this.state.requestBlock}
              disableTouchListener={!this.state.requestBlock}
            >
              <li>
                <MenuItem
                  disabled={this.state.requestBlock}
                  onClick={this.handleDownloadDashBoard}
                  style={this.state.requestBlock ? {pointerEvents: 'none'} : {}}
                >
                  <OndemandVideoIcon />
                  <span style={{marginLeft: 10}}>{I18n.t('page.playerPage.videoWithDashboard')}</span>
                </MenuItem>
              </li>
            </Tooltip>
            <MenuItem
              style={{display: 'none'}}
              selected
              onClick={this.closeMenu}
            />
          </Menu>
          <Dialog
            open={this.state.confimationDialogOpen}
            onClose={this.handleCancel}
          >
            <ConfirmationDialog
              onCancel={this.handleCancelDownload}
              onConfirm={this.handleConfirmDownload}
              videoSize={this.state.videoSize}
              clip={this.props.clip}
            />
          </Dialog>
        </div>
        {this.props.isMenu && <span onClick={this.handleDownloadVideoClick} disabled={this.props.isInternalSalesUser}>{I18n.t('page.playerPage.actions.download')}</span>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {downloadVideo} = state;
  const canDownloadVideo = hasAccess([
    'changeCustomer',
    'videoIntelligenceManager',
    'videoIntelligenceAdmin'
  ]);

  return {canDownloadVideo, ...downloadVideo};
};

export default connect(
  mapStateToProps,
  {
    getUrlVideo,
    requestVideoWithDashboard,
    clearUrl
  }
)(DownloadVideo);
