import {set, get, map, toInteger, concat} from 'lodash';
import {
  LIBRARY_CLEAR,
  LIBRARY_CLEAN_SAVE,
  LIBRARY_SAVE,
  MEDIA_SEARCH_REQUEST,
  MEDIA_SEARCH_SUCCESS,
  MEDIA_SEARCH_FAIL,
  MEDIA_FORCE_SEARCH,
  UPDATE_MEDIALIST,
  CHANGE_VIEWMODE,
  DOWNLOAD_LIST_VIEW_AS_CSV_REQUEST,
  DOWNLOAD_LIST_VIEW_AS_CSV_SUCCESS,
  CLEAR_DOWNLOAD_LIST_VIEW_AS_CSV
} from '../library/LibraryActionsType';
import {
  TAG_POST_SUCCESS,
  COACH_SET_SUCCESS,
  REVIEW_POST_SUCCESS
} from '@peoplenet/vi-components-ui/src/actions/constants';
import {
  FLAG_POST_SUCCESS
} from '../flagVideo/FlagVideoActionTypes.js';

import {viewMode} from './constants';

const SAVED_POSITION_KEY = 'librarySavedPosition';
const VIEW_MODE_KEY = 'libraryViewMode';

const initialState = {
  mediaList: [],
  empty: false,
  isLoading: true,
  viewMode: viewMode.CARD,
  page: -1,
  forced: false,
  saved: {},
  summary: {},
  libraryTotalCount: 0,
  listViewCsv: null,
  listViewCsvIsLoading: false
};

const initializeState = () => {
  const savedPosition = localStorage.getItem(SAVED_POSITION_KEY);
  const viewModeSaved = localStorage.getItem(VIEW_MODE_KEY);
  const savedState = {...initialState};

  if (viewModeSaved) {
    savedState.viewMode = viewModeSaved;
  }
  if (savedPosition) {
    try {
      savedState.saved = JSON.parse(savedPosition);
    } catch (ex) {
      console.error(ex);
    }
  }

  return savedState;
};

const parseStatus = media => {
  let status = 'none';
  const coachCompleted = get(media, 'metadata.coach.completed', false);
  const reviewed = get(media, 'metadata.review', null);
  const reviewCompleted = get(media, 'metadata.review.completed', false);
  const tagged = get(media, 'tag.review.value', false);

  if (reviewed && (!coachCompleted && !reviewCompleted)) {
    // medium priority to show; show reviwed icon just when isnt coaching completed;
    status = 'reviewed';
  } else if (reviewCompleted && !coachCompleted) {
    // highest priority to show; if coaching completed, always show
    status = 'reviewCompleted';
  } else if (coachCompleted) {
    // high priority to show; if coaching completed, always show
    status = 'coachCompleted';
  } else if (tagged && !(reviewed || coachCompleted)) {
    // low priority to show; if coaching isnt completed and not reviewed
    status = 'needReview';
  }
  return status;
};

const parseMediaItems = data => {
  // TODO: move to service
  const newItems = get(data, 'payload.data', []).map(e => {
    const {
      groupNames: groups,
      vehicle,
      mediaId,
      metadata
    } = e;

    const {coach} = metadata || {};
    const machineType = get(metadata, 'machineType', 0);

    return {
      mediaId,
      machineType,
      date: parseInt(e.mediaDate, 10),
      vehicle,
      address: e.address && `${e.address.street}, ${e.address.city}, ${e.address.state}`,
      driver: e.metadata && e.metadata.driverName1,
      driverId: get(e, 'metadata.driverID1', ''),
      oer: e.relatedItems && e.relatedItems.oerEvent.trigger,
      triggerType: get(e, 'metadata.triggerType', ''),
      clips: e.captures[0].clips,
      startTime: parseInt(e.captures[0].startDate, 10),
      duration: e.captures[0].durationSeconds,
      flagged: e.flagged,
      status: parseStatus(e),
      groups,
      coach,
      checked: false,
      terminal: get(e, 'metadata.terminal.name', ''),
      terminalId: get(e, 'metadata.terminal.id', '')
    };
  });

  return newItems;
};

export default function library(state = initializeState(), action) {
  const {type, payload} = action || {};

  let result;
  let currentPage;
  let hasMorePages = false;
  let invertConcat = false;
  let mediaList = [];

  switch (type) {
    case LIBRARY_CLEAR:
      return {...state, ...initialState, saved: state.saved, viewMode: state.viewMode};
    case MEDIA_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        forced: false,
        errorMessage: false
      };
    case LIBRARY_SAVE:
      localStorage.setItem(SAVED_POSITION_KEY, JSON.stringify(action.payload));
      return {...state, saved: action.payload};
    case LIBRARY_CLEAN_SAVE:
      localStorage.removeItem(SAVED_POSITION_KEY);
      return {...state, saved: initialState.saved, viewMode: state.viewMode};
    case MEDIA_SEARCH_SUCCESS:
      result = parseMediaItems(action);
      currentPage = toInteger(get(payload, 'meta.paging.page', 0));
      hasMorePages = get(payload, 'meta.paging.pages', 1) > currentPage + 1;
      invertConcat = get(payload, 'meta.invertConcat', false);

      if (currentPage > 0) {
        mediaList = invertConcat ? concat(result, state.mediaList) : concat(state.mediaList, result);
      } else {
        mediaList = result;
      }

      return {
        ...state,
        isLoading: false,
        empty: (result.length <= 0),
        page: currentPage,
        mediaList,
        hasMorePages,
        summary: payload.summary,
        libraryTotalCount: get(payload, 'meta.total', initialState.libraryTotalCount)
      };
    case MEDIA_SEARCH_FAIL:
      return {...state, isLoading: false, errorMessage: true};
    case MEDIA_FORCE_SEARCH:
      localStorage.removeItem(SAVED_POSITION_KEY);
      return {
        ...state,
        ...initialState,
        forced: true,
        saved: initialState.saved,
        viewMode: state.viewMode
      };
    case UPDATE_MEDIALIST:
      return {
        ...state,
        mediaList: action.payload
      };
    case CHANGE_VIEWMODE:
      localStorage.setItem(VIEW_MODE_KEY, action.payload);
      return {
        ...state,
        viewMode: action.payload
      };
    case DOWNLOAD_LIST_VIEW_AS_CSV_REQUEST:
      return {
        ...state,
        listViewCsv: initialState.listViewCsv,
        listViewCsvIsLoading: true
      };
    case DOWNLOAD_LIST_VIEW_AS_CSV_SUCCESS:
      return {
        ...state,
        listViewCsv: action.payload,
        listViewCsvIsLoading: initialState.listViewCsvIsLoading
      };
    case CLEAR_DOWNLOAD_LIST_VIEW_AS_CSV:
      return {
        ...state,
        listViewCsv: initialState.listViewCsv
      };
    case TAG_POST_SUCCESS:
      return {
        ...state,
        mediaList: map(state.mediaList, media => {
          if (media.mediaId === get(action, 'payload.mediaId')) {
            const newMedia = set({...media}, 'tag.review.value', get(action, 'payload.params.value', false));
            if (get(action, 'payload.params.value', false)) {
              set(newMedia, 'metadata.coach.completed', false);
              if (get(newMedia, 'metadata.review', null)) {
                set(newMedia, 'metadata.review.completed', false);
              }
            }
            set(newMedia, 'status', parseStatus(newMedia));
            return newMedia;
          }
          return media;
        })
      };
    case COACH_SET_SUCCESS:
      return {
        ...state,
        mediaList: map(state.mediaList, media => {
          if (media.mediaId === get(action, 'body.media.id')) {
            const newMedia = set({...media}, 'metadata.coach.completed', get(action, 'body.completed', false));
            if (get(action, 'body.completed', false)) {
              set(newMedia, 'tag.review.value', false);
              set(newMedia, 'tag.review.value', false);
              if (get(newMedia, 'metadata.review', null)) {
                set(newMedia, 'metadata.review.completed', false);
              }
            }
            set(newMedia, 'status', parseStatus(newMedia));
            return newMedia;
          }
          return media;
        })
      };
    case REVIEW_POST_SUCCESS:
      return {
        ...state,
        mediaList: map(state.mediaList, media => {
          if (media.mediaId === get(action, 'payload.media.mediaId')) {
            const newMedia = set({...media}, 'metadata.review.completed', get(action, 'payload.review.completed', false));
            if (get(action, 'payload.review.completed', false)) {
              set(newMedia, 'tag.review.value', false);
              set(newMedia, 'metadata.coach.completed', false);
            }
            set(newMedia, 'status', parseStatus(newMedia));
            return newMedia;
          }
          return media;
        })
      };
    case FLAG_POST_SUCCESS:
      return {
        ...state,
        mediaList: map(state.mediaList, media => {
          if (media.mediaId === get(action, 'payload.mediaId')) {
            return set({...media}, 'flagged', get(action, 'payload.flag', false));
          }
          return media;
        })
      };
    default:
      return state;
  }
}
