/* eslint-disable react/no-find-dom-node */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {Fragment} from 'react';
import {get} from 'lodash';
import {I18n} from 'react-redux-i18n';
import {Icon, IconButton} from '@material-ui/core';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import history from '@peoplenet/vi-components-ui/src/app/history';
import ProgressSpinner from '../../components/ProgressSpinner';


export default class PreviouslyReviews extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newVisibleStatus: null,
      fixed: false
    };
    this.header = null;
    this.table = null;
  }

  handleClick = media => () => {
    this.props.onLoadReview(media.mediaId[0]);
    history.replace(`/player?mediaId=${media.mediaId[0]}&camera=split-camera&tabIndex=2&subTabIndex=2`);
  }

  // eslint-disable-next-line react/sort-comp
  renderSortIcon = sort => {
    let iconClass = 'sortupward';
    let iconName = 'arrow_downward';

    if (sort === 'asc') {
      iconClass = 'sortdownward';
      iconName = 'arrow_downward';
    }

    return (
      <Icon className={`table__arrow ${iconClass}`}>{iconName}</Icon>
    );
  }

  renderQueueEmpty() {
    const {previouslyReviews} = this.props;
    if (previouslyReviews && previouslyReviews.length > 0) return null;

    return (
      <TableRow>
        <TableCell colSpan={10}>
          <span className="previously__infomsg"><Icon className="previously__msgicon">info</Icon>{I18n.t('page.playerPage.reviewDetails.listEmpty')}</span>
        </TableCell>
      </TableRow>
    );
  }

  renderQueueItem = ({fields}, index) => {
    const formatMediaDate = () => {
      const mediaDate = `${get(fields, 'mediaDate')[0]}`;
      return parseInt(mediaDate, 10);
    };

    const mediaDate = moment(new Date(formatMediaDate()));

    const dateFormat = mediaDate.isValid() ? mediaDate.format(I18n.t('configuration.dateFormat')) : get(fields, 'mediaDate')[0];
    const timeFormat = mediaDate.isValid() ? mediaDate.format(I18n.t('configuration.timeFormat')) : get(fields, 'mediaDate')[0];

    let coachDate = get(fields, 'metadata.coach.date', null);
    let coachFormat;
    if (coachDate) {
      coachDate = moment(new Date(coachDate[0]));
      coachFormat = coachDate.format(`${I18n.t('configuration.dateFormat')} ${I18n.t('configuration.timeFormat')}`);
    }

    const reviewCompleted = fields['metadata.review.completed'] && fields['metadata.review.completed'][0] === true ? 'Yes' : 'No';
    const coachCompleted = fields['metadata.coach.completed'] && fields['metadata.coach.completed'][0] === true ? 'Yes' : 'No';
    const reviewLanguageKeys = get(fields, 'reviewLanguageKeys', []);

    return (
      <Fragment>
        <TableRow key={`${index}`} className="table__head">
          <TableCell className="previously__playcell" onClick={this.handleClick(fields)}><Icon className="play-icon">play_circle_filled</Icon></TableCell>
          <TableCell className="previously__drivercell">
            {get(fields, 'metadata.driverName1')}
          </TableCell>
          <TableCell className="previously__datecell">
            {dateFormat}
          </TableCell>
          <TableCell className="previously__timecell">
            {timeFormat}
          </TableCell>
          <TableCell className="previously__vehiclecell">
            {get(fields, 'vehicle[0]')}
          </TableCell>
          <TableCell className="previously__reviewcell">
            {reviewLanguageKeys.length === 0 ? I18n.t('page.playerPage.reviewDetails.noReviewCategories') : reviewLanguageKeys.map(key => I18n.t(key, key)).join(', ')}
          </TableCell>
          <TableCell
            className="previously__coachingcomcell"
            onClick={() => this.setState({[[get(fields, 'metadata.review.id')]]: !this.state[get(fields, 'metadata.review.id')]})}
          >
            {get(fields, 'metadata.coach.comment', null)
              !== null
              ? <a href="javascript:void(0);">{this.formatCoachComment(get(fields, 'metadata.coach.comment'))}</a>
              : '-'}
          </TableCell>
          <TableCell className="previously__reviewcompletecell" >
            {reviewCompleted}
          </TableCell>
          <TableCell className="previously__coachingcompletecell">
            {coachCompleted}
          </TableCell>
          <TableCell className="previously__scorecell">
            {fields['metadata.review.points'][0]}
          </TableCell>
        </TableRow>
        {this.state[get(fields, 'metadata.review.id')] && get(fields, 'metadata.coach.comment', null) &&
          <TableRow key={`${index}_sub`}>
            <TableCell className="previously__statuscell" colSpan={10}>
              <p className="reviewdesktop__commentrow-extend"><span>{`${get(fields, 'metadata.coach.coachName')} - ${coachFormat} - `}</span>{get(fields, 'metadata.coach.comment')}</p>
            </TableCell>
          </TableRow>
        }
      </Fragment>
    );
  };

  handleLazyLoading = () => {
    const {
      previouslyReviewsTotal,
      previouslyReviews,
      searchingReviews,
      searchingMoreReviews,
      currentPage,
      onChangePage
    } = this.props;
    if (!searchingReviews
      && !searchingMoreReviews
      && previouslyReviews.length < previouslyReviewsTotal) {
      onChangePage(currentPage + 1, this.loadIfScreenIsBig);
    }
  }

  getCurrentScrollPercent() {
    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }

  formatCoachComment = ([comment]) => {
    if (comment.length > 70) {
      return `${comment.slice(0, 70)}...`;
    }
    return comment;
  }

  loadIfScreenIsBig = () => {
    const height = window.innerHeight;
    const innerH = document.body.clientHeight;
    const {previouslyReviewsTotal, previouslyReviews} = this.props;

    if (height > innerH && previouslyReviews.length < previouslyReviewsTotal) {
      this.handleLazyLoading();
    }
  };

  startLazyLoading = scroll => {
    if (
      scroll.currentTarget.getBoundingClientRect().y
      / scroll.currentTarget.getBoundingClientRect().height <= 1.5
    ) {
      this.handleLazyLoading();
    }
  };

  render() {
    const {
      previouslyReviews,
      getSortFieldDirection,
      searchingReviews,
      searchingMoreReviews,
      isSameSortField,
      onChangeReviewSort
    } = this.props;

    return (
      <div >
        <FormControl>
          <div style={{position: 'relative', margin: 20, display: 'inline-block'}}>
            <Input
              onKeyPress={({key}) => {
                if (key !== 'Enter') return;
                this.props.onSearchReview({resetState: true, callBack: this.loadIfScreenIsBig});
              }}
              style={{paddingRight: 15}}
              id="textSearch"
              value={this.props.textSearch}
              onChange={this.props.onChanteTextSearch}
              placeholder="Search"
            />
            <IconButton
              style={{marginLeft: -30}}
              onClick={() =>
                this.props.onSearchReview({resetState: true, callBack: this.loadIfScreenIsBig})}
            >
              <Icon>search</Icon>
            </IconButton>
          </div>
        </FormControl>
        <div>

          <Table
            ref={ref => {
              this.table = ref;
            }}
          >

            <TableHead
              ref={ref => {
                this.header = ref;
              }}
            >
              <TableRow className="table__head">
                <TableCell className="previously__playcell" >
                    Play
                </TableCell>
                <TableCell className="previously__drivercell">
                  {I18n.t('page.playerPage.reviewDetails.driver')}
                </TableCell>
                <TableCell
                  className="previously__datecell"
                >
                  <div
                    className={`table__sortcont${isSameSortField('mediaDate') ? '-sorted' : ''}`}
                    style={{display: 'inline-block'}}
                    onClick={
                      getSortFieldDirection('mediaDate') === 'desc'
                        ? () => onChangeReviewSort('mediaDate', 'asc', this.loadIfScreenIsBig)
                        : () => onChangeReviewSort('mediaDate', 'desc', this.loadIfScreenIsBig)
                    }
                  >
                    {I18n.t('page.playerPage.reviewDetails.date')}
                    {this.renderSortIcon(getSortFieldDirection('mediaDate'))}
                  </div>
                </TableCell>
                <TableCell className="previously__timecell">
                  {I18n.t('page.playerPage.reviewDetails.time')}
                </TableCell>
                <TableCell className="previously__vehiclecell">
                  <div
                    className={`table__sortcont${isSameSortField('vehicle') ? '-sorted' : ''}`}
                    style={{display: 'inline-block'}}
                    onClick={
                      getSortFieldDirection('vehicle') === 'desc'
                        ? () => onChangeReviewSort('vehicle', 'asc', this.loadIfScreenIsBig)
                        : () => onChangeReviewSort('vehicle', 'desc', this.loadIfScreenIsBig)
                    }
                  >
                    {I18n.t('page.playerPage.reviewDetails.vehicleText')}
                    {this.renderSortIcon(getSortFieldDirection('vehicle'))}
                  </div>
                </TableCell>
                <TableCell className="previously__reviewcell">
                  {I18n.t('page.playerPage.reviewDetails.review')}
                </TableCell>
                <TableCell className="previously__coachingcomcell">
                  {I18n.t('page.playerPage.reviewDetails.coachingComments')}
                </TableCell>
                <TableCell className="previously__reviewcompletecell">
                  <div
                    className={`table__sortcont${isSameSortField('metadata.review.completed') ? '-sorted' : ''}`}
                    style={{display: 'inline-block'}}
                    onClick={
                      getSortFieldDirection('metadata.review.completed') === 'desc'
                        ? () => onChangeReviewSort('metadata.review.completed', 'asc', this.loadIfScreenIsBig)
                        : () => onChangeReviewSort('metadata.review.completed', 'desc', this.loadIfScreenIsBig)
                    }
                  >
                    {I18n.t('page.playerPage.reviewDetails.reviewedComplete')}
                    {this.renderSortIcon(getSortFieldDirection('metadata.review.completed'))}
                  </div>
                </TableCell>
                <TableCell className="previously__coachingcompletecell">
                  <div
                    className={`table__sortcont${isSameSortField('metadata.coach.completed') ? '-sorted' : ''}`}
                    style={{display: 'inline-block'}}
                    onClick={
                      getSortFieldDirection('metadata.coach.completed') === 'desc'
                        ? () => onChangeReviewSort('metadata.coach.completed', 'asc', this.loadIfScreenIsBig)
                        : () => onChangeReviewSort('metadata.coach.completed', 'desc', this.loadIfScreenIsBig)
                    }
                  >
                    {I18n.t('page.playerPage.reviewDetails.coachingComplete')}
                    {this.renderSortIcon(getSortFieldDirection('metadata.coach.completed'))}
                  </div>
                </TableCell>
                <TableCell className="previously__scorecell">
                  <div
                    className={`table__sortcont${isSameSortField('metadata.review.points') ? '-sorted' : ''}`}
                    style={{display: 'inline-block'}}
                    onClick={
                      getSortFieldDirection('metadata.review.points') === 'desc'
                        ? () => onChangeReviewSort('metadata.review.points', 'asc', this.loadIfScreenIsBig)
                        : () => onChangeReviewSort('metadata.review.points', 'desc', this.loadIfScreenIsBig)
                    }
                  >
                    {I18n.t('page.playerPage.reviewDetails.score')}
                    {this.renderSortIcon(getSortFieldDirection('metadata.review.points'))}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div
            style={{height: 350, overflowY: 'scroll', width: '100%'}}
            onScroll={this.startLazyLoading}
          >
            <Table>
              <TableBody >
                {searchingReviews && (
                  <TableRow key="spinner">
                    <TableCell colSpan={11}>
                      <ProgressSpinner show={searchingReviews} />
                    </TableCell>
                  </TableRow>
                )}
                {!searchingReviews && (
                  <Fragment>
                    {this.renderQueueEmpty()}
                    {previouslyReviews && previouslyReviews.map(this.renderQueueItem)}
                  </Fragment>
                )}
                {searchingMoreReviews &&
                <TableRow key="spinner">
                  <TableCell colSpan={11}>
                    <ProgressSpinner show={searchingMoreReviews} />
                  </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}
