import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import classnames from 'classnames';
import {I18n} from 'react-redux-i18n';
import {isInternalUser} from '@peoplenet/vi-components-ui/src/app/user';

export default class CommentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: props.text || '',
      loading: false,
      posted: false,
      success: false,
      tooLong: false
    };
  }

  closeSnackBar = () => {
    this.setState({
      posted: false,
      success: false
    });
  }

  handlePost = () => {
    const {commentText} = this.props;
    const {userInformation: {userId, userName}} = this.props;
    const commentBody = {
      text: commentText,
      userId,
      userName
    };

    const doPost = () => {
      const {commentId} = this.props;
      this.props
        .postComment(this.props.mediaId, commentBody, this.props.commentId)
        .then(success => {
          // when editing, component will unmount before this
          if (!commentId) {
            this.setState({loading: false, success}, () => setTimeout(() => {
              this.setState({posted: false, success: false});
            }, 2000));
          }
        });
    };


    this.setState({commentText: '', loading: true, posted: true}, doPost);
    this.props.onChangeCommentText({target: {value: '', edit: this.props.edit}})
  }

  handleChange = ({target}) => {
    const {value} = target;
    const tooLong = value.length > 300;
    this.setState({commentText: value, tooLong});
  }

  disableSubmit = () => {
    return !this.props.commentText ||
      this.props.commentText === this.props.text ||
      this.state.tooLong ||
      isInternalUser();
  }

  renderFeedback = () => {
    const {loading, posted, success} = this.state;
    if (!posted || loading) return null;

    if (!this.props.isMobile) {
      return (
        <div className="reviewdesktop__commentsfeedbackmsg">
          {I18n.t(`page.playerPage.comments.${success ? 'saved' : 'failed'}`)}
        </div>
      );
    }

    return (
      <Snackbar
        className="reviewdesktop__snackbar-success"
        open={posted && !loading}
        autoHideDuration={5000}
        onClose={this.closeSnackBar}
        message={I18n.t(`page.playerPage.comments.${success ? 'saved' : 'failed'}`)}
      />
    );
  }

  render() {
    const {edit = false} = this.props;
    let formClasses = [];
    if (edit) {
      formClasses = ['reviewdesktop__commentrow', 'reviewdesktop__commentrow-editing'];
    }
    return (
      <div className={classnames(formClasses)}>
        <FormControl error aria-describedby="error-text" className="reviewdesktop__comments">
          <TextField
            id={`comment_${edit ? this.props.commentId : 'new'}`}
            value={this.props.commentText}
            error={this.state.tooLong}
            helperText={`${this.props.commentText ? this.props.commentText.length : 0}/300 ${I18n.t('defaults.characters')}`}
            label={I18n.t('page.playerPage.comments.placeholder')}
            margin="normal"
            type="text"
            onChange={this.props.onChangeCommentText}
            fullWidth
            multiline
          />
          {this.state.tooLong &&
            <FormHelperText error>
              {I18n.t('page.playerPage.comments.commentTooLong')}
            </FormHelperText>
          }
        </FormControl>
        {this.state.loading && <LinearProgress className="reviewdesktop__progressline" />}
        <div className="reviewdesktop__actions">
          {this.renderFeedback()}
          {edit &&
            <Button onClick={this.props.cancel} variant="contained" color="default" className="btn btn-secondary">
              {I18n.t('page.playerPage.comments.form.cancel')}
            </Button>
          }
          <Button disabled={this.disableSubmit()} onClick={this.handlePost} variant="contained" color="default" className="btn btn-primary">{I18n.t('page.playerPage.comments.form.submit')}</Button>
        </div>
      </div>
    );
  }
}
