import React from 'react';
import {connect} from 'react-redux';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import {I18n} from 'react-redux-i18n';
import {switchMobile} from '@peoplenet/vi-components-ui/src/app/configuration/configurationAction';

class PlatformSwitcher extends React.Component {
  handleSwitchToMobile = () => {
    this.props.switchMobile(!this.props.isMobile);
  }

  render() {
    const {isMobile} = this.props;
    return (
      <MenuItem button onClick={this.handleSwitchToMobile}>
        <Icon className="profile-dropdown__desktopswitcher-icon">swap_horiz</Icon>
        {I18n.t(`action.${isMobile ? 'switchToDesktop' : 'switchToMobile'}`)}
      </MenuItem>
    );
  }
}

const mapStateToProps = state => ({isMobile: state.configuration.isMobile, ...state.i18n.locale});
export default connect(mapStateToProps, {switchMobile})(PlatformSwitcher);
