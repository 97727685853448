import React from 'react';
import {connect} from 'react-redux';
import {Icon, IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import Dialog from '@material-ui/core/Dialog';
import deleteVideo from '../DeleteActions';
import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';
import DeleteDialogContent from './DeleteDialogContent';

class DeleteButton extends React.Component {
  static propTypes = {
    videoId: PropTypes.string.isRequired
  }

  state = {
    open: false
  };

  handleDelete = () => {
    if (this.props.isInternalSalesUser) {
      return;
    }
    this.setState({open: true});
  }

  handleConfirmDelete = () => {
    this.props.deleteVideo(this.props.videoId);
  }

  handleCancel = () => {
    this.setState({open: false});
  }

  render() {
    if (this.props.canDelete) {
      return (
        <React.Fragment>
          <div>
            <IconButton onClick={this.handleDelete} id="playerDelete" disabled={this.props.isInternalSalesUser} title={this.props.isInternal ? I18n.t('defaults.noPermissionFunctionality') : I18n.t('action.delete')}>
              <Icon>delete</Icon>
            </IconButton>
            <Dialog
              open={this.state.open}
              onClose={this.handleCancel}
              aria-labelledby="delete-dialog-title"
              aria-describedby="delete-dialog-description"
            >
              <DeleteDialogContent
                title={I18n.t('page.playerPage.deleteVideo.deleteConfirmation.title')}
                confirmMessage={I18n.t('page.playerPage.deleteVideo.deleteConfirmation.message')}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirmDelete}
              />
            </Dialog>
          </div>
          {this.props.isMenu && <span onClick={this.handleDelete} disabled={this.props.isInternalSalesUser}>{I18n.t('page.playerPage.actions.delete')}</span>}
        </React.Fragment>
      );
    }

    return null;
  }
}

const mapStateToProps = () => {
  const canDelete = hasAccess([
    'changeCustomer',
    'videoIntelligenceManager',
    'videoIntelligenceAdmin'
  ]);

  const isInternal = hasAccess([
    'videoIntelligenceInternal'
  ]);

  return {canDelete, isInternal};
};

export default connect(mapStateToProps, {deleteVideo})(DeleteButton);
