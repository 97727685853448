import {push} from 'connected-react-router';
import {
  CUSTOMER_SEARCH_REQUEST,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAIL,
  CLEAR_SWITCH_ACCOUNTS
} from './SwitchCustomerActionsType';
import {CLEAR_FILTER} from '@peoplenet/vi-components-ui/src/LibraryFilter/libraryFilterActionsType';
import {SET_CUSTOMER} from '@peoplenet/vi-components-ui/src/app/user/UserActionsType';
import {loadGroups, loadTerminals, loadOerTypes} from '@peoplenet/vi-components-ui/src/LibraryFilter/libraryFilterActions';
import * as actionTypes from '@peoplenet/vi-components-ui/src/actions/constants';
import {MEDIA_FORCE_SEARCH} from '../library/LibraryActionsType';
import SwitchCustomerService from './service/SwitchCustomerService';

export const loadCustomers = () => async dispatch => {
  dispatch({type: CUSTOMER_SEARCH_REQUEST});
  try {
    const customers = await SwitchCustomerService.loadCustomers();
    dispatch({type: CUSTOMER_SEARCH_SUCCESS, payload: customers});
  } catch (e) {
    console.error(e);
    dispatch({type: CUSTOMER_SEARCH_FAIL});
  }
};

export const changeCustomer = selectedCustomer => dispatch => {
  const {id} = selectedCustomer;
  dispatch({type: SET_CUSTOMER, payload: {cid: id, customerId: id}});
  dispatch({type: CLEAR_FILTER});
  dispatch({type: actionTypes.CLEAR_SETTINGS});
  dispatch(push('/library'));
  dispatch({type: MEDIA_FORCE_SEARCH});
  loadGroups()(dispatch);
  loadTerminals()(dispatch);
  loadOerTypes()(dispatch);
};

export const clear = () => dispatch => dispatch({type: CLEAR_SWITCH_ACCOUNTS});
