import * as qs from 'query-string';
import config from '@peoplenet/vi-components-ui/src/components/Auth/trimble-id-config';
import {VEHICLE_NUMBER_KEY, ON_DEMAND_FILTER_KEY, REDIRECT_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';
import {VEHICLE_ONDEMAND_KEY} from '@peoplenet/vi-components-ui/src/app/configuration/localstorageKeys';

class LoginAuth {
  setLogin({
    accessToken,
    expiresIn,
    sessionState,
    idToken,
    state
  }) {
    const params = qs.parse(state);
    const systemId = params.ref;

    if (systemId) {
      localStorage.setItem('systemId', systemId);
    } else {
      localStorage.removeItem('systemId');
    }

    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', JSON.stringify((expiresIn * 1000) + new Date().getTime()));
    localStorage.setItem('session_state', sessionState);

    // redirect user to where it was before being redirected to the login page
    const newParams = qs.parse(window.location.search);
    if (params[VEHICLE_NUMBER_KEY]) {
      newParams[VEHICLE_NUMBER_KEY] = params[VEHICLE_NUMBER_KEY];
    }

    if (params[ON_DEMAND_FILTER_KEY]) {
      newParams[ON_DEMAND_FILTER_KEY] = params[ON_DEMAND_FILTER_KEY];
    }

    if (params[REDIRECT_KEY]) {
      window.location = `${params[REDIRECT_KEY]}?${qs.stringify(newParams)}`;
    } else if (newParams[VEHICLE_NUMBER_KEY] || newParams[ON_DEMAND_FILTER_KEY]) {
      window.location.search = qs.stringify(newParams);
    }
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  handleAuthentication() {
    if (!this.isAuthenticated()) {
      window.location.href = config.urlLogin;
    }
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('session_state');
    localStorage.removeItem('systemId');
    localStorage.removeItem(VEHICLE_ONDEMAND_KEY);
    window.location.href = config.urlLogout;
  }
}

export default new LoginAuth();
