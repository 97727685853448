import React, {Fragment} from 'react';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import {get} from 'lodash';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import FlagVideo from '@peoplenet/vi-components-ui/src/FlagVideo';
import CameraViewsMenu from '@peoplenet/vi-components-ui/src/Player/CameraViewsMenu';
import FullVideoPlayer from '@peoplenet/vi-components-ui/src/Player/FullVideoPlayer';
import ViAppBar from '../../components/ViAppBar';
import ProgressSpinner from '../../components/ProgressSpinner';
import DownloadVideo from '../../downloadVideo/component/DownloadVideo';
import PlayerBottomNav from '../../components/PlayerBottomNav';
import MediaMap from '../../components/MediaMap';
import MediaDetails from '../component/MobileMediaDetails';
import Comments from '../component/Comments';
import DeleteVideo from '../../deleteVideo/component/DeleteVideo';
import LockVideo from '../../lockVideo/component/LockVideo';
import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';

class Player extends React.Component {
  constructor(props) {
    super(props);
    const {comment} = props.coach || {};
    this.state = {
      coachingComment: comment || '',
      anchorEl: null
    };
  }

  componentWillReceiveProps(props) {
    if (get(props, 'coach.id') !== get(this.props, 'coach.id')) {
      const {comment = ''} = props.coach || {};
      this.setState({coachingComment: comment});
    }
  }

  handleChangeCoachComment = value => {
    this.setState({coachingComment: value});
  };

  handleOnClickMenuActions = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleOnCloseMenuActions = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {props} = this;
    const eventSecond = get(
      props,
      'media.relatedItems.oerEvent.eventSecond',
      null
    );
    const {anchorEl} = this.state;
    const isAdmin = hasAccess(['videoIntelligenceAdmin']);
    return (
      <div>
        <ViAppBar
          auth={props.auth}
          title={I18n.t('page.playerPage.title')}
          onClickBack={props.handleOnClickBack}
        >
          {props.showMedia && (
            <CameraViewsMenu
              machineType={get(props.media, 'metadata.machineType', 0)}
              clips={get(props.media, 'captures.0.clips', [])}
              currentCamera={props.camera}
              onCameraChange={props.onCameraChange}
            />
          )}
          {props.showMedia && props.media && (
            <FlagVideo
              isInternalSalesUser={props.isInternalSalesUser}
              mediaId={props.media.mediaId}
              flagged={props.media.flagged}
              playerPage
              onFlagVideo={this.props.onFlagVideo}
            />
          )}
          <IconButton
            id="more-actions-menu-icon"
            aria-owns={anchorEl ? 'more-actions-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleOnClickMenuActions}
          >
            <Icon className="viappbar__morevert">more_vert</Icon>
          </IconButton>

          <Menu
            id="more-actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleOnCloseMenuActions}
            className="viappbar__morevert-menu"
          >
            {(props.isInternalSalesUser ||
              props.isInternaPeoplelUser ||
              isAdmin) && (
              <MenuItem>
                {props.showMedia && (
                  <LockVideo
                    userInformation={props.userInformation}
                    media={props.media}
                    isMobile
                    isMenu
                    isAdmin={isAdmin}
                    isInternaPeoplelUser={props.isInternaPeoplelUser}
                    isInternalSalesUser={props.isInternalSalesUser}
                  />
                )}
              </MenuItem>
            )}
            {props.showMedia && (
              <Fragment>
                <MenuItem>
                  <DownloadVideo
                    isInternalSalesUser={props.isInternalSalesUser}
                    clip={props.clip}
                    media={props.media}
                    isMobile
                    isMenu
                  />
                </MenuItem>

                <MenuItem>
                  <DeleteVideo
                    isInternalSalesUser={props.isInternalSalesUser}
                    isInternal={props.isInternal}
                    videoId={props.media.mediaId}
                    isMobile
                    isMenu
                  />
                </MenuItem>
              </Fragment>
            )}
          </Menu>
        </ViAppBar>
        <div className="bodycontent">
          <ProgressSpinner show={props.isLoadingTop} />
          {props.showMedia && !props.isLoadingTop && (
            <Grid container className="media">
              <Grid
                item
                xl={6}
                lg={7}
                md={8}
                sm={12}
                xs={12}
                className="playerbody"
              >
                <FullVideoPlayer
                  mobile
                  mediaLoaded={props.showMedia}
                  showDashboard={props.selectedTab.isDashboard}
                  currentCamera={props.camera}
                  flowplayerKey={props.flowplayerKey}
                  media={props.media}
                  clip={props.clip}
                  customerSettings={get(props, 'customer.data.settings', {})}
                  onPlayerTimeChanged={props.onPlayerTimeChanged}
                  onCameraChange={props.onCameraChange}
                  userSettings={props.userSettings}
                />
                <MediaMap
                  options={props.mapOptions}
                  location={props.media.location}
                  oerMarker={props.media.location}
                  oerItem={eventSecond}
                  data={props.perSecondData}
                  playerSecond={props.playerSecond}
                />
                {props.media && (
                  <MediaDetails
                    media={props.media}
                    review={props.review}
                    coach={props.coach}
                    isInternalSalesUser={props.isInternalSalesUser}
                    options={props.mediaDetailsOptions}
                    onChangeCoachingComment={this.handleChangeCoachComment}
                    coachingComment={this.state.coachingComment}
                  />
                )}
                <Comments
                  {...props.comments}
                  deleteComment={props.deleteComment}
                  postComment={props.postComment}
                  mediaId={props.mediaId}
                  userInformation={props.userInformation}
                  onChangeCommentText={props.onChangeCommentText}
                  commentText={props.commentText}
                  isMobile
                  visible={get(props, 'selectedTab.isComment', false)}
                />
              </Grid>
            </Grid>
          )}
          <PlayerBottomNav
            options={props.bottomNavOptions}
            commentsSize={props.comments.entries.length}
          />
          <div className={props.errorMessage ? '' : 'hideme'}>
            <Grid container className="media">
              <Grid item xl={3} lg={6} md={6} sm={10} xs={12}>
                <Paper className="papercard" elevation={2}>
                  <Icon className="papercard__erroricon">videocam_off</Icon>
                  <p>{I18n.t('page.playerPage.notFoundVideo')}</p>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({userSettings}) => ({
  userSettings
});

export default connect(mapStateToProps)(Player);
