import React from 'react';
import PropTypes from 'prop-types';
import MediaListCardView from '@peoplenet/vi-components-ui/src/Library/list/MediaListCardView';
import MediaListListView from './MediaListListView';
import {viewMode} from '../constants';

export default class MediaList extends React.PureComponent {
  static defaultProps = {
    medias: [],
    viewMode: viewMode.CARD
  }
  static propTypes = {
    medias: PropTypes.arrayOf(PropTypes.shape({})),
    viewMode: PropTypes.string
  }

  render() {
    if (this.props.viewMode === viewMode.CARD) {
      return (
        <MediaListCardView
          onVideoOpen={this.props.handleClickVideo}
          onFlagVideo={this.props.onFlagVideo}
          onDeleteVideo={this.props.deleteVideo}
          canDelete={this.props.canDelete}
          askDeleteConfirmation={false}
          user={this.props.user}
          isInternal={this.props.isInternal}
          medias={this.props.medias}
          prepareReference={this.props.prepareReference}
          isMobile={this.props.isMobile}
          locale={this.props.locale}
          addFilterChipOnDriverClick={this.props.addFilterChipOnDriverClick}
          addFilterChipOnVehicleClick={this.props.addFilterChipOnVehicleClick}
          addFilterChipOnTerminalClick={this.props.addFilterChipOnTerminalClick}
          addFilterOnOERClick={this.props.addFilterOnOERClick}
          addOnDemandFilter={this.props.addOnDemandFilter}
        />
      );
    }

    return (
      <MediaListListView
        medias={this.props.medias}
        onVideoClick={this.props.handleClickVideo}
        prepareReference={this.props.prepareReference}
        isMobile={this.props.isMobile}
        onDeleteVideo={this.props.deleteVideo}
        onCheckMedia={this.props.onCheckMedia}
        locale={this.props.locale}
        user={this.props.user}
        downloadListViewAsCsv={this.props.handleDownloadListViewAsCsv}
        clearDownloadListViewCsv={this.props.handleClearDownloadListViewCsv}
        listViewCsv={this.props.listViewCsv}
        listViewCsvIsLoading={this.props.listViewCsvIsLoading}
      />
    );
  }
}
