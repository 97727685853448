/* eslint-disable quote-props */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import {I18n} from 'react-redux-i18n';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import DateTimePicker from './DateTimePicker';

export default class DateTimeFilterDesktop extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired
  }

  static ranges = {
    'Today': {
      startTime: moment().startOf('day'),
      endTime: moment().endOf('day'),
      label: 'filter.date.range.today'
    },
    'Yesterday': {
      startTime: moment().subtract(1, 'days').startOf('day'),
      endTime: moment().subtract(1, 'days').endOf('day'),
      label: 'filter.date.range.yesterday'
    },
    'Last 7 Days': {
      startTime: moment().subtract(6, 'days').startOf('day'),
      endTime: moment().endOf('day'),
      label: 'filter.date.range.last7days',
      amount: 7
    },
    'Last Week': {
      startTime: moment().subtract(1, 'week').startOf('week'),
      endTime: moment().subtract(1, 'week').endOf('week'),
      label: 'filter.date.range.lastWeek',
      amount: 7
    },
    'Last 30 Days': {
      startTime: moment().subtract(30, 'days').startOf('day'),
      endTime: moment().subtract(1, 'days').endOf('day'),
      label: 'filter.date.range.last30days',
      amount: 30
    },
    'Last Month': {
      startTime: moment().subtract(1, 'months').startOf('month'),
      endTime: moment().subtract(1, 'months').endOf('month'),
      label: 'filter.date.range.lastMonth',
      amount: 30
    },
    '3 Months': {
      startTime: moment().subtract(90, 'days').startOf('day'),
      endTime: moment().subtract(1, 'days').endOf('day'),
      label: 'filter.date.range.3months',
      amount: 90
    },
    '6 Months': {
      startTime: moment().subtract(180, 'days').startOf('day'),
      endTime: moment().subtract(1, 'days').endOf('day'),
      label: 'filter.date.range.6months',
      amount: 180
    }
  }

  constructor(props) {
    super(props);
    const {startTime, endTime, periods} = props;

    this.state = {startTime, endTime, periods};
  }

  getChipTimeClass(period) {
    const times = DateTimeFilterDesktop.ranges[period];
    if (times) {
      if (times.startTime.valueOf() === this.state.startTime
        && times.endTime.valueOf() === this.state.endTime) {
        return 'chip__selected';
      }
    }
    return 'librarylist__datechips';
  }

  handleChipTime(type) {
    const times = DateTimeFilterDesktop.ranges[type];
    if (times) {
      const startTime = times.startTime.valueOf();
      const endTime = times.endTime.valueOf();
      this.setState({startTime, endTime}, () => {
        this.props.onChange({startTime, endTime});
      });
    }
  }

  handleOnChangedDate(date, convertedToTime) {
    this.setState(
      {[date]: convertedToTime},
      () => {
        const {startTime, endTime} = this.state;
        this.props.onChange({startTime, endTime});
      }
    );
  }

  renderChips(key) {
    const {
      label,
      amount
    } = DateTimeFilterDesktop.ranges[key];

    let labelFormated = {};
    if (amount) {
      labelFormated = I18n.t(label, {amount});
    } else {
      labelFormated = I18n.t(label);
    }

    return (
      <Chip
        avatar={
          <Avatar className="chip">
            <Icon className="chip__icon">event</Icon>
          </Avatar>
        }
        label={labelFormated}
        className={this.getChipTimeClass(key)}
        onClick={() => this.handleChipTime(key)}
        id={`dateFilter${key.replace(' ', '')}`}
      />
    );
  }

  render() {
    return (
      <div>
        <ListItem className="librarylist__datechipscont">
          {this.state.periods.map(key => this.renderChips(key))}
        </ListItem>
        <div className="desktopdatepicker__customdate">
          <ListItem>
            <DateTimePicker
              label={I18n.t('filter.date.start')}
              mode="start"
              onChange={e => this.handleOnChangedDate('startTime', e)}
              max={moment(this.props.endTime).startOf('day').valueOf()}
              min={moment().startOf('day').subtract(180, 'days').endOf('day').valueOf()}
              value={this.state.startTime}
              id="dateFilterStartTime"
            />
          </ListItem>
          <ListItem>
            <DateTimePicker
              label={I18n.t('filter.date.end')}
              mode="end"
              onChange={e => this.handleOnChangedDate('endTime', e)}
              value={this.state.endTime}
              max={moment().valueOf()}
              min={moment(this.state.startTime).endOf('day').valueOf()}
              id="dateFilterEndTime"
            />
          </ListItem>
        </div>
      </div>
    );
  }
}
