import {get, groupBy, sortBy} from 'lodash';
import BaseService from '../../middleware/BaseService';

const MEDIA_ENDPOINT = '/medias';

class PlayerService extends BaseService {
  prefix = MEDIA_ENDPOINT;

  async postCoach(params) {
    const response = await this.post(params, `${MEDIA_ENDPOINT}/coach`);
    return get(response, 'data[0]', {});
  }

  async getComments(mediaId) {
    const response = await this.get(null, `${MEDIA_ENDPOINT}/${mediaId}/comments`);
    return get(response, 'data', []);
  }

  async postComment(mediaId, body, commentId) {
    if (commentId) {
      return this.post(body, `${MEDIA_ENDPOINT}/comment/${commentId}/update`);
    }
    const response = await this.post(body, `${MEDIA_ENDPOINT}/${mediaId}/comment`);
    return {id: response.commentId, date: response.date};
  }

  async deleteComment(commentId) {
    return this.remove(commentId, `${MEDIA_ENDPOINT}/comment`);
  }

  async getReview(mediaId) {
    const {data} = await this.get(undefined, `${MEDIA_ENDPOINT}/${mediaId}/review`);
    return this.mapReviewToUI(data);
  }

  async searchReview(params) {
    const {data: review, meta: {total}} = await this.search(params, `${MEDIA_ENDPOINT}/review`);
    return {total, review};
  }

  async postReview(data) {
    const body = this.createReviewPayloadToPost(data);
    const response = await this.post(body, `${MEDIA_ENDPOINT}/review`);
    return {
      reviewerName: body.reviewerName,
      date: body.media.date,
      score: body.score,
      id: get(response, 'data[0].reviewId', '')
    };
  }

  createReviewPayloadToPost({
    media,
    user,
    review: {
      id,
      mainCategory,
      otherCategories,
      completed
    },
    coach
  }) {
    return {
      id,
      completed,
      coachCompleted: get(coach, 'completed', false),
      tagReview: get(media, 'tag.review.value', false),
      media: {
        cid: get(media, 'cid'),
        date: new Date().getTime(),
        id: get(media, 'mediaId'),
        vehicleNumber: get(media, 'vehicle')
      },
      reviewerId: get(user, 'userInformation.userId'),
      reviewerName: get(user, 'userInformation.userName'),
      ...this.mapStateReview({mainCategory, otherCategories})
    };
  }

  mapStateReview({mainCategory, otherCategories}) {
    let points = 0;
    const score = [];
    mainCategory.reviewOptions.forEach(item => {
      if (item.marked && item.points) {
        points += item.points;
      }
      score.push(item);
    });

    Object.keys(otherCategories).map(key =>
      otherCategories[key].reviewOptions.forEach(item => {
        if (item.marked && item.points) {
          points += item.points;
        }
        score.push(item);
      }));

    return {
      points,
      score
    };
  }

  mapReviewToUI(review) {
    const mainCategory = this.filterCategory({
      categoryOrder: 0,
      options: review.options
    });

    /*
    This code should be used instead of group by categoryName,
    it's being used like this now because we have
    two categories with the same order, we changed this in
    ElasticSearch but we need to make it work for previous
    reviews.
    */
    // const otherCategories = groupBy(this.filterCategory({
    //   categoryOrder: 0,
    //   options: review.options,
    //   equal: false
    // }), 'categoryOrder');
    const otherCategories = groupBy(this.filterCategory({
      categoryOrder: 0,
      options: review.options,
      equal: false
    }), 'categoryName');

    return {
      ...review,
      mainCategory: {
        categoryName: get(mainCategory, '[0].categoryName', ''),
        categoryNameId: get(mainCategory, '[0].categoryNameId', ''),
        points: get(mainCategory, '[0].points', ''),
        reviewOptions: mainCategory
      },
      otherCategories: this.mapCategories(otherCategories)
    };
  }

  filterCategory({categoryOrder, options, equal = true}) {
    return options.filter(option => {
      if (equal) {
        return option.categoryOrder === categoryOrder;
      } else {
        return option.categoryOrder !== categoryOrder;
      }
    }).sort((a, b) => a.order - b.order);
  }

  mapCategories(categories) {
    const otherCategories = Object.keys(categories).map(item => ({
      categoryName: get(categories[item], '[0].categoryName'),
      categoryNameId: get(categories[item], '[0].categoryNameId'),
      reviewOptions: categories[item]
    }));

    return sortBy(otherCategories, [item => item.reviewOptions.length, 'categoryName']).reverse();
  }
}

export default new PlayerService();
