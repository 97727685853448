import {get} from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {setCoach} from '../../actions/player';
import CoachingForm from '../component/CoachingForm';
import {isInternalUser} from '@peoplenet/vi-components-ui/src/app/user';

class Coaching extends React.PureComponent {
  handleCoachSubmit = (_coach, media, user) => {
    const coach = {
      coachId: user.userId,
      coachName: user.userName,
      completed: _coach.completed,
      comment: _coach.comment,
      media: {
        id: media.mediaId,
        cid: media.cid
      },
      tagReview: get(media, 'tag.review.value', false),
      reviewCompleted: get(this.props, 'review.completed', false)
    };

    if (_coach.id != null) {
      coach.id = _coach.id;
    }
    return this.props.setCoach(coach);
  }


  render() {
    return (
      <CoachingForm
        coach={this.props.coach}
        review={this.props.review}
        onSubmit={this.handleCoachSubmit}
        coachingComment={this.props.coachingComment}
        onChangeComment={this.props.onChangeComment}
        isMobile={this.props.isMobile}
        canSubmit={!this.props.isInternalSalesUser}
      />
    );
  }
}


const mapStateToProps = state => {
  const {player: {coach, review}} = state;
  return {
    coach,
    review,
    isInternalSalesUser: isInternalUser()
  };
};

export default connect(mapStateToProps, {setCoach})(Coaching);

