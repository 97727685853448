/* eslint-disable react/no-find-dom-node */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {get, size} from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import {connect} from 'react-redux';
import CameraViewsMenu from '@peoplenet/vi-components-ui/src/Player/CameraViewsMenu';
import FullVideoPlayer from '@peoplenet/vi-components-ui/src/Player/FullVideoPlayer';
import PlayerFullDetails from '@peoplenet/vi-components-ui/src/PlayerDetails/PlayerFullDetails';
import FlagVideo from '@peoplenet/vi-components-ui/src/FlagVideo';
import Comments from '../component/Comments';
import LockVideo from '../../lockVideo/component/LockVideo';
import DeleteVideo from '../../deleteVideo/component/DeleteVideo';
import DownloadVideo from '../../downloadVideo/component/DownloadVideo';
import VideoTagging from '../../components/VideoTagging';
import ExtendVideo from '../component/ExtendVideo';
import ViAppBar from '../../components/ViAppBar';
import ProgressSpinner from '../../components/ProgressSpinner';
import MediaMap from '../../components/MediaMap';
import LibraryView from '../component/LibraryView';
import Review from '../container/Review';
import ReviewDetails from '../component/review/ReviewDetailsDesktop';
import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';

function TabContainer(props) {
  return <div>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export const Player = props => {
  const eventSecond = get(props, 'media.relatedItems.oerEvent.eventSecond', null);
  const libraryView = get(props, 'libraryViewOptions', {});
  const renderExtendVideo = !props.isOnDemand && (props.canExtendVideo || props.isInternalUser);
  const isAdmin = hasAccess([
    'videoIntelligenceAdmin'
  ]);
  return (
    <div style={{position: 'relative'}}>
      <ViAppBar auth={props.auth} title={I18n.t('page.playerPage.title')} onClickBack={props.handleOnClickBack} style={{position: 'relative', overflow: 'unset'}} />
      <div className="bodycontent bodycontent__playerdesktop" style={{position: 'relative'}}>
        <ProgressSpinner show={props.isLoadingTop} />
        {
          props.showMedia &&
          !props.isLoadingTop &&
          <React.Fragment>
            <Grid container spacing={16} className="playercont" style={{overflow: 'auto'}}>
              <Grid item lg={5} md={5} sm={6} xs={12}>
                <Card>
                  <div className="card__header">
                    <span className="card__actionscont-left">
                      <VideoTagging
                        media={props.media}
                        coach={props.coach}
                        onSuccess={props.mediaDetailsOptions.events.onTagSuccess}
                        isInternalSalesUser={props.isInternalSalesUser}
                      />
                      { renderExtendVideo && (
                        <ExtendVideo
                          captureStartDate={props.captureStartDate}
                          captureEndDate={props.captureEndDate}
                          machineType={props.machineType}
                          media={props.media}
                          isInternalUser={props.isInternalUser}
                          isInternalSalesUser={props.isInternalSalesUser}
                          userInformation={props.userInformation}
                        />
                      )}
                    </span>
                    <span className="card__actionscont-right">
                      <CameraViewsMenu
                        machineType={get(props.media, 'metadata.machineType', 0)}
                        clips={get(props.media, 'captures.0.clips', [])}
                        currentCamera={props.camera}
                        onCameraChange={props.onCameraChange}
                      />
                      <LockVideo
                        media={props.media}
                        userInformation={props.userInformation}
                        isAdmin={isAdmin}
                        isInternaPeoplelUser={props.isInternaPeoplelUser}
                        isInternalSalesUser={props.isInternalSalesUser}
                      />
                      <DownloadVideo
                        clip={props.clip}
                        camera={props.camera}
                        media={props.media}
                        isInternalSalesUser={props.isInternalSalesUser}
                      />
                      <FlagVideo
                        mediaId={props.media.mediaId}
                        flagged={props.media.flagged}
                        playerPage
                        isInternalSalesUser={props.isInternalSalesUser}
                        onFlagVideo={props.onFlagVideo}
                      />
                      <DeleteVideo
                        videoId={props.media.mediaId}
                        isInternal={props.isInternalUser}
                        isInternalSalesUser={props.isInternalSalesUser}
                      />
                    </span>
                  </div>
                  <CardContent className="card__content">
                    <FullVideoPlayer
                      mediaLoaded={props.showMedia}
                      currentCamera={props.camera}
                      flowplayerKey={props.flowplayerKey}
                      media={props.media}
                      clip={props.clip}
                      customerSettings={get(props, 'customer.data.settings', {})}
                      onPlayerTimeChanged={props.onPlayerTimeChanged}
                      onCameraChange={props.onCameraChange}
                      userSettings={props.userSettings}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={7} md={7} sm={6} xs={12}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={props.tabIndex}
                    tabIndex={props.tabIndex}
                    onChange={props.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label={I18n.t('page.playerPage.tabs.map')} id="playerReviewTab" />
                    <Tab label={I18n.t('page.playerPage.tabs.fullDetails')} id="playerFulldetailsTab" />
                    <Tab label={I18n.t('page.playerPage.tabs.review')} id="reviewTab" />
                    <Tab
                      label={
                        <Badge
                          className="tab-badge"
                          badgeContent={size(props.comments.entries)}
                        >
                          {I18n.t('page.playerPage.tabs.comment')}
                        </Badge>
                      }
                      id="commentsTab"
                    />
                  </Tabs>
                </AppBar>
                <SwipeableViews index={props.tabIndex} onChangeIndex={props.handleChangeTabIndex} draggable={false} className="lowerindex" style={{position: 'relative'}}>
                  <TabContainer>
                    <div style={{overflow: 'hidden', width: '100%'}}>
                      <MediaMap
                        options={{show: true, isMobile: props.isMobile}}
                        location={props.media.location}
                        oerMarker={props.media.location}
                        oerItem={eventSecond}
                        data={props.perSecondData}
                        playerSecond={props.playerSecond}
                        visible={props.tabIndex === 0}
                      />
                    </div>
                  </TabContainer>
                  <TabContainer>
                    <PlayerFullDetails
                      media={props.media}
                      review={props.review}
                      clip={props.clip}
                      visible={props.tabIndex === 1}
                    />
                  </TabContainer>
                  <TabContainer>
                    <Fragment>
                      <Review
                        tabIndex={props.subTabIndex}
                        key={props.review.updated}
                        coach={props.coach}
                        textSearch={props.textSearch}
                        onChanteTextSearch={props.onChanteTextSearch}
                        onChangePage={props.onChangePage}
                        onSearchReview={props.onSearchReview}
                        onChangeReviewSort={props.onChangeReviewSort}
                        onLoadReview={props.onLoadReview}
                        getSortFieldDirection={props.getSortFieldDirection}
                        onChangeHeaderVisibility={props.onChangeHeaderVisibility}
                        currentPage={props.currentPage}
                        isSameSortField={props.isSameSortField}
                        previouslyReviews={props.previouslyReviews}
                        previouslyReviewsTotal={props.previouslyReviewsTotal}
                        searchingReview={props.searchingReviews}
                        visible={props.tabIndex === 2}
                        component={ReviewDetails}
                      />
                    </Fragment>
                  </TabContainer>
                  <TabContainer>
                    <Comments
                      {...props.comments}
                      postComment={props.postComment}
                      deleteComment={props.deleteComment}
                      mediaId={props.mediaId}
                      onChangeCommentText={props.onChangeCommentText}
                      commentText={props.commentText}
                      userInformation={props.userInformation}
                      visible={props.tabIndex === 3}
                    />
                  </TabContainer>
                </SwipeableViews>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <LibraryView
                list={libraryView.list}
                isLoading={libraryView.isLoading}
                hasMorePages={libraryView.hasMorePages}
                hasLessPages={libraryView.hasLessPages}
                mediaId={libraryView.mediaId}
                onLoad={libraryView.events.onLoad}
                onOpenVideo={libraryView.events.onOpenVideo}
                onFlagVideo={props.onFlagVideo}
                onScrollLeft={libraryView.events.onScrollLeft}
                onScrollRight={libraryView.events.onScrollRight}
              />
            </Grid>
          </React.Fragment>
        }
        <div className={props.errorMessage ? '' : 'hideme'}>
          <Grid container className="media">
            <Grid item xl={3} lg={6} md={6} sm={10} xs={12}>
              <Paper className="papercard" elevation={2}>
                <Icon className="papercard__erroricon">videocam_off</Icon>
                <p>
                  {I18n.t('page.playerPage.notFoundVideo')}
                </p>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userSettings: state.userSettings
  }
};

export default connect(mapStateToProps)(Player);
