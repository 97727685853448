import moment from 'moment';
import {forEach, get} from 'lodash';
import * as actionTypes from '@peoplenet/vi-components-ui/src/actions/constants';
import {
  SAVE_REQUEST,
  SAVE_SUCCESS
} from '@peoplenet/vi-components-ui/src/admin/settings/SettingsActionsType';
import {updatePendoCustomer} from '@peoplenet/vi-components-ui/src/app/pendo/Pendo';


const initialState = {
  data: null,
  loading: false,
  saving: false,
  saved: false
};

export default function customer(state = initialState, action) {
  let item;
  const {payload} = action;
  const settings = get(payload, 'settings', null);
  const intelliview = get(payload, 'intelliview', null);
  const blurring_v2 = get(payload, 'blurring_v2', null);
  const dataRetention = get(payload, 'dataRetention', null);
  const sdCardRetention_v2 = get(payload, 'sdCardRetention_v2', null);
  switch (action.type) {
    case SAVE_REQUEST:
      return {
        ...state,
        saved: false,
        saving: true,
        updatedAt: moment()
      };
    case SAVE_SUCCESS:
      if (payload) {
        if (payload.oerList) {
          const excludedOER = [];
          forEach(payload.oerList, (value, key) => {
            if (value === false) {
              excludedOER.push(key);
            }
          });
          payload.excludedOER = excludedOER;
        }
      }
      return {
        ...state,
        saved: true,
        saving: false,
        data: {...state.data, settings, intelliview, blurring_v2, dataRetention, sdCardRetention_v2},
        updatedAt: moment(),
        errorMessage: (payload == null)
      };
    case actionTypes.GET_REQUEST:
      return {
        ...state, errorMessage: false, loading: true, saved: false, saving: false
      };
    case actionTypes.GET_SUCCESS:
      item = payload || {};

      updatePendoCustomer(item);

      if (item.settings) {
        if (item.settings.oerList) {
          const excludedOER = [];
          forEach(item.settings.oerList, (value, key) => {
            if (value === false) {
              excludedOER.push(key);
            }
          });
          item.excludedOER = excludedOER;
        }

        // Initialize settings
        if (item.settings.overSpeed == null) {
          item.settings.overSpeed = 0;
        }
        if (item.settings.excessiveOverSpeed == null) {
          item.settings.excessiveOverSpeed = 0;
        }
        if (!item.settings.install) {
          item.settings.install = get(state, 'data.settings.install', {});
        }
      }


      return {
        ...state,
        data: item,
        loading: false,
        updatedAt: moment(),
        errorMessage: (item == null)
      };
    case actionTypes.GET_FAIL:
      return {...state, errorMessage: true};
    case actionTypes.CLEAR_SETTINGS:
      return {...state, ...initialState};
    default:
      return state;
  }
}
