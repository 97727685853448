import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import Icon from '@material-ui/core/Icon';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import DateTimeFilter from './DateTimeFilterDesktop';

export class DatePickerChip extends Component {
  static propTypes = {
    handleOnDateApply: PropTypes.func.isRequired,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    label: PropTypes.string,
    datePeriods: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  static defaultProps = {
    startTime: DateTimeFilter.ranges['6 Months'].startTime.valueOf(),
    endTime: DateTimeFilter.ranges['6 Months'].endTime.valueOf(),
    label: DateTimeFilter.ranges['6 Months'].label
  }

  static STANDARD_NUMBER_OF_FILTERS = 4;

  constructor(props) {
    super(props);
    const {
      startTime,
      endTime,
      label
    } = props;

    this.state = {
      open: false,
      startTime,
      endTime,
      startTimeSelected: startTime,
      endTimeSelected: endTime,
      label
    };
  }

  componentDidMount = () => {
    this.setLabel();
  }

  componentWillReceiveProps(props) {
    const {
      startTime,
      endTime
    } = props;

    this.setState({
      startTime,
      endTime
    });
  }

  setLabel = () => {
    let label = null;
    Object.keys(DateTimeFilter.ranges).some(key => {
      const item = DateTimeFilter.ranges[key];
      if (item.startTime.valueOf() === this.state.startTime &&
        item.endTime.valueOf() === this.state.endTime) {
        label = I18n.t(item.label);
        this.setState({label});
        return true;
      }
    });
    if (!label) {
      const format = 'MM/DD/YYYY';
      label = `${moment(this.state.startTime).format(format)} - ${moment(this.state.endTime).format(format)}`;
      this.setState({label});
    }
  }

  handleOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleDateTime = (dateTime = {}) => {
    const {startTime, endTime} = dateTime;
    this.setState({startTimeSelected: startTime, endTimeSelected: endTime});
  }

  handleOnFilter = () => {
    const {
      startTimeSelected,
      endTimeSelected
    } = this.state;

    this.setState({
      open: false,
      startTime: startTimeSelected,
      endTime: endTimeSelected
    }, this.applySelectedFilter);
  }

  applySelectedFilter = () => {
    const {
      startTime,
      endTime
    } = this.state;

    this.props.handleOnDateApply({startTime, endTime});
    this.setLabel();
  }

  handleOnClear = () => {
    this.setState({
      startTimeSelected: DatePickerChip.defaultProps.startTime,
      endTimeSelected: DatePickerChip.defaultProps.endTime,
      label: DatePickerChip.defaultProps.label
    }, this.handleOnFilter);
  }

  render() {
    const numOfDatePeriods = this.props.datePeriods.length;
    return (
      <div>
        <Chip
          avatar={
            <Avatar className="chip">
              <Icon className="chip__icon">event</Icon>
            </Avatar>
          }
          id="dateFilter"
          label={I18n.t(`filter.date.range.${this.state.label}`)}
          className="chip__datepickerchip"
          onClick={this.handleOpen}
          onDelete={this.handleOpen}
          deleteIcon={
            <EditIcon className="chip__modeediticon" />
          }
        />
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          BackdropProps={{
            invisible: true
          }}
          classes={{
            root: 'desktopdatepicker',
            paper: numOfDatePeriods > DatePickerChip.STANDARD_NUMBER_OF_FILTERS ? `desktopdatepicker__paper__${numOfDatePeriods}` : 'desktopdatepicker__paper'
          }}
        >
          <List className="desktopdatepicker__cont">
            <DateTimeFilter
              onChange={this.handleDateTime}
              onChangeSort={this.handleSortDateTime}
              startTime={this.state.startTime}
              endTime={this.state.endTime}
              periods={this.props.datePeriods}
            />
            <ListItem className="desktopdatepicker__actions">
              <Button variant="contained" onClick={this.handleOnClear} id="dateFilterClear" className="btn btn-secondary">{I18n.t('action.clear')}</Button>
              <Button variant="contained" color="primary" id="dateFilterApply" className="btn btn-primary" onClick={this.handleOnFilter}>{I18n.t('action.apply')}</Button>
            </ListItem>
          </List>
        </Dialog>
      </div>
    );
  }
}

const styleSheet = () => ({
  flex: {
    flex: 1
  }
});

export default withStyles(styleSheet)(DatePickerChip);

