import {push} from 'connected-react-router';
import querystring from 'querystring';
import {get, isArray, mapValues, join, filter} from 'lodash';
import moment from 'moment';
import {
  LIBRARY_SAVE,
  LIBRARY_CLEAN_SAVE,
  MEDIA_SEARCH_REQUEST,
  MEDIA_SEARCH_SUCCESS,
  MEDIA_SEARCH_FAIL,
  LIBRARY_CLEAR,
  UPDATE_MEDIALIST,
  CHANGE_VIEWMODE,
  DOWNLOAD_LIST_VIEW_AS_CSV_REQUEST,
  DOWNLOAD_LIST_VIEW_AS_CSV_SUCCESS,
  CLEAR_DOWNLOAD_LIST_VIEW_AS_CSV
} from './LibraryActionsType';
import libraryService from './LibraryService';

const parseParams = params => mapValues(params, (par, key) => {
  if (isArray(par) && !(key === 'trigger')) {
    return `[${join(par, ',')}]`;
  }
  return par;
}, {});

export const searchMedias = params => async dispatch => {
  dispatch({type: MEDIA_SEARCH_REQUEST});
  const page = get(params, 'page', -1) + 1;

  const tmpParams = {...params};

  const {invertConcat, overwritePage} = tmpParams;
  delete tmpParams.invertConcat;
  delete tmpParams.overwritePage;

  if (params.suddenstoptype) {
    tmpParams.trigger = filter(tmpParams.trigger, it => it.toUpperCase() !== 'SUDDEN_STOP');
  }

  const startTime = get(params, 'startTime', moment().startOf('day').subtract(29, 'days').utc()
    .valueOf());
  const endTime = get(params, 'endTime', moment().endOf('day').utc().valueOf());

  const sort = `mediaDate:${get(params, 'sortBy', 'desc')}`;

  const parsedParams = parseParams({
    ...tmpParams,
    page,
    startTime,
    endTime,
    sort
  });

  const {from, to} = tmpParams;
  if (from >= 0 && to > 0) {
    delete parsedParams.page;
    delete parsedParams.pageSize;
  }

  try {
    const payload = await libraryService.search(parsedParams);

    payload.meta.invertConcat = invertConcat;
    if (params && overwritePage) {
      payload.meta.paging.page = overwritePage;
      //payload.meta.paging.pages *= overwritePage;
    }
    return dispatch({type: MEDIA_SEARCH_SUCCESS, payload});
  } catch (ex) {
    // TODO: replace with toast
    console.error('Unable to find medias', ex);
    return dispatch({type: MEDIA_SEARCH_FAIL});
  }
};

export const openPlayerPage = params => dispatch => {
  dispatch({type: LIBRARY_SAVE, payload: params});

  const {mediaId, camera} = params;

  const query = {mediaId};

  if (camera) {
    query.camera = camera;
  }

  dispatch(push(`/player?${querystring.stringify(query)}`));
};

export const clear = cleanSaved => dispatch => {
  if (cleanSaved) {
    dispatch({type: LIBRARY_CLEAN_SAVE});
  }
  dispatch({type: LIBRARY_CLEAR});
};

export const downloadListViewAsCsv = (filters, pairingMethod) => async dispatch => {
  dispatch({type: DOWNLOAD_LIST_VIEW_AS_CSV_REQUEST});

  const startTime = get(filters, 'startTime', moment().startOf('day').subtract(29, 'days').utc()
    .valueOf());
  const endTime = get(filters, 'endTime', moment().endOf('day').utc().valueOf());
  const timezone = moment.tz.guess();

  const parsedParams = parseParams({
    ...filters, startTime, endTime, timezone, pairingMethod
  });

  const response = await libraryService.downloadListViewAsCsv(parsedParams);

  dispatch({type: DOWNLOAD_LIST_VIEW_AS_CSV_SUCCESS, payload: response});
};

export const clearDownloadListViewAsCsv = () => async dispatch => {
  dispatch({type: CLEAR_DOWNLOAD_LIST_VIEW_AS_CSV});
};

export const changeViewMode = viewMode => dispatch => {
  dispatch({type: CHANGE_VIEWMODE, payload: viewMode});
};

export const checkMedia = (index, checked) => (dispatch, getState) => {
  const state = getState();
  let medias = state.library.mediaList;
  if (medias) {
    if (index === 'all') {
      medias = medias.map(it => ({...it, checked}));
    } else {
      const media = medias[index];
      if (media) {
        media.checked = checked;
        medias[index] = media;
      }
    }
  }
  dispatch({type: UPDATE_MEDIALIST, payload: medias});
};

export const deleteVideo = mediaId => async () => {
  try {
    await libraryService.deleteVideo(mediaId);
  } catch (ex) {
    console.error(ex);
  }
};

export const deleteSelectedMedia = () => async (dispatch, getState) => {
  try {
    const {
      library: {
        mediaList
      }
    } = getState();
    const mediaToDelete =
      mediaList
        .filter(media => media.checked)
        .map(({mediaId}) => mediaId);
    return await libraryService.deleteVideos(mediaToDelete);
  } catch (ex) {
    console.error(ex);
    return false;
  }
};
