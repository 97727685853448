
import React, {Fragment} from 'react';
import ReactGA from 'react-ga';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import size from 'lodash/size';
import {Translate, I18n} from 'react-redux-i18n';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import {formatDuration, translateOer} from '@peoplenet/vi-components-ui/src/app/formatter';

export default class MediaListViewItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.media.checked
    };

    const {
      media: {
        mediaId,
        clips,
        coach
      }
    } = props;
    this.setup();
    this.mediaId = mediaId;
    this.selectedCamera = size(clips) > 1 ? 'split-camera' : '';
    this.coachStatus = coach && coach.completed ? 'coachCompleted' : 'coachNotCompleted';
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.checked !== nextProps.checked) {
      this.setState({checked: nextProps.checked});
    }
    if (this.props.locale !== nextProps.locale) {
      this.setup();
    }
  }

  setup() {
    const {
      media: {
        date,
        startTime,
        duration
      }
    } = this.props;

    this.date = moment(new Date(date)).format(I18n.t('configuration.dateFormat'));
    this.startTime = moment(new Date(startTime)).format(I18n.t('configuration.timeFormat'));
    this.duration = formatDuration(duration);
  }

  handleClick = () => {
    this.props.onVideoClick(this.mediaId)(this.selectedCamera);

    ReactGA.event({
      category: 'Library',
      action: 'Open Player Page',
      label: 'List Item'
    });
  }

  handleDeleteClick = () => this.props.deleteVideo(this.props.index);

  checkMedia = () => {
    const {checked} = this.state;
    this.props.onCheckMedia(this.props.index, !checked);
    this.setState({checked: !checked});
  }

  renderDeleteColumns = () => {
    return (
      <Fragment>
        {(this.props.isAdmin || this.props.isInternal) &&
          <TableCell className="library__checkboxcell">
            <Checkbox
              disabled={this.props.isInternal}
              onClick={this.checkMedia}
              checked={this.state.checked}
            />
          </TableCell>
        }
        {!this.props.isBasicUser &&
          <TableCell className="library__deletecell">
            {(!this.props.isAdmin || this.props.isInternal) &&
              <Icon
                className="delete-icon"
                disabled={this.props.isInternal}
                onClick={this.handleDeleteClick}
              >
                delete
              </Icon>
            }
          </TableCell>
        }
      </Fragment>
    );
  };

  renderOerColumns = media => {
    if (media.triggerType === 'onDemand') {
      return I18n.t('filter.onDemand');
    }

    return translateOer(media.oer);
  }

  render() {
    const {media} = this.props;
    return (
      <TableRow
        key={media.mediaId}
        ref={el => this.props.prepareReference(media, el)}
      >
        <TableCell className="library__actionscell" onClick={this.handleClick}><Icon className="play-icon testclass">play_circle_filled</Icon></TableCell>
        <TableCell className="library__mediaidcell">{media.mediaId}</TableCell>
        <TableCell className="library__terminalcell"><div title={media.terminal}>{media.terminal}</div></TableCell>
        <TableCell className="library__groupscell"><div title={media.groups ? media.groups.join(', ') : ''}>{media.groups ? media.groups.join(', ') : ''}</div></TableCell>
        <TableCell className="library__driveridcell">{media.driverId}</TableCell>
        <TableCell className="library__drivernamecell">{media.driver}</TableCell>
        <TableCell className="library__statuscell"><Translate value={`page.library.coachStatus.${this.coachStatus}`} /></TableCell>
        <TableCell className="library__vehiclecell">{media.vehicle}</TableCell>
        <TableCell className="library__eventcell">{this.renderOerColumns(media)}</TableCell>
        <TableCell className="library__datetimecell">{this.date}</TableCell>
        <TableCell className="library__starttimecell">{this.startTime}</TableCell>
        <TableCell className="library__durationcell">{this.duration}</TableCell>
        {this.renderDeleteColumns()}
      </TableRow>
    );
  }
}
