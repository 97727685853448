import React from 'react';
import ReactDOM from 'react-dom';
import {I18n} from 'react-redux-i18n';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import VideoCam from '@material-ui/icons/Videocam';
import Grid from '@material-ui/core/Grid';
import VideoCard from '@peoplenet/vi-components-ui/src/Library/card/VideoCard';
import CircularProgress from '@material-ui/core/CircularProgress';


function getElementFromRef(ref) {
  if (!ref) {
    return null;
  }

  return ReactDOM.findDOMNode(ref);
}

const initialState = {
  hasFocused: false,
  isOpen: false,
  list: []
};

export default class LibraryView extends React.Component {
  constructor(props) {
    super(props);

    this.containerElement = null;
    this.cardSelected = React.createRef();

    this.state = {
      ...initialState
    };
  }

  componentDidMount() {
    this.props.onLoad();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.list.length !== state.list.length) {
      return {
        list: props.list
      };
    }
    return null;
  }

  getSnapshotBeforeUpdate(prevProps) {
    const {containerElement: container} = this;

    if (!container) {
      return null;
    }

    const {list: prevList} = prevProps;
    const {list: currentList} = this.props;

    if (prevList.length > 0 && prevList.length < currentList.length) {
      const foundItem = currentList.find(item => !prevList.includes(item));

      const foundItemIndex = currentList.indexOf(foundItem);

      return {
        fromleft: (foundItemIndex <= 0),
        scrollWidth: container.scrollWidth,
        scrollLeft: container.scrollLeft
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {containerElement: container} = this;

    if (container && snapshot && snapshot.fromleft) {
      container.scrollLeft = ((container.scrollWidth - snapshot.scrollWidth) + snapshot.scrollLeft);
    }

    if (this.props.isLoading) {
      return;
    }

    const {hasFocused, isOpen} = this.state;

    if (!hasFocused && isOpen) {
      setTimeout(() => {
        if (this.props.hasLessPages) {
          document.getElementById('libraryview_container').parentElement.scrollTo({left: 64});
        }
        this.scrollToSelectedCard();
      }, 450);
    }
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  handleContainerRef = ref => {
    this.containerElement = getElementFromRef(ref);
  }

  scrollToSelectedCard = () => {
    this.setState({hasFocused: true});
    const card = this.cardSelected.current;
    if (card) {
      document.getElementById(card.props.id).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }

  handleWheel = ev => {
    const element = ev.currentTarget.children[1];

    if (ev.deltaY > 0) {
      element.scrollLeft += 60;
    } else {
      element.scrollLeft -= 60;
    }
  }

  handleScroll = ({target}) => {
    if (!this.state.hasFocused) {
      return;
    }

    const visibleWidth = (target.scrollWidth - target.clientWidth) - target.scrollLeft;

    if (!this.props.isLoading) {
      if (visibleWidth <= 50) {
        this.props.onScrollRight();
      } else if (target.scrollLeft <= 50) {
        this.props.onScrollLeft();
      }
    }
  }

  handleToggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  renderList = list => {
    const {
      mediaId,
      onOpenVideo,
      onFlagVideo,
      hasMorePages,
      hasLessPages,
      locale
    } = this.props;

    return (
      <Grid container className="libraryview" id="libraryview_container">
        {hasLessPages && (
          <Grid item className="loading" style={{width: 100, paddingLeft: 14}}>
            <CircularProgress className="loading__cirgularprogress" size={50} />
          </Grid>
        )}
        {
          list.map((_media, index) => (
            <Grid
              ref={_media.mediaId === mediaId ? this.cardSelected : null}
              key={_media.mediaId}
              id={`card_${_media.mediaId}`}
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={_media.mediaId === mediaId ? 'library__card libraryview-selected' : 'library__card'}
            >
              <VideoCard
                key={_media.mediaId}
                index={index}
                media={_media}
                locale={locale}
                onVideoOpen={onOpenVideo(_media.mediaId)}
                onFlagVideo={onFlagVideo}
              />
            </Grid>
          ))
        }
        {hasMorePages && (
          <Grid item className="loading" style={{width: 100, paddingRight: 14}}>
            <CircularProgress className="loading__cirgularprogress" size={50} />
          </Grid>
        )}
      </Grid>
    );
  }

  render() {
    const {isOpen, list} = this.state;
    return (
      <div>
        <Fab color="primary" variant="extended" className="libraryview__fab" onClick={this.handleToggle}>
          <VideoCam className="libraryview__fab-icon" />
          {I18n.t('page.playerPage.libraryView.title')}
        </Fab>
        <div>
          <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={this.handleToggle}
            onScroll={e => this.handleScroll(e)}
            onWheel={e => this.handleWheel(e)}
            PaperProps={{ref: this.handleContainerRef}}
            ModalProps={{keepMounted: true}}
            className="libraryview__drawer"
          >
            {this.renderList(list)}
          </Drawer>
        </div>
      </div>
    );
  }
}
