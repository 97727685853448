import {get} from 'lodash';
import BaseService from '../../middleware/BaseService';
import store from '../../stores';
import FileDownloader from '../../app/FileDownloader';

const GET_URL_ENDPOINT = '/dashboard/download';

class DownloadVideoDashboardService extends BaseService {
  prefix = GET_URL_ENDPOINT;

  async requestVideoDasboardDownload(mediaId, clipId) {
    const userInformation = get(store.getState(), 'user.userInformation', {});
    const system = get(store.getState(), 'system.settings', {});
    const systemMeasure = get(store.getState(), 'userSettings.settings.systemMeasure', {});
    const {userId, customerId} = userInformation;
    const {systemId} = system;
    const response = await this.post({
      mediaId, userId, customerId, clipId, systemId, systemMeasure
    });

    return response;
  }

  async downloadVideoDashboard(mediaId, clipId, systemMeasure) {
    const videoBinary = await this.search({systemMeasure}, `/medias/${mediaId}/clips/${clipId}/dashboard/download`, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });

    FileDownloader.forData(videoBinary).downloadAs(`${mediaId}_${clipId}_${systemMeasure}_dashboard.mp4`);
  }
}

export default new DownloadVideoDashboardService();
