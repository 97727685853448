import React from 'react';
import AbstractLibrary from './AbstractLibrary';
import Filters from '@peoplenet/vi-components-ui/src/LibraryFilter/component/LibraryFilters';

export default class DesktopLibrary extends AbstractLibrary {
  render() {
    return (
      <div>
        <Filters />
        {super.render()}
      </div>
    );
  }
}
