import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import get from 'lodash/get';
import DefaultConfirmationDialog from '@peoplenet/vi-components-ui/src/Dialog/DefaultConfirmationDialog';
import {deleteNotifications} from '../NotificationActions';

class ClearNotificationsDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    notifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })),
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onClearDone: PropTypes.func
  }

  static defaultProps = {
    open: false,
    notifications: [],
    onClose: () => {},
    onConfirm: () => {},
    onClearDone: () => {}
  }

  handleCloseDialog = () => {
    this.props.onClose();
  }

  handleClearConfirmed = async () => {
    await this.props.onConfirm();

    const notificationIds = this.props.notifications.map(item => ({id: item.id}));

    if (notificationIds.length <= 0) {
      return this.props.onClearDone();
    }

    await this.props.deleteNotifications(notificationIds);
    return this.props.onClearDone();
  }

  render() {
    const {open, deleting} = this.props;

    return (
      <DefaultConfirmationDialog
        open={open}
        disableButtons={deleting}
        title={I18n.t('page.notifications.confirmationDialog.title')}
        icon="warning"
        iconClass="clear-notification"
        message={I18n.t('page.notifications.confirmationDialog.message')}
        cancelLabel="defaults.cancel"
        confirmLabel="defaults.clear"
        onClose={this.handleCloseDialog}
        onConfirm={this.handleClearConfirmed}
        DialogProps={{
          disableEnforceFocus: true
        }}
      />
    );
  }
}

const withRedux = connect(state => {
  const notification = get(state, 'notification', {});

  return {
    deleting: notification.deleting,
    notifications: notification.list
  };
}, {
  deleteNotifications
});

export default withRedux(ClearNotificationsDialog);
