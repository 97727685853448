import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Drawer from '@material-ui/core/Drawer';
import ProgressSpinner from '@peoplenet/vi-components-ui/src/Loading/ProgressSpinner';
import {isNearEnd} from '@peoplenet/vi-components-ui/src/util/scroll';
import NotificationList from './NotificationList';
import {getUnreadNotifications} from '../util/NotificationUtil';
import {
  readNotifications,
  commitReadNotifications,
  loadPreviousNotifications,
  closeNotificationsPanel
} from '../NotificationActions';

class NotificationDrawer extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    commitReadNotifications: PropTypes.func,
    closeNotificationsPanel: PropTypes.func
  }

  static defaultProps = {
    open: false,
    commitReadNotifications: () => {},
    closeNotificationsPanel: () => {},
    onClose: () => {}
  }

  componentDidUpdate() {
    const {open, reading, notifications} = this.props;

    if (!open || reading) {
      return;
    }

    const unreadNotifications = getUnreadNotifications(notifications);

    if (isEmpty(unreadNotifications)) {
      return;
    }

    this.props.readNotifications(unreadNotifications);
  }

  handleClose = () => {
    this.props.commitReadNotifications();
    this.props.closeNotificationsPanel();
    this.props.onClose();
  }

  handleLazyLoading = scrollEvent => {
    const {loadingPrevious, hasMore, notifications} = this.props;

    console.log(loadingPrevious, hasMore);

    if (hasMore === false || loadingPrevious) {
      return;
    }

    if (!isNearEnd(scrollEvent.currentTarget)) {
      return;
    }

    this.props.loadPreviousNotifications(notifications);
  }

  render() {
    const {open, loadingPrevious, notifications} = this.props;

    return (
      <Drawer
        open={open}
        anchor="right"
        onClose={this.handleClose}
        classes={{
          paper: 'notification__drawer-paper'
        }}
      >
        <NotificationList
          notifications={notifications}
          onScroll={this.handleLazyLoading}
        >
          <ProgressSpinner show={loadingPrevious} />
        </NotificationList>
      </Drawer>
    );
  }
}

const withRedux = connect(state => {
  const {
    reading, open, loadingPrevious, hasMore, list
  } = get(state, 'notification', {});

  return {
    open,
    reading,
    loadingPrevious,
    hasMore,
    notifications: (list || [])
  };
}, {
  readNotifications,
  commitReadNotifications,
  loadPreviousNotifications,
  closeNotificationsPanel
});

export default withRedux(NotificationDrawer);
