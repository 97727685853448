import React from 'react';
import AbstractLibrary from './AbstractLibrary';
import {viewMode as VIEW_MODE} from '../constants';
import MobileChipsFilter from '@peoplenet/vi-components-ui/src/LibraryFilter/component/MobileChipsFilter';

export default class MobileLibrary extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="mobilefilters">
          <MobileChipsFilter />
        </div>
        <AbstractLibrary {...this.props} viewMode={VIEW_MODE.CARD} />
      </div>
    );
  }
}
