import {get, map} from 'lodash';
import BaseService from '../../middleware/BaseService';

const SEARCH_VEHICLE_ENDPOINT = '/search/autocomplete/vehicle';
const SEARCH_DVR_ENDPOINT = '/search/autocomplete/dvr';
const FETCH_CHANNEL_ENDPOINT = '/medias/fetch/channels';
const FETCH_REQUESTED_ENDPOINT = '/medias/fetch/requested';
const FETCH_ASYNC_ENDPOINT = '/medias/fetch/async';
const FETCH_ONDEMANDLIST_ENDPOINT = '/medias/fetch/ondemand';
const DVR_SCAN_VIDEO_ENDPOINT = '/medias/fetch/scan';
const ONDEMAND_MEDIA_ENDPOINT = '/medias/fetch/update';
const FETCH_MATCH_DVR_ENDPOINT = '/medias/fetch/matchdvr';

class OnDemandService extends BaseService {
  prefix = SEARCH_VEHICLE_ENDPOINT;

  async searchVehicles(params) {
    if (!params || !params.text) {
      return [];
    }

    const response = await this.search(params, SEARCH_VEHICLE_ENDPOINT);
    return map(get(response, 'data', []), e => ({
      id: e.id,
      name: e.number,
      dsn: e.dsn,
      type: e.type
    }));
  }

  async searchDVRs(params) {
    if (!params || !params.text) {
      return [];
    }

    const response = await this.search(params, SEARCH_DVR_ENDPOINT);
    return map(get(response, 'data', []), (e, index) => ({
      id: index,
      name: `${e.number} (${e.name})`,
      serial: e.name,
      vehicle: e.number,
      type: e.type
    }));
  }

  async loadChannels(_params) {
    const {vehicle, dvr} = _params;
    if (!vehicle && !dvr) {
      return {};
    }

    const params = {};
    if (vehicle) {
      params.vehicle = vehicle.name;
    }
    if (dvr) {
      params.serial = dvr.serial;
    }

    const response = await this.search(params, FETCH_CHANNEL_ENDPOINT);
    return response;
  }

  async loadTotalRequested() {
    const response = await this.search({}, FETCH_REQUESTED_ENDPOINT);
    return get(response, 'data', {});
  }

  async postFetchAsync(params) {
    const response = await this.post(params, FETCH_ASYNC_ENDPOINT);
    return get(response, 'data', {});
  }

  async scanVideoAvailable(params) {
    return this.search(params, DVR_SCAN_VIDEO_ENDPOINT);
  }

  async checkMatchDVR(params) {
    if (params && params.vehicle && params.serialNo) {
      const response = await this.search(params, FETCH_MATCH_DVR_ENDPOINT);
      return response;
    }
    return {};
  }

  async loadQueueList(_params) {
    const params = _params;
    if (params && params.page >= 0) {
      params.tab = 'current';
      params.pageSize = 16;
      params.sort = 'requestDate';
      params.sortOrder = 'desc';
      const response = await this.search(params, FETCH_ONDEMANDLIST_ENDPOINT);
      return response;
    }
    return {};
  }

  async loadPreviouslyQueueList(_params) {
    const params = _params;
    if (params && params.page >= 0) {
      params.tab = 'previously';
      params.pageSize = 16;
      const response = await this.search(params, FETCH_ONDEMANDLIST_ENDPOINT);
      return response;
    }
    return {};
  }

  async retryOndemandMedia(media) {
    const body = {id: media.id, status: 'retry'};
    const response = await this.post(body, ONDEMAND_MEDIA_ENDPOINT);

    return get(response, 'data', {});
  }

  async cancelOndemandMedia(media) {
    const body = {id: media.id, status: 'cancel'};
    const response = await this.post(body, ONDEMAND_MEDIA_ENDPOINT);

    return get(response, 'data', {});
  }
}

export default new OnDemandService();
