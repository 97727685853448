import BaseService from '../middleware/BaseService';

const DOWNLOAD_LIST_VIEW_AS_CSV_ENDPOINT = '/medias/report/csv';

class LibraryService extends BaseService {
  prefix = '/medias';

  async deleteVideo(mediaId) {
    return this.remove(mediaId);
  }

  async deleteVideos(medias) {
    return this.api.remove(`${this.prefix}/many`, {ids: medias});
  }

  async downloadListViewAsCsv(filters) {
    const response = await this.search(filters, DOWNLOAD_LIST_VIEW_AS_CSV_ENDPOINT, {headers: {'Content-Type': 'application/octet-stream'}});

    return response;
  }
}

export default new LibraryService();
