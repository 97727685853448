import React from 'react';
import {toLower, get} from 'lodash';
import {I18n} from 'react-redux-i18n';
import classNames from 'classnames';
import {Icon} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';

import OnDemandDetails from './OnDemandDetails';
import CancelMediaRequestDialog from './CancelMediaRequestDialog';
import CameraViews from './CameraViews';
import moment from '@peoplenet/vi-components-ui/src/app/moment';

export default class MobileRequestQueueItem extends React.Component {
  state = {
    detailsOpened: false,
    cancelConfirmationOpened: false,
    isRetryingMedia: false,
    isCancelingMedia: false
  }

  onDetailsClick = () => {
    this.setState({detailsOpened: !this.state.detailsOpened});
  };

  handleRetryMedia = () => {
    this.setState({isRetryingMedia: true});

    setTimeout(() => {
      this.props.retryOndemandMedia(this.props.requestItem).then(() => {
        this.setState({isRetryingMedia: false});
      });
    }, 1000);
  }

  handleCancelMedia = () => {
    this.setState({cancelConfirmationOpened: !this.state.cancelConfirmationOpened});
  }

  handleCloseDialog = () => {
    this.setState({cancelConfirmationOpened: false});
  }

  handleCancelMediaDialog = () => {
    this.setState({isCancelingMedia: true});

    this.props.cancelOndemandMedia(this.props.requestItem).then(() => {
      this.setState({isCancelingMedia: false});
      this.handleCloseDialog();
    });
  }

  isCurrentQueue() {
    return this.props.type === 'current';
  }

  isPreviouslyQueue() {
    return this.props.type === 'previously';
  }

  isInAnyState = statusList => {
    const {requestItem} = this.props;

    const statusLower = toLower(requestItem.status);
    return statusList.includes(statusLower);
  };

  canBeCanceled = () => {
    if (!this.isCurrentQueue()) {
      return false;
    }

    return this.isInAnyState(['reopened', 'created', 'waiting']);
  };

  canBeRetried = () => {
    if (!this.isPreviouslyQueue()) {
      return false;
    }

    return this.isInAnyState(['expired', 'cancelled']);
  };

  openPlayer = () => {
    return this.props.openPlayer(this.props.requestItem.id);
  }

  renderIconButton(icon, onClickCallback, disabled = false, classeName = '') {
    return (
      <IconButton
        disabled={disabled}
        className={classeName}
        onClick={onClickCallback}
      >
        <Icon>{icon}</Icon>
      </IconButton>
    );
  }

  renderActionButton() {
    const {canChangeStatus} = this.props;

    if (this.canBeRetried()) {
      const disabled = !canChangeStatus || this.state.isRetryingMedia;

      const className = (this.state.isRetryingMedia ? 'spin' : null);
      return this.renderIconButton(
        'autorenew',
        this.handleRetryMedia,
        disabled,
        className
      );
    }

    if (this.isInAnyState(['completed'])) {
      return <Icon onClick={this.openPlayer} className="play-icon testclass">play_circle_filled</Icon>;
    }

    if (this.canBeCanceled()) {
      return this.renderIconButton('delete', this.handleCancelMedia, !canChangeStatus);
    }

    return null;
  }

  render() {
    const {requestItem} = this.props;
    const statusLower = toLower(requestItem.status);
    const tblClasses = classNames({
      table__success: (statusLower === 'completed'),
      table__error: (statusLower === 'expired' || statusLower === 'notavailable')
    });
    return (
      <React.Fragment>
        <OnDemandDetails
          open={this.state.detailsOpened}
          requestItem={this.props.requestItem}
          onClose={this.onDetailsClick}
        />

        {this.canBeCanceled() && <CancelMediaRequestDialog
          open={this.state.cancelConfirmationOpened}
          onClose={this.handleCloseDialog}
          onCancelMedia={this.handleCancelMediaDialog}
          loading={this.state.isCancelingMedia}
        />}

        <TableRow className={tblClasses}>
          <TableCell className="ondemand__vehiclecell-mobile">
            <div className="ondemand__vehiclecell-cont">
              <IconButton onClick={this.onDetailsClick}>
                <Icon>info</Icon>
              </IconButton>
              <span>{requestItem.vehicleNumber}</span>
            </div>
          </TableCell>
          <TableCell className="ondemand__datetimecell-mobile">
            {moment(requestItem.requestDate).format(I18n.t('configuration.fullDateTimeFormat'))}
          </TableCell>
          <TableCell className="ondemand__smallcamicons ondemand__viewscell-mobile">
            <div className="ondemand__camerasmobile-cont">
              <div className="ondemand__camerasmobile">
                <CameraViews channels={requestItem.channels} machineType={get(requestItem, 'metadata.machineType', 0)} />
              </div>
              <span className="ondemand__vehiclecell-actionbt">{this.renderActionButton()}</span>
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}
