import {CALL_API} from '../../middleware/api';
import {GET_REQUEST, GET_FAIL} from '../LockVideoActionTypes';
import * as actionTypes from '@peoplenet/vi-components-ui/src/actions/constants';

class LockVideoService {
  lockVideo(mediaId, userId) {
    return this.changeVideoLockStatus({locked: true, mediaId, userId});
  }

  unLockVideo(mediaId, userId) {
    return this.changeVideoLockStatus({locked: false, mediaId, userId});
  }

  changeVideoLockStatus({locked, mediaId, userId}) {
    const metadata = {
      locked
    };
    if (locked) {
      metadata.lock = {
        userId
      };
    } else {
      metadata.unlock = {
        userId
      };
    }
    const successRequestType = locked ? actionTypes.LOCK_MEDIA : actionTypes.UNLOCK_MEDIA;
    return {
      [CALL_API]: {
        types: [GET_REQUEST, successRequestType, GET_FAIL],
        method: 'post',
        body: {
          metadata
        },
        endpoint: `/medias/${mediaId}/metadata`
      }
    };
  }
}

export default new LockVideoService();
