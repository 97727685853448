import {get} from 'lodash';
import * as actionTypes from '@peoplenet/vi-components-ui/src/actions/constants';

const initialState = {
  loading: false,
  error: false,
  mediaId: null,
  tagged: null
};

export default function videoTagging(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TAG_POST_REQUEST:
      return {...state, loading: true, error: false};
    case actionTypes.TAG_POST_SUCCESS:
      return {
        ...state,
        mediaId: get(action, 'payload.mediaId', null),
        tagged: get(action, 'payload.params.value', null),
        loading: false,
        error: false
      };
    case actionTypes.TAG_POST_FAIL:
      return {...state, loading: false, error: true};
    default:
      return state;
  }
}
