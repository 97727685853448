import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {get, result, has} from 'lodash';
import moment from 'moment';
import {dvrUnknownSpeed} from '@peoplenet/vi-components-ui/src/util/DvrConstants';
import AlkMapScriptLoader from './AlkMapScriptLoader';
import Marker from '../layer/Marker';
import {SYSTEM_MEASURE} from '@peoplenet/vi-components-ui/src/util/SystemMeasure';

const styles = {
  map: {
    width: '100%',
    height: '100%'
  }
};

class AlkMap extends React.Component {
    static propTypes = {
      apiKey: PropTypes.string.isRequired,
      onMapLoaded: PropTypes.func,
      zoom: PropTypes.number,
      center: PropTypes.shape({lon: PropTypes.number, lat: PropTypes.number}),
      breadcrumbData: PropTypes.object
    }

    static defaultProps = {
      zoom: 4,
      center: {
        lon: -98.5,
        lat: 40
      },
      onMapLoaded: null
    }

    state = {
      loading: true
    }

    componentDidMount() {
      const {
        apiKey,
        center,
        isMobile,
        i18n
      } = this.props;

      const {ALKMaps} = window;

      ALKMaps.Lang.setCode(i18n.locale);
      ALKMaps.APIKey = apiKey;

      // Instantiate the actual alk map
      const controls = [new ALKMaps.Control.Navigation()];
      this.map = new ALKMaps.Map('alkMapRoot', {
        displayProjection: new ALKMaps.Projection('EPSG:4326'),
        controls
      });

      const mainLayer = new ALKMaps.Layer.BaseMap('ALK Maps', {style: ALKMaps.STYLE.TERRAIN}, {displayInLayerSwitcher: false});
      this.map.addLayer(mainLayer);

      this.markersLayer = new ALKMaps.Layer.VectorMarkers('Vector Layer');

      setTimeout(() => {
        if (this.props.breadcrumbData) {
          this.setBreadcrumbMarkers(this.markersLayer);

          if (!isMobile) {
            const buttons = new ALKMaps.Control.Panel({displayClass: 'mapcontrols', minimalist: true});
            buttons.addControls([
              new ALKMaps.Control.Button({
                displayClass: 'terrainButton-',
                eventListeners: {
                  activate: () => {
                    mainLayer.changeStyle(ALKMaps.STYLE.TERRAIN);
                  }
                },
                type: ALKMaps.Control.TYPE_TOOL,
                title: I18n.t('page.playerPage.map.terrain')
              }),
              new ALKMaps.Control.Button({
                displayClass: 'satelliteButton-',
                eventListeners: {
                  activate: () => {
                    mainLayer.changeStyle(ALKMaps.STYLE.SATELLITE);

                    this.removeDefaultButtonClass();
                  }
                },
                type: ALKMaps.Control.TYPE_TOOL,
                title: I18n.t('page.playerPage.map.satellite')
              }),
              new ALKMaps.Control.Button({
                displayClass: 'smoothButton-',
                eventListeners: {
                  activate: () => {
                    mainLayer.changeStyle(ALKMaps.STYLE.SMOOTH);

                    this.removeDefaultButtonClass();
                  }
                },
                type: ALKMaps.Control.TYPE_TOOL,
                title: I18n.t('page.playerPage.map.smooth')
              })
            ]);

            // ADD Map Style buttons
            this.map.addControl(buttons);

            // ADD Geolocate buttons
            this.map.addControl(new ALKMaps.Control.Geolocate());

            // ADD Pan + Zoom buttons
            const panZoomBar = new ALKMaps.Control.PanZoomBar();
            this.map.addControl(panZoomBar);

            const terrainButton = document.getElementsByClassName('terrainButton-ItemInactive')[0];
            terrainButton.classList.add('button-default');
          }

          this.map.addLayer(this.markersLayer);

          if (!isMobile) {
            const eventSelect = new ALKMaps.Control.FeatureEvent([this.markersLayer], {
              featureAttributesFilter: [{property: 'isMarker', value: true}]
            });
            this.map.addControls([eventSelect]);
            eventSelect.activate();

            this.markersLayer.events.register('featureclicked', this, event => {
              event.feature.createPopup(true);
              this.map.addPopup(event.feature.popup, true);
              event.feature.popup.show();
            });
          }
        }
        this.setState({loading: false});
      }, 0);
      const lonLat = new ALKMaps.LonLat(center.lon, center.lat).transform(new ALKMaps.Projection('EPSG:4326'), this.map.getProjectionObject());
      this.map.setCenter(lonLat, this.props.zoom);

      // Expose ALK objects to container
      return this.props.onMapLoaded && this.props.onMapLoaded(ALKMaps, this.map);
    }

    componentWillReceiveProps = nextProps => {
      if (nextProps.i18n.locale !== this.props.i18n.locale) {
        window.ALKMaps.Lang.setCode(nextProps.i18n.locale);
      }

      if (nextProps.systemMeasure !== this.props.systemMeasure) {
        this.setBreadcrumbMarkers(this.markersLayer);
      }

      const currentSecond = get(nextProps, 'playerSecond', 0);
      this.markers.forEach(marker => {
        if (marker) {
          marker.update(currentSecond);
        }
      });
      this.markersLayer.redraw();
    }

    componentWillUnmount() {
      // destroy the map
      try {
        result(this.map, 'destroy');
      } catch (e) {
        console.error('error occured when destroying map', e);
      }
    }

    setBreadcrumbMarkers(layer) {
      const {ALKMaps} = window;
      if (!this.props.breadcrumbData) {
        return;
      }

      const DVR_UNKNOWN_SPEED = dvrUnknownSpeed(this.props.systemMeasure);
      const shouldUseMetric = this.props.systemMeasure === SYSTEM_MEASURE.METRIC;

      Object.keys(this.props.breadcrumbData).forEach(key => {
        const item = this.props.breadcrumbData[key];
        const {time} = item;

        item.rtcTimeFormated = (item.rtcTime != null ? moment(item.rtcTime).format(I18n.t('configuration.midDateTimeFormat')) : I18n.t('page.playerPage.map.notAvailable').toUpperCase());
        item.j1708Speed = (item.j1708Speed != null ? item.j1708Speed : item.gpsSpeed);

        const speedFormated = item.j1708Speed === DVR_UNKNOWN_SPEED ?
          I18n.t('page.playerPage.map.notAvailableShort')
          : `${item.j1708Speed && item.j1708Speed.toFixed(1)} ${shouldUseMetric ? 'km/h' : 'mph'}`;

        let content = `<span style="white-space: nowrap; font-size: 12px;"><b>${I18n.t('page.playerPage.map.dateAndTime')}:</b>&nbsp;${item.rtcTimeFormated}`;
        content += `<br><b>${I18n.t('page.playerPage.map.speed')}:</b>&nbsp;${speedFormated}`;
        content += '</span>';

        const marker = new Marker(this.map, layer, {
          ...item.location,
          angle: 180 + item.gpsHeading,
          time: time.second,
          hoverText: `${speedFormated} - ${item.rtcTimeFormated}`,
          popup: {
            popupSize: new ALKMaps.Size(200, 80),
            popupContentHTML: content,
            anchor: {size: new ALKMaps.Size(11, 10), offset: new ALKMaps.Pixel(-13, -6)},
            overflow: 'auto'
          }
        });
        marker.create();
        this.markers.push(marker);
      });
      this.setOERMarker(layer);
    }

    setOERMarker(layer) {
      const {ALKMaps} = window;
      const {oerMarker, oerItem} = this.props;
      if (!oerItem) {
        return;
      }

      const DVR_UNKNOWN_SPEED = dvrUnknownSpeed(this.props.systemMeasure);
      const hasSpeed = (has(oerItem, 'j1708Speed') && oerItem.j1708Speed !== DVR_UNKNOWN_SPEED);

      const rtcTimeFormated = moment(oerItem.rtcTime).format(I18n.t('configuration.midDateTimeFormat'));
      const speed = hasSpeed && `${oerItem.j1708Speed && oerItem.j1708Speed.toFixed(1)} ${
        this.props.systemMeasure === SYSTEM_MEASURE.METRIC ? 'km/h' : 'mph'
      }`;

      let content = `<span style="white-space: nowrap; font-size: 12px;"><b>${I18n.t('page.playerPage.map.oerEvent').toUpperCase()}</b><br><b>${I18n.t('page.playerPage.map.dateAndTime')}:</b>&nbsp;${rtcTimeFormated}`;
      content += hasSpeed && `<br><b>${I18n.t('page.playerPage.map.speed')}:</b>&nbsp;${speed}`;
      content += '</span>';

      let hoverText = `${I18n.t('page.playerPage.map.oerEvent')}: `;
      hoverText += hasSpeed ? `${speed} - ${rtcTimeFormated}` : rtcTimeFormated;

      const marker = new Marker(this.map, layer, {
        ...oerMarker,
        eventMarker: true,
        hoverText,
        popup: {
          popupSize: new ALKMaps.Size(200, 80),
          popupContentHTML: content,
          anchor: {size: new ALKMaps.Size(15, 12), offset: new ALKMaps.Pixel(-13, -18)},
          overflow: 'auto'
        }
      });
      marker.create();
      layer.redraw();
    }

    markers = [];
    markersLayer = null;

    removeDefaultButtonClass = () => {
      const terrainButton = document.getElementsByClassName('terrainButton-ItemInactive')[0];
      terrainButton.classList.remove('button-default');
    }

    render() {
      return (
        <div id="alkMapRoot" style={styles.map}>
          {this.state.loading && <CircularProgress />}
        </div>
      );
    }
}

const mapStateToProps = ({i18n, userSettings}) => ({
  i18n,
  systemMeasure: userSettings.settings.systemMeasure
});

export default connect(mapStateToProps)(AlkMapScriptLoader(AlkMap));
