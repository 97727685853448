/* eslint-disable no-case-declarations */
import {get, set, cloneDeep, filter, map} from 'lodash';
import {LIBRARY_VIEW_KEY} from '@peoplenet/vi-components-ui/src/app/configuration/localstorageKeys';
import * as actionTypes from '@peoplenet/vi-components-ui/src/actions/constants';

const initialState = {
  media: null,
  review: {
    showReviewSubmittedChip: false,
    completed: false,
    mainCategory: {},
    otherCategories: [],
    updated: new Date()
  },
  previouslyReviews: [],
  previouslyReviewsTotal: 0,
  isLoadingTop: true,
  coach: {
    comment: '',
    completed: false
  },
  comments: {
    loading: false,
    entries: []
  },
  libraryView: {
    mediaId: null,
    index: 0,
    pageSize: 10
  }
};

const saveLibraryView = ({mediaId, index}) => {
  localStorage.setItem(LIBRARY_VIEW_KEY, JSON.stringify({libraryView: {mediaId, index}}));
};

const initializeState = () => {
  const libraryView = localStorage.getItem(LIBRARY_VIEW_KEY);
  if (libraryView) {
    try {
      const parsed = JSON.parse(libraryView);
      return {
        ...initialState,
        ...parsed
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  return initialState;
};

const concatComments = (currentComments, newComment) => {
  let isNew = true;
  const newCommentList = map(currentComments, it => {
    if (it.id === newComment.id) {
      isNew = false;
      return {...it, ...newComment};
    }
    return it;
  });
  if (isNew) {
    newCommentList.unshift(newComment);
  }
  return newCommentList;
};

const removeComment = (comments, commentToRemove) => {
  return filter(comments, it => it.id !== commentToRemove.id);
};


export default function player(state = initializeState(), action) {
  let newItem = null;

  switch (action.type) {
    case actionTypes.LOCK_MEDIA:
      set(state, 'media.metadata.locked', true);
      return {...state};
    case actionTypes.UNLOCK_MEDIA:
      set(state, 'media.metadata.locked', false);
      return {...state};
    case actionTypes.COMMENTS_GET_REQUEST:
      return {...state, comments: {...state.comments, loading: true}};
    case actionTypes.COMMENTS_GET_FAIL:
      return {...state, comments: {...state.comments, loading: false}};
    case actionTypes.COMMENTS_GET_SUCCESS:
      return {...state, comments: {...state.comments, loading: false, entries: action.payload}};
    case actionTypes.COMMENTS_POST_SUCCESS:
      return {...state, comments: {...state.comments, entries: concatComments(state.comments.entries, action.payload)}};
    case actionTypes.COMMENTS_DELETE_SUCCESS:
      return {...state, comments: {...state.comments, entries: removeComment(state.comments.entries, action.payload)}};
    case actionTypes.MEDIA_GET_REQUEST:
      return {...state, isLoadingTop: true, errorMessage: false};
    case actionTypes.MEDIA_GET_SUCCESS:
      newItem = get(action, 'response.data[0]', null);
      return {
        ...state,
        isLoadingTop: false,
        media: newItem,
        errorMessage: (newItem == null)
      };
    case actionTypes.MEDIA_GET_FAIL:
      return {...state, isLoadingTop: false, errorMessage: true};
    case actionTypes.REVIEW_GET_REQUEST:
      return {...state, errorReview: false};
    case actionTypes.REVIEW_SEARCH_REQUEST:
      const {page} = action.payload;

      return {
        ...state,
        searchingReviews: page === 0,
        searchingMoreReviews: page !== 0,
        previouslyReviews: page === 0 ? [] : state.previouslyReviews
      };
    case actionTypes.REVIEW_SEARCH_FAIL:
      return {
        ...state,
        searchingReviews: false,
        searchingMoreReviews: false,
        previouslyReviews: initialState.previouslyReviews
      };
    case actionTypes.REVIEW_SEARCH_SUCCESS:
      const {
        payload: {
          total, review, previouslyReviews: oldPreviouslyReviews
        }
      } = action;
      const previouslyReviews = [...oldPreviouslyReviews, ...review];

      return {
        ...state,
        searchingReviews: false,
        searchingMoreReviews: false,
        previouslyReviews,
        previouslyReviewsTotal: total
      };
    case actionTypes.REVIEW_GET_SUCCESS:
      return {
        ...state,
        review: {
          ...state.review,
          ...get(action, 'payload.review[0]._source'),
          mainCategory: cloneDeep(action.payload.mainCategory),
          otherCategories: cloneDeep(action.payload.otherCategories),
          completed: get(action, 'payload.review[0]._source.completed', false),
          updated: new Date()
        }
      };
    case actionTypes.REVIEW_GET_FAIL:
      return {...state, errorReview: true};
    case actionTypes.REVIEW_HIDE_SUCCESS:
      return {...state, review: {...state.review, showReviewSubmittedChip: false}};
    case actionTypes.REVIEW_POST_SUCCESS:
      if (get(action.payload, 'review.completed', false)) {
        set(state, 'media.tag.review.value', false);
        set(state, 'coach.completed', false);
      }

      if (!get(state, 'review.id', false) && !get(state, 'media.metadata.review', false)) {
        set(state, 'media.metadata.review', {});
      }

      return {
        ...state,
        review: {
          ...state.review,
          ...action.payload.review,
          showReviewSubmittedChip: true,
          updated: new Date()
        }
      };
    case actionTypes.COACH_GET_REQUEST:
      return {...state, errorCoach: false};
    case actionTypes.COACH_GET_SUCCESS:
      return {
        ...state,
        coach: get(action, 'response.data.coach[0]._source', null)
      };
    case actionTypes.COACH_GET_FAIL:
      return {...state, errorCoach: true};
    case actionTypes.COACH_SET_REQUEST:
      return {...state, errorCoach: false};
    case actionTypes.COACH_SET_SUCCESS:
      if (get(action, 'body.completed', false)) {
        set(state, 'media.tag.review.value', false);
      }
      set(state, 'coach', {...get(action, 'body', {}), ...get(action, 'payload', {})});
      return {
        ...set({...state}, 'coach.id', get(action, 'payload.coachId', null)),
        review: {
          ...state.review,
          completed: state.coach.completed ? false : state.review.completed
        }
      };
    case actionTypes.COACH_SET_FAIL:
      return {...state, errorCoach: true};
    case actionTypes.CLEAR_PLAYER:
      return {...state, ...initialState};
    case actionTypes.MEDIA_FLAG_UPDATE:
      return {
        ...set({...state}, 'media.flagged', get(action, 'payload.flagged', false))
      };
    case actionTypes.TAG_POST_SUCCESS:
      if (!get(state, 'media.tag.review.value', false)) {
        set(state, 'coach.completed', false);
      }
      return {
        ...set({...state}, 'media.tag.review.value', !get(state, 'media.tag.review.value', false)),
        review: {
          ...state.review,
          updated: new Date(),
          completed: get(state, 'media.tag.review.value', false) ? false : state.review.completed
        }
      };
    case actionTypes.LIBRARY_VIEW_SAVE:
      saveLibraryView(get(action, 'payload', null));
      return {
        ...set({...state}, 'libraryView.mediaId', get(action, 'payload.mediaId', null)),
        ...set({...state}, 'libraryView.index', get(action, 'payload.index', 0))
      };
    default:
      return state;
  }
}
