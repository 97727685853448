import {push} from 'connected-react-router';
import {get} from 'lodash';
import {
  SEARCH_VEHICLE_REQUEST,
  SEARCH_VEHICLE_SUCCESS,
  SEARCH_VEHICLE_FAIL,
  SEARCH_DVR_REQUEST,
  SEARCH_DVR_SUCCESS,
  SEARCH_DVR_FAIL,
  LOAD_CHANNELS_REQUEST,
  LOAD_CHANNELS_SUCCESS,
  LOAD_CHANNELS_FAIL,
  GET_MATCH_DVR_REQUEST,
  GET_MATCH_DVR_SUCCESS,
  GET_MATCH_DVR_FAIL,
  CLEAR_CHANNELS,
  CLEAR_TABLES,
  CLEAR_TABLES_LIST,
  GET_TOTAL_REQUEST,
  GET_TOTAL_SUCCESS,
  GET_TOTAL_FAIL,
  RESET,
  FETCH_ASYNC_REQUEST,
  FETCH_ASYNC_SUCCESS,
  FETCH_ASYNC_FAIL,
  DVR_SCAN_VIDEO_REQUEST,
  DVR_SCAN_VIDEO_SUCCESS,
  DVR_SCAN_VIDEO_FAIL,
  GET_QUEUE_LIST_REQUEST,
  GET_QUEUE_LIST_SUCCESS,
  GET_QUEUE_LIST_FAIL,
  GET_PREVIOUSLY_QUEUE_LIST_REQUEST,
  GET_PREVIOUSLY_QUEUE_LIST_SUCCESS,
  GET_PREVIOUSLY_QUEUE_LIST_FAIL,
  RETRY_ONDEMAND_MEDIA_REQUEST,
  RETRY_ONDEMAND_MEDIA_SUCCESS,
  RETRY_ONDEMAND_MEDIA_FAIL,
  CANCEL_ONDEMAND_MEDIA_REQUEST,
  CANCEL_ONDEMAND_MEDIA_SUCCESS,
  CANCEL_ONDEMAND_MEDIA_FAIL,
  CHANGE_WIFI_CHECKBOX
} from './OnDemandActionsType';
import OnDemandService from './service/OnDemandService';

export const searchVehicles = typedText => async dispatch => {
  dispatch({type: SEARCH_VEHICLE_REQUEST});
  try {
    const vehicles = await OnDemandService.searchVehicles(typedText);
    dispatch({type: SEARCH_VEHICLE_SUCCESS, payload: vehicles});
  } catch (e) {
    console.error('Unable to get vehicles list', e);
    dispatch({type: SEARCH_VEHICLE_FAIL});
  }
};

export const searchDVRs = typedText => async dispatch => {
  dispatch({type: SEARCH_DVR_REQUEST});
  try {
    const dvrs = await OnDemandService.searchDVRs(typedText);
    dispatch({type: SEARCH_DVR_SUCCESS, payload: dvrs});
    return dvrs;
  } catch (e) {
    console.error('Unable to get dvrs list', e);
    dispatch({type: SEARCH_DVR_FAIL});
  }
  return null;
};

export const scanVideoAvailable = body => async dispatch => {
  dispatch({type: DVR_SCAN_VIDEO_REQUEST});
  try {
    const result = await OnDemandService.scanVideoAvailable(body);
    dispatch({type: DVR_SCAN_VIDEO_SUCCESS, payload: result});
  } catch (e) {
    console.error('Unable to scan video', e);
    dispatch({type: DVR_SCAN_VIDEO_FAIL});
  }
};

export const loadChannels = params => async dispatch => {
  dispatch({type: LOAD_CHANNELS_REQUEST});
  try {
    const channelsAvailable = await OnDemandService.loadChannels(params);
    dispatch({type: LOAD_CHANNELS_SUCCESS, payload: channelsAvailable});
    return channelsAvailable;
  } catch (e) {
    console.error('Unable to get channels', e);
    const body = get(e, 'body', {});
    dispatch({type: LOAD_CHANNELS_FAIL, payload: body});
    return body;
  }
};

export const checkMatchDVR = params => async dispatch => {
  dispatch({type: GET_MATCH_DVR_REQUEST});

  try {
    const matchDVR = await OnDemandService.checkMatchDVR(params);
    dispatch({type: GET_MATCH_DVR_SUCCESS, payload: matchDVR});
  } catch (e) {
    console.error('Unable to check DVR', e);
    dispatch({type: GET_MATCH_DVR_FAIL});
  }
};

export const loadTotalRequested = () => async dispatch => {
  dispatch({type: GET_TOTAL_REQUEST});

  try {
    const totalRequested = await OnDemandService.loadTotalRequested();
    dispatch({type: GET_TOTAL_SUCCESS, payload: totalRequested});
  } catch (e) {
    console.error('Unable to get total requested', e);
    dispatch({type: GET_TOTAL_FAIL});
  }
};

export const postFetchAsync = body => async dispatch => {
  dispatch({type: FETCH_ASYNC_REQUEST});
  try {
    const command = await OnDemandService.postFetchAsync(body);
    dispatch({type: FETCH_ASYNC_SUCCESS, payload: command});
  } catch (e) {
    console.error('Unable to fetch async', e);
    dispatch({type: FETCH_ASYNC_FAIL});
  }
};

export const loadQueueList = params => async dispatch => {
  dispatch({type: GET_QUEUE_LIST_REQUEST});

  try {
    const queueList = await OnDemandService.loadQueueList(params);
    dispatch({type: GET_QUEUE_LIST_SUCCESS, payload: queueList});
  } catch (e) {
    console.error('Unable to get queue list', e);
    dispatch({type: GET_QUEUE_LIST_FAIL});
  }
};

export const loadPreviouslyQueueList = params => async dispatch => {
  dispatch({type: GET_PREVIOUSLY_QUEUE_LIST_REQUEST, payload: params});
  try {
    const queueList = await OnDemandService.loadPreviouslyQueueList(params);
    dispatch({type: GET_PREVIOUSLY_QUEUE_LIST_SUCCESS, payload: {queueList, params}});
  } catch (e) {
    console.error('Unable to get previously queue list', e);
    dispatch({type: GET_PREVIOUSLY_QUEUE_LIST_FAIL});
  }
};

export const retryOndemandMedia = media => async dispatch => {
  dispatch({type: RETRY_ONDEMAND_MEDIA_REQUEST});

  try {
    const retry = await OnDemandService.retryOndemandMedia(media);
    dispatch({type: RETRY_ONDEMAND_MEDIA_SUCCESS, payload: retry, media});

    return retry;
  } catch (e) {
    console.error('Unable to retry ondemand media', e);

    dispatch({type: RETRY_ONDEMAND_MEDIA_FAIL});
  }
};

export const cancelOndemandMedia = media => async dispatch => {
  dispatch({type: CANCEL_ONDEMAND_MEDIA_REQUEST});

  try {
    const cancel = await OnDemandService.cancelOndemandMedia(media);
    dispatch({type: CANCEL_ONDEMAND_MEDIA_SUCCESS, payload: cancel, media});
    
    return cancel;
  } catch (e) {
    console.error('Unable to cancel ondemand media', e);

    dispatch({type: CANCEL_ONDEMAND_MEDIA_FAIL});
  }
};

export const changeWifiCheckbox = wifi => async dispatch => {
  dispatch({type: CHANGE_WIFI_CHECKBOX, payload: {wifi}});
};

export const goToLibrary = () => dispatch => dispatch(push('/library'));
export const goToOnDemand = () => dispatch => dispatch(push('/onDemand'));
export const clearChannels = () => dispatch => dispatch({type: CLEAR_CHANNELS});
export const reset = () => dispatch => dispatch({type: RESET});
export const clearTables = () => dispatch => dispatch({type: CLEAR_TABLES});
export const clearTablesList = () => dispatch => dispatch({type: CLEAR_TABLES_LIST});
