import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Icon from '@material-ui/core/Icon';
import {I18n} from 'react-redux-i18n';
import CommentForm from './CommentForm';
import {isInternalUser, hasAccess} from '@peoplenet/vi-components-ui/src/app/user';

export default class Comment extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      deleting: false,
      deleted: false,
      commentText: props.text
    };
  }

  editMode = () => {
    this.setState({editing: !this.state.editing});
  }

  handleChangeText = ({target: {value, edit}}) => {
    if (!edit) {
      this.setState({commentText: value});
    }
  }

  postComment = (mediaId, commentBody) => {
    return this.props.postComment(mediaId, commentBody, this.props.id).then(success => {
      setTimeout(() => this.setState({editing: false}), 500);
      return success;
    });
  }

  cancelDelete = () => {
    this.setState({deleting: false});
  }

  deleteComment = () => {
    this.setState({deleting: true});
  }

  deleteConfirm = () => {
    this.setState({deleted: true}, () => {
      this.props.deleteComment(this.props.id).then(success => {
        if (!success) {
          this.setState({deleting: false});
        }
      });
    });
  }

  renderEdited = () => {
    if (!this.props.updated) return null;

    const {
      userName,
      date
    } = this.props.updated;

    return (
      <span> {I18n.t('page.playerPage.comments.edited')} {userName} - {moment(date).format(I18n.t('configuration.dateTimeFormat'))}</span>
    );
  };

  renderActions = () => {
    if (isInternalUser() || !hasAccess(['videoIntelligenceAdmin'])) {
      return null;
    }

    return (
      <div className="reviewdesktop__commentrow-actions">
        <Icon onClick={this.deleteComment} className="reviewdesktop__commentrow-icon">delete</Icon>
        <Icon onClick={this.editMode} className="reviewdesktop__commentrow-icon">edit</Icon>
      </div>
    );
  }

  renderDeleteConfirmation = () => {
    if (!this.state.deleting) return null;

    return (
      <div className="reviewdesktop__confirmation">
        {I18n.t('page.playerPage.comments.delete.text')}
        <Icon onClick={this.deleteConfirm} className="reviewdesktop__confirmation-check" title={I18n.t('page.playerPage.comments.delete.confirm')}>check_circle</Icon>
        <Icon onClick={this.cancelDelete} className="reviewdesktop__confirmation-close" title={I18n.t('page.playerPage.comments.delete.cancel')}>highlight_off</Icon>
      </div>
    );
  }

  render() {
    const {
      userName,
      date,
      text,
      id
    } = this.props;
    const {deleted} = this.state;

    let wrapperClasses = ['reviewdesktop__commentrow'];
    if (deleted) {
      wrapperClasses.push('fade');
    }

    if (!this.state.editing) {
      return (
        <div className={classnames(wrapperClasses)}>
          {this.renderDeleteConfirmation()}
          <Icon className="reviewdesktop__commentrow-avatar">person</Icon>
          <p className="reviewdesktop__commentrow-text">
            <span>{userName} - {moment(date).format(I18n.t('configuration.dateTimeFormat'))} - </span>
            {text}
            {this.renderEdited()}
          </p>
          {this.renderActions()}
        </div>
      );
    }

    return (
      <CommentForm
        userInformation={this.props.userInformation}
        edit
        commentId={id}
        postComment={this.postComment}
        cancel={this.editMode}
        text={text}
        onChangeCommentText={this.handleChangeText}
        commentText={this.state.commentText}
      />
    );
  }
}
