/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import {I18n} from 'react-redux-i18n';
import {CAMERA_VIEWS} from '@peoplenet/vi-components-ui/src/app/configuration/cameras';

class ChannelsAvailable extends React.Component {
  getCameras = () => {
    const {machineType} = this.props.options;
    return CAMERA_VIEWS.getArray(machineType);
  };

  handleChange = name => event => {
    const {cameras} = this.props.options;
    cameras[name] = event.target.checked;
    this.props.onChange(cameras);
  }

  render() {
    const {options} = this.props;
    const camerasList = this.getCameras();
    const isDisabled = name => {
      if (options.order) {
        return options.order.indexOf(camerasList.indexOf(name) + 1) === -1;
      }
      return true;
    };

    const {cameras, showLabel = true} = options;
    return (
      <React.Fragment>
        <div className="ondemand__allviewscont">
          {showLabel && (
            <label className="ondemand__col1">{I18n.t('page.onDemand.form.camerasAvailable')}</label>
          )}
          <div className="ondemand__col2">
            <label htmlFor="frontCamera" className={isDisabled('front') ? 'ondemand__viewcont disableme' : 'ondemand__viewcont'}>
              <Checkbox id="frontCamera" checked={cameras.front} disabled={isDisabled('front')} onChange={this.handleChange('front')} />
              <div className="ondemand__cameraviews ff-cam">
                <Icon className="videothumb__camview">arrow_upward</Icon>{I18n.t('page.onDemand.form.camera.ff')}
              </div>
            </label>
            <label htmlFor="backCamera" className={isDisabled('back') ? 'ondemand__viewcont disableme' : 'ondemand__viewcont'}>
              <Checkbox id="backCamera" checked={cameras.back} disabled={isDisabled('back')} onChange={this.handleChange('back')} />
              <div className="ondemand__cameraviews re-cam">
                <Icon className="videothumb__camview">arrow_downward</Icon>{I18n.t('page.onDemand.form.camera.re')}
              </div>
            </label>
            {camerasList.indexOf('left') > 0 &&
              (
                <label htmlFor="leftCamera" className={isDisabled('left') ? 'ondemand__viewcont disableme' : 'ondemand__viewcont'}>
                  <Checkbox id="leftCamera" checked={cameras.left} disabled={isDisabled('left')} onChange={this.handleChange('left')} />
                  <div className="ondemand__cameraviews dr-cam">
                    <Icon className="videothumb__camview">arrow_back</Icon>{I18n.t('page.onDemand.form.camera.dr')}
                  </div>
                </label>
              )
            }
            {camerasList.indexOf('right') > 0 &&
              (
                <label htmlFor="rightCamera" className={isDisabled('right') ? 'ondemand__viewcont disableme' : 'ondemand__viewcont'}>
                  <Checkbox id="rightCamera" checked={cameras.right} disabled={isDisabled('right')} onChange={this.handleChange('right')} />
                  <div className="ondemand__cameraviews pa-cam">
                    {I18n.t('page.onDemand.form.camera.pa')}<Icon className="videothumb__camview">arrow_forward</Icon>
                  </div>
                </label>
              )
            }
          </div>
        </div>
        {showLabel && <div className="ondemand__line">&nbsp;</div>}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ChannelsAvailable);
