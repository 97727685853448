import React from 'react';
import {connect} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {I18n} from 'react-redux-i18n';
import querystring from 'querystring';
import {Icon} from '@material-ui/core';
import {push} from 'connected-react-router';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import {get, toLower} from 'lodash';
import ScrollEvent from 'react-onscroll';

import ProgressSpinner from '../../components/ProgressSpinner';
import {
  loadPreviouslyQueueList,
  loadQueueList,
  retryOndemandMedia,
  clearTables,
  reset
} from '../OnDemandActions';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import {formatDuration} from '@peoplenet/vi-components-ui/src/app/formatter';
import CameraViews from './CameraViews';

class PreviouslyQueue extends React.Component {
  state = {
    isRetryingMedia: null
  }

  componentDidMount = () => {
    if (this.props.isEnabled) {
      this.props.loadPreviouslyQueueList({page: 0});
    }
  }

  componentWillReceiveProps(nextProps) {
    const {isRetried} = nextProps;

    if (isRetried) {
      this.setState({isRetryingMedia: null});
    }
  }

  componentDidUpdate = () => {
    const {previouslyRequestedQueue, isEnabled} = this.props;
    const height = window.innerHeight;
    const innerH = document.body.clientHeight;

    if (height > innerH &&
      isEnabled &&
      !previouslyRequestedQueue.isLoading &&
      previouslyRequestedQueue.list.length > 0 &&
      (previouslyRequestedQueue.page + 1 < previouslyRequestedQueue.pages)) {
      const {sort, sortOrder} = previouslyRequestedQueue;
      this.props.loadPreviouslyQueueList({
        page: previouslyRequestedQueue.page + 1,
        sort,
        sortOrder
      });
    }
  }

  getUsername = item => {
    return ((item.metadata && item.metadata.userNameRequested) || item.userId || item.uid);
  }

  handleLazyLoading = () => {
    const {previouslyRequestedQueue, isEnabled} = this.props;
    if (isEnabled && !previouslyRequestedQueue.isLoading && previouslyRequestedQueue.hasMorePages) {
      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';
      const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      if (percent > 80) {
        this.props.loadPreviouslyQueueList({
          page: previouslyRequestedQueue.page + 1,
          sort: previouslyRequestedQueue.sort,
          sortOrder: previouslyRequestedQueue.sortOrder
        });
      }
    }
  }

  handleRetryMedia = (mediaIndex, media) => () => {
    this.setState({isRetryingMedia: mediaIndex});

    setTimeout(() => {
      this.props.retryOndemandMedia(media);
    }, 1000);
  }

  openPlayer = media => () => {
    const query = {mediaId: media.id};
    this.props.push(`/player?${querystring.stringify(query)}`);
  }

  renderQueueItem = (e, i) => {
    const statusLower = toLower(e.status);

    const tblClasses = classNames({
      table__success: (statusLower === 'completed'),
      table__error: (statusLower === 'expired' || statusLower === 'notavailable')
    });

    let status = I18n.t(`page.onDemand.requestQueue.statuses.${statusLower}`);

    if (statusLower === 'created' || statusLower === 'waiting' || statusLower === 'reopened') {
      status = I18n.t('page.onDemand.requestQueue.statuses.requested');
    }

    return (
      <TableRow className={tblClasses} key={i}>
        <TableCell className="ondemand__actionscell">{this.renderActionButton(i, e)}</TableCell>
        <TableCell className={`${tblClasses}-cell ondemand__statuscell`}>{status}</TableCell>
        <TableCell className="ondemand__vehiclecell">{e.vehicleNumber}</TableCell>
        <TableCell className="ondemand__whocell">{this.getUsername(e)}</TableCell>
        <TableCell className="ondemand__datetimecell">{moment(e.requestDate).format(I18n.t('configuration.fullDateTimeFormat'))}</TableCell>
        <TableCell className="ondemand__durationcell">{formatDuration(e.durationSeconds)}</TableCell>
        <TableCell className="ondemand__datetimecell">{moment(e.startDate).format(I18n.t('configuration.fullDateTimeFormat'))}</TableCell>
        <TableCell className="ondemand__smallcamicons ondemand__viewscell">
          <CameraViews channels={e.channels} machineType={get(e, 'metadata.machineType', 0)} renderIcons={false} />
        </TableCell>
      </TableRow>
    );
  }

  renderActionButton = (mediaIndex, e) => {
    const statusLower = toLower(e.status);
    const toRetryList = ['expired', 'cancelled'];

    const {canChangeStatus} = this.props;

    if (toRetryList.includes(statusLower)) {
      return (
        <IconButton
          className={this.state.isRetryingMedia === mediaIndex ? 'spin' : null}
          onClick={this.handleRetryMedia(mediaIndex, e)}
          disabled={!canChangeStatus}
          title={!canChangeStatus && I18n.t('defaults.noPermissionFunctionality')}
        >
          <Icon>autorenew</Icon>
        </IconButton>
      );
    } else if (statusLower === 'completed') {
      return (
        <IconButton onClick={this.openPlayer(e)}>
          <Icon className="play-icon testclass">play_circle_filled</Icon>
        </IconButton>
      );
    }
    return null;
  }

  renderPreviouslyRequestedQueueEmpty() {
    const {previouslyRequestedQueue} = this.props;

    if (!previouslyRequestedQueue.isLoading && previouslyRequestedQueue.list.length <= 0) {
      return (
        <TableRow>
          <TableCell>
            <span className="ondemand__infomsg"><Icon className="ondemand__msgicon">info</Icon>{I18n.t('page.onDemand.requestQueue.previouslyListEmpty')}</span>
          </TableCell>
        </TableRow>
      );
    }

    return null;
  }

  renderRequestQueue = () => {
    const list = get(this.props, 'previouslyRequestedQueue.list', []);

    return (
      <Table className="table__contnh">
        <TableBody>
          {this.renderPreviouslyRequestedQueueEmpty()}

          {
            list.map((e, i) => (
              this.renderQueueItem(e, i)
            ))
          }
        </TableBody>
      </Table>
    );
  }

  render() {
    const {previouslyRequestedQueue} = this.props;
    return (
      <React.Fragment>
        <ProgressSpinner show={previouslyRequestedQueue.isLoading} />
        <ScrollEvent handleScrollCallback={this.handleLazyLoading} />

        {this.renderRequestQueue()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {onDemand, customer} = state;
  return {...state.i18n.locale, ...onDemand, customer};
}

export default connect(mapStateToProps, {
  loadPreviouslyQueueList,
  loadQueueList,
  retryOndemandMedia,
  clearTables,
  reset,
  push
})(PreviouslyQueue);
