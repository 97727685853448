import React from 'react';
import {I18n} from 'react-redux-i18n';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {each, get, toLower, includes} from 'lodash';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import BarLineGraph from '../../../components/graph/BarLineGraph';

export default class TotalEventsVsReviewedVideos extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }

  constructor(props) {
    super(props);
    this.labels = [];
    this.oers = [];
    this.reviews = [];
    this.hasData = false;
    const {data} = props;
    each(data, entry => {
      const label = moment(entry.key).utc().format(I18n.t('configuration.dateFormat'));
      const isWeek = includes(toLower(entry.key_as_string), 'week');
      this.labels.push(`${isWeek ? I18n.t('page.dashboard.graph.label.weekOf') : ''} ${label}`);
      
      const oerCount = get(entry, 'oers', 0);
      const reviewCount = get(entry, 'reviews', 0);
      this.oers.push(oerCount);
      this.reviews.push(reviewCount);
      this.hasData = this.hasData || oerCount > 0 || reviewCount > 0;
    });
  }
  renderGraph = () => {
    const barDataset = {
      data: this.oers,
      label: I18n.t('page.dashboard.graph.label.event'),
      borderWidth: 3,
      backgroundColor: 'rgba(243, 60, 45, 0.3)',
      borderColor: 'rgba(243, 60, 45, 1)'
    };
    const lineDataset = {
      data: this.reviews,
      label: I18n.t('page.dashboard.graph.label.reviews'),
      borderWidth: 3,
      backgroundColor: 'rgba(0, 166, 90, 0.3)',
      borderColor: 'rgba(0, 166, 90, 1)'
    };
    return (
      <BarLineGraph
        labels={this.labels}
        barDataset={barDataset}
        lineDataset={lineDataset}
        height={140}
      />
    );
  }
  render() {
    if (!this.hasData) {
      return (
        <Card className="kpi__graph kpi__container-nodata">
          <Typography variant="title" component="p">
            {I18n.t('page.dashboard.graph.totalEventsVsReviews')}
          </Typography>
          <span>
            <Icon className="insert_chart">insert_chart</Icon>
            {I18n.t('page.dashboard.graph.noData')}
          </span>
        </Card>
      );
    }
    return (
      <Card className="kpi__graph">
        <Typography variant="title" component="h2">
          {I18n.t('page.dashboard.graph.totalEventsVsReviews')}
        </Typography>
        <CardContent>
          {this.renderGraph()}
        </CardContent>
      </Card>
    );
  }
}
