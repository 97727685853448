/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import {I18n} from 'react-redux-i18n';

import Grid from '@material-ui/core/Grid';
import ScrollEvent from 'react-onscroll';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import {get, isEqual} from 'lodash';
import classnames from 'classnames';

import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';
import ProgressSpinner from '../../components/ProgressSpinner';
import MediaList from '../component/MediaList';
import {viewMode as VIEW_MODE} from '../constants';
import {flagVideo} from '../../flagVideo/FlagVideoActions';

import {
  clear,
  searchMedias,
  openPlayerPage,
  checkMedia,
  deleteVideo,
  deleteSelectedMedia,
  downloadListViewAsCsv,
  clearDownloadListViewAsCsv
} from '../LibraryActions';
import {
  addFilterChipOnDriverClick,
  addFilterChipOnVehicleClick,
  addFilterChipOnTerminalClick,
  addFilterOnOERClick,
  addOnDemandFilter,
  setOnDemandFilter
} from '@peoplenet/vi-components-ui/src/LibraryFilter/libraryFilterActions';
import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import SummaryMenuFilter from '@peoplenet/vi-components-ui/src/LibraryFilter/component/SummaryMenuFilter';
import DeleteConfirmationModal from '../component/DeleteConfirmationModal';
import {popQueryParameter, VEHICLE_NUMBER_KEY, ON_DEMAND_FILTER_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';

class AbstractLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMediaId: '',
      summaryMenuCardOpened: true,
      summaryMenuListOpened: false,
      deleteDialogOpen: false
    };
    const vehicleNumber = popQueryParameter(VEHICLE_NUMBER_KEY);
    const onDemandFilter = popQueryParameter(ON_DEMAND_FILTER_KEY);
    if (vehicleNumber) {
      props.addFilterChipOnVehicleClick(vehicleNumber);
    }
    if (onDemandFilter) {
      props.setOnDemandFilter(onDemandFilter !== 'false');
    }
  }

  componentWillMount() {
    this.props.clear(true);
    let {filters} = this.props;
    // has saved media
    const {saved} = this.props;
    if (saved && saved.mediaId && saved.amount) {
      filters = {
        ...filters,
        overwritePage: Math.ceil(saved.amount / this.props.pageSize) - 1,
        pageSize: saved.amount,
        endTime: saved.endTime,
        page: 0
      };
      this.setState({selectedMediaId: saved.mediaId});
    }
    this.props.searchMedias(this.getSearchParams(false, {...filters}));
  }

  componentDidMount = () => {
    try {
      const summaryMenuOpened = JSON.parse(localStorage.getItem('summaryMenuOpened'));
      this.setState({...summaryMenuOpened});
    } catch (e) {
      // Do nothing
    }

    if (!this.props.auth.isAuthenticated()) {
      this.props.logout();
    }
  }

  componentWillReceiveProps(props) {
    const {filters} = props;

    if (props.forced || !isEqual(this.props.filters, filters)) {
      this.props.clear();
      this.props.searchMedias(this.getSearchParams(false, {...filters}));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mediaList.length === 0 && this.props.mediaList.length !== 0) {
      setTimeout(() => {
        this.scrollToLastViewedItem();
      }, 1800);
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  getSearchParams = (_more, _terms) => {
    let terms = {};

    if (_terms) {
      terms = {..._terms};
    }
    const customerData = get(this.props, 'customer.data', null);
    const useTerminal = get(customerData, 'settings.useTerminal', false);
    if (useTerminal) {
      terms.terminalRestriction = true;
    }

    if (!terms.page) {
      if (_more === false) {
        terms.page = AbstractLibrary.defaultProps.page;
      } else {
        terms.page = this.props.page;
      }
    }

    if (!terms.pageSize) {
      terms.pageSize = this.props.pageSize;
    }

    terms.groupName = false;
    terms.perSecondData = false;
    terms.summary = true;
    terms.isInternal = this.props.isInternal;
    terms.isAdmin = this.props.isAdmin;
    return terms;
  }

  handleDownloadListViewAsCsv = () => {
    const customer = get(this.props, 'customer', null);
    const pairingMethod = get(customer, 'data.dvrPairing.method', 'dsn');
    this.props.downloadListViewAsCsv(this.getSearchParams(true, this.props.filters), pairingMethod);
  }

  handleClearDownloadListViewCsv = () => {
    this.props.clearDownloadListViewAsCsv();
  }

  handleClickVideo = mediaId => camera => {
    const mediaList = get(this.props, 'mediaList', []);
    const index = mediaList.findIndex(m => m.mediaId === mediaId);
    const endTime = get(mediaList, '[0].date');
    const amount = mediaList.length;
    this.props.openPlayerPage({
      mediaId,
      endTime,
      amount,
      index,
      camera
    });
  }

  handleDeleteDialogModal = media => {
    const mediaId = get(media, 'mediaId', null);
    if (mediaId != null) {
      const {mediaList} = this.props;
      const index = mediaList.findIndex(m => m.mediaId === mediaId);
      this.props.checkMedia('all', false);
      this.props.checkMedia(index, true);
    }
    const {deleteDialogOpen} = this.state;
    this.setState({deleteDialogOpen: !deleteDialogOpen});
  }

  handleDeleteMedias = () => {
    this.props.deleteSelectedMedia().then(() => {
      this.handleDeleteDialogModal();
      const {filters} = this.props;
      this.props.clear();
      this.props.searchMedias(this.getSearchParams(false, {...filters}));
    });
  }

  handleLazyLoading = () => {
    if (!this.props.isLoading && this.props.hasMorePages) {
      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';
      const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      if (percent > 80) {
        ReactGA.event({
          category: 'Library',
          action: 'Load more',
          label: 'search medias'
        });
        this.props.searchMedias(this.getSearchParams(true, this.props.filters));
      }
    }
  }

  handleSummaryClick = () => {
    const {viewMode} = this.props;
    let {summaryMenuCardOpened, summaryMenuListOpened} = this.state;
    if (viewMode === VIEW_MODE.CARD) {
      summaryMenuCardOpened = !summaryMenuCardOpened;
    } else if (viewMode === VIEW_MODE.LIST) {
      summaryMenuListOpened = !summaryMenuListOpened;
    }
    const newState = {summaryMenuCardOpened, summaryMenuListOpened};
    this.setState(newState);
    localStorage.setItem('summaryMenuOpened', JSON.stringify(newState));
  }

  scrollToLastViewedItem = () => {
    const itemComponent = this.divToScrollTo;
    if (itemComponent) {
      const domNode = ReactDOM.findDOMNode(itemComponent);
      domNode.scrollIntoView({
        block: 'center',
        inline: 'center'
      });
    }
  }

  prepareReference = (media, element) => {
    if (media.mediaId === this.state.selectedMediaId) {
      this.divToScrollTo = element;
    }
  }

  renderErrorMessage() {
    const isEmpty = this.props.empty && !this.props.isLoading;
    if (isEmpty || this.props.errorMessage) {
      let errorMessage = I18n.t('page.library.list.noMedia');
      if (this.props.errorMessage) {
        errorMessage = I18n.t('page.library.list.error');
      }
      return (
        <div>
          <Grid container className="media">
            <Grid item xl={3} lg={6} md={6} sm={10} xs={12}>
              <Paper className="papercard papercard-mobile" elevation={2}>
                <Icon className="papercard__erroricon">videocam_off</Icon>
                <p>{errorMessage}</p>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    return null;
  }

  renderSummary = () => {
    return (
      <SummaryMenuFilter
        summary={this.props.summary}
        libraryTotalCount={this.props.libraryTotalCount}
        handleSummaryClick={this.handleSummaryClick}
        isMobile={this.props.isMobile}
      />
    );
  }

  renderMediaList = () => {
    return (
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classnames('library__cardcontainer', {'mobilelibrary': this.props.isMobile})}>
        <Grid container spacing={16}>
          <MediaList
            onFlagVideo={this.props.flagVideo}
            canDelete={this.props.canDelete}
            isInternal={this.props.isInternal}
            medias={this.props.mediaList}
            isMobile={this.props.isMobile}
            handleClickVideo={this.handleClickVideo}
            prepareReference={this.prepareReference}
            viewMode={this.props.viewMode}
            locale={this.props.locale}
            onCheckMedia={this.props.checkMedia}
            deleteVideo={this.handleDeleteDialogModal}
            addFilterChipOnDriverClick={this.props.addFilterChipOnDriverClick}
            addFilterChipOnVehicleClick={this.props.addFilterChipOnVehicleClick}
            addFilterChipOnTerminalClick={this.props.addFilterChipOnTerminalClick}
            addFilterOnOERClick={this.props.addFilterOnOERClick}
            addOnDemandFilter={this.props.addOnDemandFilter}
            user={this.props.userInformation}
            handleDownloadListViewAsCsv={this.handleDownloadListViewAsCsv}
            handleClearDownloadListViewCsv={this.handleClearDownloadListViewCsv}
            listViewCsv={this.props.listViewCsv}
            listViewCsvIsLoading={this.props.listViewCsvIsLoading}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {summaryMenuCardOpened, summaryMenuListOpened, deleteDialogOpen} = this.state;
    let wrapperClass = null;
    if (this.props.viewMode === VIEW_MODE.CARD && summaryMenuCardOpened === true) {
      wrapperClass = 'summarycolumn-show summarycolumn-in';
    } else if (this.props.viewMode === VIEW_MODE.LIST && summaryMenuListOpened === true) {
      wrapperClass = 'summarycolumn-show summarycolumn-in';
    }

    return (
      <div className={wrapperClass}>
        <ScrollEvent handleScrollCallback={this.handleLazyLoading} />
        <Grid container spacing={16} className={this.props.isLoading && this.props.empty ? 'hideme' : 'library__container'}>
          {this.renderSummary()}
          {this.renderMediaList()}
        </Grid>
        {this.renderErrorMessage()}
        <ProgressSpinner show={this.props.isLoading} />
        <DeleteConfirmationModal
          isOpen={deleteDialogOpen}
          onClose={this.handleDeleteDialogModal}
          medias={this.props.mediaList}
          onDelete={this.handleDeleteMedias}
        />
      </div>
    );
  }
}

AbstractLibrary.defaultProps = {
  page: -1,
  pageSize: (/Mobi/.test(navigator.userAgent) ? 15 : 50),
  isLoading: false
};

function mapStateToProps(state, ownProps) {
  const {library, customer, libraryFilters, user: {userInformation}} = state;
  const filters = {...libraryFilters.filters};
  if (ownProps.viewMode) {
    library.viewMode = ownProps.viewMode;
  }

  const isAdminOrManager = hasAccess([
    'changeCustomer',
    'videoIntelligenceManager',
    'videoIntelligenceAdmin'
  ]);

  const isInternal = hasAccess([
    'videoIntelligenceInternal'
  ]);
  
  const isAdmin = hasAccess([
    'videoIntelligenceAdmin'
  ]);

  const canDelete = (isAdminOrManager && !isInternal);

  return {
    locale: state.i18n.locale,
    ...library,
    customer,
    filters,
    canDelete,
    isInternal,
    isAdmin,
    userInformation
  };
}

export default connect(mapStateToProps, {
  clear,
  openPlayerPage,
  searchMedias,
  logout,
  checkMedia,
  addFilterChipOnDriverClick,
  addFilterChipOnVehicleClick,
  addFilterChipOnTerminalClick,
  addFilterOnOERClick,
  addOnDemandFilter,
  deleteVideo,
  deleteSelectedMedia,
  downloadListViewAsCsv,
  clearDownloadListViewAsCsv,
  flagVideo,
  setOnDemandFilter
})(AbstractLibrary);
