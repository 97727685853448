import React from 'react';
import {I18n} from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import {each, get, capitalize} from 'lodash';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import LineGraph from '../../../components/graph/LineGraph';

export default class IndustryAverageDistancePerEvent extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }

  constructor(props) {
    super(props);
    this.labels = [];
    this.points = [];
    this.hasData = true;
    this.systemMeasure = capitalize(this.props.systemMeasure);
    this.prepareData();
  }

  prepareData = () => {
    const {data} = this.props;

    if (data) {
      // Initialize arrays to store data
      this.points.push({
        data: [],
        backgroundColor: 'rgba(243, 60, 45, 0.3)',
        borderColor: 'rgba(243, 60, 45, 1)',
        borderWidth: 3,
        label: I18n.t('page.dashboard.graph.label.industry.small')
      });

      this.points.push({
        data: [],
        backgroundColor: 'rgba(0, 166, 90, 0.3)',
        borderColor: 'rgba(0, 166, 90, 1)',
        borderWidth: 3,
        label: I18n.t('page.dashboard.graph.label.industry.medium')
      });

      this.points.push({
        data: [],
        backgroundColor: 'rgba(13, 106, 168, 0.3)',
        borderColor: 'rgba(13, 106, 168, 1)',
        borderWidth: 3,
        label: I18n.t('page.dashboard.graph.label.industry.big')
      });
    }


    const amount = 3; // there are only three categories
    each(data, entry => {
      const label = moment(entry.date).utc().format(I18n.t('configuration.dateFormat'));
      this.labels.push(`${I18n.t('page.dashboard.graph.label.weekOf')} ${label}`);
      for (let i = 0; i < amount; i++) {
        const sub = get(entry, `category${1 + i}`, 0).toFixed(1);
        this.points[i].data.push(sub);
        this.hasData = this.hasData || sub !== 0;
      }
    });
  }

  renderGraph = () => {
    return (
      <LineGraph
        labels={this.labels}
        datasets={this.points}
        locale={this.props.locale}
      />
    );
  }

  render() {
    if (!this.hasData) {
      return (
        <Card className="kpi__graph kpi__container-nodata">
          <Typography variant="title" component="p">
            {I18n.t(`page.dashboard.graph.industryAverage${this.systemMeasure}PerEvent`)}
          </Typography>
          <span>
            <Icon className="insert_chart">insert_chart</Icon>
            {I18n.t('page.dashboard.graph.noData')}
          </span>
        </Card>
      );
    }

    return (
      <Card className="kpi__graph">
        <Typography variant="title" component="h2">
          {I18n.t(`page.dashboard.graph.industryAverage${this.systemMeasure}PerEvent`)}
        </Typography>
        <CardContent>
          {this.renderGraph()}
        </CardContent>
      </Card>
    );
  }
}
