import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';
import store from '../stores';
import BaseService from '../middleware/BaseService';

const SIZE = 100;

const NOTIFICATIONS_READ_ENDPOINT = '/notifications/read';
const NOTIFICATIONS_ENDPOINT = '/notifications';

class NotificationService extends BaseService {
  prefix = NOTIFICATIONS_ENDPOINT;

  async pullNotifications() {
    const params = {
      size: SIZE
    };

    const notifications = get(store.getState(), 'notification.list', null);

    if (!isEmpty(notifications)) {
      params.lastPulledDate = notifications[0].date;
    }

    return this.search(params);
  }

  async readNotifications(notifications) {
    return this.api.put(NOTIFICATIONS_READ_ENDPOINT, notifications.map(notification => pick(notification, 'id')));
  }

  async loadPreviousNotifications(notifications) {
    const page = Math.floor(notifications.length / SIZE);

    return this.search({
      page,
      size: SIZE
    });
  }

  async deleteNotification(notification) {
    console.log(notification);
    return this.remove(notification.id || notification, this.prefix);
  }

  async deleteNotifications(notifications) {
    return this.api.remove(NOTIFICATIONS_ENDPOINT, notifications.map(notification => pick(notification, 'id')));
  }
}

export default new NotificationService();
