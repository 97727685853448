/* eslint-disable flowtype/require-valid-file-annotation */

import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import {push} from 'connected-react-router';
import {I18n} from 'react-redux-i18n';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ReactGA from 'react-ga';
import Divider from '@material-ui/core/Divider';
import LanguageSelection from './LanguageSelection';
import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';
import SwitchAccountAutoComplete from '../switchCustomer/components/SwitchCustomerAutocomplete';
import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import {isBeta} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import PlatformSwitcher from './PlatformSwitcher';
import DvrHealthIcon from '../images/dvr-health-icon.svg';
import OnDemandIcon from '@peoplenet/vi-components-ui/src/images/ondemand-grey-icon.svg';
import DvrToolkitIcon from '../images/dvrtoolkit-icon.svg';

class DrawerMenu extends React.Component {
  state = {
    left: false
  };

  getSupportToolUrl = () => {
    if (this.props.isBeta) {
      return `${SUPPORT_TOOL_URL}/?source=pfm`;
    }

    const token = localStorage.getItem('id_token');
    const accessToken = localStorage.getItem('access_token');

    return `${SUPPORT_TOOL_URL}/?accessToken=${accessToken}&token=${token}`;
  }

  handleLogout = () => {
    this.props.logout();
    this.handleGA('Logout');
  }

  handleGA = gaAction => {
    ReactGA.event({
      category: 'Main Menu',
      action: gaAction,
      label: 'Drawer'
    });
  }

  toggleDrawerSilently = (side, open) => {
    this.setState({
      [side]: open
    });
  }

  toggleDrawer = (side, open) => () => {
    this.toggleDrawerSilently(side, open);
    if (open) {
      this.handleGA('Open Menu');
    } else {
      this.handleGA('Close Menu');
    }
  }

  goTo = (page, gaAction) => () => {
    this.toggleDrawerSilently('left', false);
    this.props.dispatch(push(page));

    this.handleGA(gaAction);
  }

  renderDVRHealthMenu = (justIcon = false) => {
    return (
      <React.Fragment>
	<ListItem button onClick={this.goTo('/dvr-health', 'DVR Health')} id={`mainMenuGoToDvrHealth_${justIcon}`} title={justIcon ? I18n.t('page.dvrHealth.title') : ''}>
          <div className="mainmenu__dvrhealthicon" title={justIcon ? I18n.t('page.dvrHealth.title') : ''}>
            <img src={DvrHealthIcon} />
          </div>
          {(!justIcon && <ListItemText primary={I18n.t('page.dvrHealth.title')} />)}
        </ListItem>
      </React.Fragment>
    );
  }

  renderDesktopMenuBrand = () => {
    if (this.props.isMobile) return null;
    return (
      <React.Fragment>
        <div className="mainmenu__productbranding">
          Video Intelligence
        </div>
      </React.Fragment>
    );
  }

  renderAccountSwitcher = () => {
    if (!this.props.configuration.isMobile) return null;
    return (
      <div className="mainmenu__accountswitcher">
        <SwitchAccountAutoComplete
          className="mainmenu__accountfield"
          onChange={this.toggleDrawer('left', false)}
        />
      </div>
    );
  }

  renderLimitedAccessMenu = (justIcon = false) => {
    if (!hasAccess([
      'changeCustomer',
      'videoIntelligenceManager',
      'videoIntelligenceAdmin',
      'videoIntelligenceInternal'])) {
      return null;
    }

    return (
      <React.Fragment>
        <ListItem button onClick={this.goTo('/onDemand', 'On-Demand')} id={`mainMenuGoToOnDemand_${justIcon}`} title={justIcon ? I18n.t('page.onDemand.title') : ''}>
          <div className="mainmenu__ondemand" title={justIcon ? I18n.t('page.onDemand.title') : ''}>
            <img src={OnDemandIcon} alt="icon" />
          </div>
          {(!justIcon && <ListItemText primary={I18n.t('page.onDemand.title')} />)}
        </ListItem>
      </React.Fragment>
    );
  }

  renderAdminMenu = (justIcon = false) => {
    if (this.props.isMobile || !hasAccess([
      'changeCustomer',
      'videoIntelligenceAdmin',
      'videoIntelligenceInternal'])) {
      return null;
    }

    return (
      <React.Fragment>
        <ListItem button onClick={this.goTo('/admin', 'Admin')} id={`mainMenuGoToAdmin_${justIcon}`} title={justIcon ? I18n.t('page.admin.title') : ''}>
          <ListItemIcon>
            <Icon title={justIcon ? I18n.t('page.admin.title') : ''}>settings</Icon>
          </ListItemIcon>
          {(!justIcon && <ListItemText primary={I18n.t('page.admin.title')} />)}
        </ListItem>
      </React.Fragment>
    );
  }

  renderReportsMenu = (justIcon = false) => {
    if (this.props.isMobile || !hasAccess([
      'changeCustomer',
      'videoIntelligenceAdmin',
      'videoIntelligenceManager',
      'videoIntelligenceInternal'])) {
      return null;
    }

    return (
      <React.Fragment>
        <ListItem button onClick={this.goTo('/reports', 'Reports')} id={`mainMenuGoToReports_${justIcon}`} title={justIcon ? I18n.t('page.reports.title') : ''}>
          <ListItemIcon>
            <Icon title={justIcon ? I18n.t('page.reports.title') : ''}>insert_chart</Icon>
          </ListItemIcon>
          {(!justIcon && <ListItemText primary={I18n.t('page.reports.title')} />)}
        </ListItem>
      </React.Fragment>
    );
  }

  renderDashboardMenu = (justIcon = false) => {
    if (this.props.isMobile) {
      return null;
    }

    return (
      <React.Fragment>
        <ListItem button onClick={this.goTo('/dashboard', 'Dashboard')} id={`mainMenuGoToDashboard_${justIcon}`} title={justIcon ? I18n.t('page.dashboard.title') : ''}>
          <ListItemIcon>
            <Icon title={justIcon ? I18n.t('page.dashboard.title') : ''}>dashboard</Icon>
          </ListItemIcon>
          {(!justIcon && <ListItemText primary={I18n.t('page.dashboard.title')} />)}
        </ListItem>
      </React.Fragment>
    );
  }

  renderLogout = (justIcon = false) => {
    if (this.props.configuration.isMobile) {
      return (
        <ListItem button onClick={this.handleLogout} id="mainMenuLogout" title={justIcon ? I18n.t('action.logout') : ''}>
          <ListItemIcon>
            <Icon title={justIcon ? I18n.t('action.logout') : ''}>lock</Icon>
          </ListItemIcon>
          {(!justIcon && <ListItemText primary={I18n.t('action.logout')} />)}
        </ListItem>
      );
    }

    return null;
  }

  renderHelpLinks = (justIcon = false) => {
    return (
      <ListItem
        button
        id="helpLink"
        component="a"
        href="https://sites.google.com/peoplenetonline.com/video-intelligence-resources/"
        rel="noopener noreferrer"
        target="_blank"
        title={justIcon ? I18n.t('action.goToHelp') : ''}
      >
        <ListItemIcon>
          <Icon title={justIcon ? I18n.t('action.goToHelp') : ''}>help</Icon>
        </ListItemIcon>
        {(!justIcon && <ListItemText primary={I18n.t('action.goToHelp')} />)}
      </ListItem>
    );
  }

  renderDVRToolKitLink = (justIcon = false) => {
    return (
      <ListItem
        button
        id="toolKitLink"
        component="a"
        href={DVRTOOLKIT_URL}
        rel="noopener noreferrer"
        target="_blank"
        title={justIcon ? I18n.t('action.goToDVRToolkit') : ''}
      >
        <div className="mainmenu__ondemand" title={justIcon ? I18n.t('action.goToDVRToolkit') : ''}>
          <img src={DvrToolkitIcon} alt="icon" />
        </div>
        {(!justIcon && <ListItemText primary={I18n.t('action.goToDVRToolkit')} />)}
      </ListItem>
    );
  }

  renderSupportToolLink = (justIcon = false) => {
    if (this.props.isMobile || !hasAccess(['changeCustomer', 'videoIntelligenceInternal'])) {
      return null;
    }

    return (
      <ListItem
        button
        id="supportToolLink"
        component="a"
        href={this.getSupportToolUrl()}
        rel="noopener noreferrer"
        target="_blank"
        title={justIcon ? I18n.t('action.goToSupportTool') : ''}
      >
        <ListItemIcon>
          <Icon title={justIcon ? I18n.t('action.goToSupportTool') : ''}>build</Icon>
        </ListItemIcon>
        {(!justIcon && <ListItemText primary={I18n.t('action.goToSupportTool')} />)}
      </ListItem>
    );
  }


  render() {
    const canChangeCustomer = get(this.props, 'user.userInformation.changeCustomer', false);
    return (
      <div>
        <IconButton className="mainmenu__hamburgericon" color="inherit" onClick={this.toggleDrawer('left', true)} id="mainMenuOpenClose">
          <Icon color="inherit" aria-label="Menu">menu</Icon>
        </IconButton>
        {(!this.props.isMobile &&
          <div style={{
            width: 48, height: '100%', position: 'fixed', zIdex: 2, top: 64, bottom: 0, left: 0, backgroundColor: 'white'
          }}
          >
            <div className="mainmenu__menulist-narrow" tabIndex={0} role="button">
              <List>
                <ListItem button onClick={this.goTo('/library', 'Library')} id="mainMenuGoToLibrary_mini" title={I18n.t('page.library.title')}>
                  <ListItemIcon>
                    <Icon title={I18n.t('page.library.title')}>video_library</Icon>
                  </ListItemIcon>
                </ListItem>
                {this.renderLimitedAccessMenu(true)}
                {this.renderReportsMenu(true)}
                {this.renderDVRHealthMenu(true)}
                {this.renderDashboardMenu(true)}
                {this.renderAdminMenu(true)}
                {this.renderLogout(true)}
                <Divider />
                {this.renderHelpLinks(true)}
                {this.renderDVRToolKitLink(true)}
                {this.renderSupportToolLink(true)}
              </List>
            </div>
          </div>
        )}
        <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          {canChangeCustomer && this.renderAccountSwitcher()}
          {this.renderDesktopMenuBrand()}
          <div className="mainmenu__menulist" tabIndex={0} role="button">
            <List>
              <ListItem button onClick={this.goTo('/library', 'Library')} id="mainMenuGoToLibrary">
                <ListItemIcon>
                  <Icon>video_library</Icon>
                </ListItemIcon>
                <ListItemText primary={I18n.t('page.library.title')} />
              </ListItem>
              {this.renderLimitedAccessMenu()}
              {this.renderReportsMenu()}
              {this.renderDVRHealthMenu()}
              {this.renderDashboardMenu()}
              {this.renderAdminMenu()}
              {this.renderLogout()}
              {(this.props.isMobile || this.props.isBeta) && <Divider className="mainmenu__divider" />}
              {(this.props.isMobile || this.props.isBeta) && <LanguageSelection />}
              <Divider />
              {this.renderHelpLinks()}
              {this.renderDVRToolKitLink()}
              {this.renderSupportToolLink()}
              {this.props.configuration.isMobile && <PlatformSwitcher />}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

const dispatcherToProps = dispatcher => ({
  dispatch: op => dispatcher(op),
  logout: () => dispatcher(logout())
});

const mapStateToProps = state => ({
  ...state,
  isMobile: state.configuration.isMobile,
  isBeta: isBeta(state)
});

export default connect(mapStateToProps, dispatcherToProps)(DrawerMenu);
