import PeopleNetBrandImage from '../../images/peoplenetbrand.png';

let auth0Config;
switch (TARGET_ENV) {
  case 'qa':
    auth0Config = {
      domain: 'trimble-staging.auth0.com',
      clientId: '7ZbnQbQBs8KnSTtYaDfOXx99y6AF0qvI',
      callbackUrl: location.origin
    };
    break;
  case 'prod':
  case 'production':
    auth0Config = {
      domain: 'trimble.auth0.com',
      clientId: 'nMTPE61ImAdbuYR4zyZBhj0d0Ha256tw',
      callbackUrl: location.origin
    };
    break;
  default:
    auth0Config = {
      domain: 'trimble-dev.auth0.com',
      clientId: 'g1IDu8I8rNZaBO8W0l8Bvztq0xzUSxsM',
      callbackUrl: location.origin
    };
}

export const AUTH_CONFIG = auth0Config;

export const AUTHLOCK_OPTIONS = {
  additionalSignUpFields: [
    {
      name: 'firstName',
      placeholder: 'First name'
    },
    {
      name: 'lastName',
      placeholder: 'Last name'
    }
  ],
  allowedConnections: ['facebook', 'google-oauth2', 'Username-Password-Authentication'],
  languageDictionary: {
    title: 'Video Intelligence',
    error: {
      login: {
        'lock.invalid_email_password': 'Wrong email or password. Sign up?',
        'lock.fallback': 'Wrong email or password. Sign up?'
      }
    }
  },
  container: 'login-widget',
  socialButtonStyle: 'small',
  oidcConformant: true,
  autoclose: true,
  closable: false,
  avatar: null,
  auth: {
    redirectUrl: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    params: {
      scope: 'openid email'
    }
  },
  theme: {
    primaryColor: '#2196F3',
    backgroundImage: PeopleNetBrandImage
  }
};
