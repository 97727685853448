/* eslint-disable import/prefer-default-export */
import withDashboardDownloadNotification from '../withDashboardDownloadNotification';
import {openPlayerPage} from '../../../library/LibraryActions';
import {downloadVideoDashboard} from '../../../downloadVideo/DownloadVideoActions';
import {getCameraViewName} from '../../util/NotificationUtil';

async function handleOpenClip() {
  const {data} = this.props;

  return this.props.openPlayerPage({
    mediaId: data.mediaId,
    camera: getCameraViewName(data)
  });
}

async function handleDownloadVideo() {
  const {data} = this.props;

  return this.props.downloadVideoDashboard(data.mediaId, data.clipId, data.systemMeasure);
}

export const DashboardDownloadRequestNotification = withDashboardDownloadNotification({
  variant: 'primary',
  icon: 'videocam',
  title: 'notification.dashboardDownloadRequest.title',
  description: 'notification.dashboardDownloadRequest.description',
  onClick: handleOpenClip,
  mapDispatchToProps: {
    openPlayerPage
  }
});

export const DashboardDownloadSuccessNotification = withDashboardDownloadNotification({
  variant: 'success',
  icon: 'get_app',
  title: 'notification.dashboardDownloadSuccess.title',
  description: 'notification.dashboardDownloadSuccess.description',
  onClick: handleDownloadVideo,
  mapDispatchToProps: {
    openPlayerPage,
    downloadVideoDashboard
  }
});

export const DashboardDownloadErrorNotification = withDashboardDownloadNotification({
  variant: 'error',
  icon: 'error_outline',
  title: 'notification.dashboardDownloadError.title',
  description: 'notification.dashboardDownloadError.description',
  onClick: handleOpenClip,
  mapDispatchToProps: {
    openPlayerPage
  }
});
