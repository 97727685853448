/* eslint-disable quotes */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import {Route, Redirect, Switch} from 'react-router';
import {I18n} from 'react-redux-i18n';
import {ConnectedRouter} from 'connected-react-router';
import {connect} from 'react-redux';
import moment from 'moment';
import {get} from 'lodash';
import * as qs from 'query-string';
import ProtectedRoute from '../app/ProtectedRoute';
import LinkAcccount from '../linkAccount/component/LinkAcccount';
import App from '../containers/App';
import AdminPage from '@peoplenet/vi-components-ui/src/admin';
import Library from '../library/container/Library';
import Player from '../player/container/Player';
import OnDemand from '../onDemand/container/OnDemand';
import Report from '@peoplenet/vi-components-ui/src/report/container/Report';
import DVRhealth from '@peoplenet/vi-components-ui/src/DVRhealth/container/DVRhealthPage';
import auth from '../components/Auth/Auth';
import trimbleAuth from '../components/Auth/TrimbleId';
import history from '@peoplenet/vi-components-ui/src/app/history';
import Login from '../containers/Login';
import NotificationObserver from '../notification/NotificationObserver';
import TrimbleIdLogin from '../containers/TrimbleIdLogin';
import DashboardPage from '../dashboard';
import UserSettings from '@peoplenet/vi-components-ui/src/UserSettings/container/UserSettings';
import Spinner from '../containers/Spinner';
import SignupVerification from '../containers/SignUpVerification';
import {loadUserInformation, login} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import getSettings from '@peoplenet/vi-components-ui/src/actions/customer';
import {getSystemSettings} from '../system/SystemActions';
import {getSettings as getUserSettings} from '@peoplenet/vi-components-ui/src/UserSettings/UserSettingsActions';

export class Root extends React.Component {
  componentWillMount() {
    if (this.isAuthenticated()) {
      this.props.loadUserInformation();
      this.props.getSystemSettings();
    }
  }

  componentWillReceiveProps(props) {
    const user = get(props, 'user', null);
    const isUserLoading = get(props, 'user.loading', false) === true;
    const isCustomerLoading = get(props, 'customer.loading', false) === true;
    const isSystemSettingsLoading = get(props, 'system.loading', false) === true;
    const userId = get(props, 'user.userInformation.userId', null);

    if (isUserLoading) {
      return;
    }

    if (isCustomerLoading) {
      return;
    }

    if (isSystemSettingsLoading) {
      return;
    }

    const systemSettings = get(props, 'system.settings', null);

    if (!systemSettings) {
      this.props.getSystemSettings();
    }

    const customerData = get(props, 'customer.data', null);
    const hasData = customerData !== null;

    const updatedAt = get(props, 'customer.updatedAt', 0);
    const shouldUpdate = moment().diff(updatedAt, 'seconds') >= 60;

    if (hasData && !shouldUpdate) {
      return;
    }

    const userSettingsLoaded = get(props, 'userSettings.isLoaded', false);
    if (!userSettingsLoaded &&
      user.userInformation.hasOwnProperty('userId')) {
      this.props.getUserSettings(userId);
    }

    if (user.userInformation.hasOwnProperty('customerId')) {
      this.props.getSettings();
    }
  }

  get renderRoot() {
    if (TRIMBLE_ID_FEATURE_TOGGLE) {
      return this.authFlow(trimbleAuth);
    }

    if (auth.isAuthenticated()) {
      return this.authFlow(auth);
    }

    return (
      <Route
        render={
          props => (
            <Login
              auth={auth}
              hash={props.location.hash}
              onLogin={this.props.login}
            />
          )
        }
      />
    );
  }

  isAuthenticated() {
    if (TRIMBLE_ID_FEATURE_TOGGLE) {
      return trimbleAuth.isAuthenticated();
    }

    return auth.isAuthenticated();
  }

  unverifiedTrimbleUser = () => {
    const pathname = window.location.pathname;
    const paramsParsed = qs.parse(window.location.search);
    const {uuid, onetimeCode} = paramsParsed;

    return pathname === '/signUpVerification' && uuid !== undefined && onetimeCode !== undefined;
  }

  authFlow = auth => {
    if (TRIMBLE_ID_FEATURE_TOGGLE && this.unverifiedTrimbleUser()) {
      return (
        <Switch>
          <Route
            exact
            path="/signUpVerification"
            render={props => <SignupVerification auth={auth} {...props} />}
          />
          <Redirect to="/signUpVerification" />
        </Switch>
      );
    }

    if (TRIMBLE_ID_FEATURE_TOGGLE && !trimbleAuth.isAuthenticated()) {
      return (
        <Route
          render={
            props => (
              <TrimbleIdLogin
                auth={trimbleAuth}
                hash={props.location.hash}
                onLogin={this.props.login}
              />
            )
          }
        />
      );
    }

    const {user} = this.props;
    const customerData = get(this.props, 'customer.data', null);

    if (!user.loading && !user.userInformation.emailVerified) {
      return (
        <Switch>
          <Route
            exact
            path="/signUpVerification"
            render={props => <SignupVerification auth={auth} {...props} />}
          />
          <Redirect to="/signUpVerification" />
        </Switch>
      );
    } else if (!user.loading && !user.userInformation.hasOwnProperty('customerId')) {
      return (
        <Switch>
          <Route
            exact
            path="/linkAcccount"
            render={props => <LinkAcccount {...props} onLink={this.handleOnLink} />}
          />
          <Redirect to="/linkAcccount" />
        </Switch>
      );
    } else if (!user.loading) {
      if (customerData === null) {
        return (
          <Route
            render={
              props => (
                <Spinner auth={auth} {...props} />
              )
            }
          />
        );
      }

      return (
        <>
          <NotificationObserver />

          <Switch>
            <Route exact path="/library" render={props => <Library auth={auth} {...props} />} />
            <Route exact path="/player" render={props => <Player auth={auth} {...props} />} />
            <ProtectedRoute
              exact
              path="/onDemand"
              component={OnDemand}
              user={user}
              roles={[
                'changeCustomer',
                'videoIntelligenceManager',
                'videoIntelligenceAdmin',
                'videoIntelligenceInternal'
              ]}
              title={I18n.t('page.onDemand.title')}
            />
            <ProtectedRoute
              disabledOnMobile
              exact
              path="/admin"
              component={AdminPage}
              user={user}
              roles={[
                'changeCustomer',
                'videoIntelligenceAdmin',
                'videoIntelligenceInternal'
              ]}
              title={I18n.t('page.admin.title')}
            />
            <Route exact path="/dashboard" render={props => <DashboardPage auth={auth} {...props} />} />
            <ProtectedRoute 
              exact 
              path="/dvr-health" 
              component={DVRhealth}
              user={user}
              roles={[
                'changeCustomer',
                'videoIntelligenceManager',
                'videoIntelligenceAdmin',
                'videoIntelligenceInternal'
              ]}
              title={I18n.t('page.dvrHealth.title')}
            />
            <ProtectedRoute
              disabledOnMobile
              exact
              path="/reports"
              component={Report}
              user={user}
              roles={[
                'changeCustomer',
                'videoIntelligenceManager',
                'videoIntelligenceAdmin',
                'videoIntelligenceInternal'
              ]}
              title={I18n.t('page.reports.title')}
            />
            <ProtectedRoute
              disabledOnMobile
              exact
              path="/settings"
              component={UserSettings}
              user={user}
              title={I18n.t('page.userSettings.title')}
            />
            <Redirect exact from="/linkAcccount" to="/library" />
            <Redirect exact from="/signUpVerification" to="/linkAcccount" />
            <Route path="/" render={props => <App auth={auth} {...props} />} />
          </Switch>
        </>
      );
    }

    // It is loading user information
    return (
      <Route
        render={
          props => (
            <Spinner auth={auth} {...props} />
          )
        }
      />
    );
  }

  handleOnLink = () => {
    this.props.loadUserInformation();
    this.props.getSettings();
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        {this.renderRoot}
      </ConnectedRouter>
    );
  }
}

export const mapStateToProps = state => {
  return {user: {...state.user}, ...state};
};

export const mapDispatcherToProps = dispatcher => ({
  loadUserInformation: () => dispatcher(loadUserInformation()),
  login: authData => {
    dispatcher(login(authData));
    dispatcher(loadUserInformation());
  },
  getSettings: () => dispatcher(getSettings()),
  getSystemSettings: () => dispatcher(getSystemSettings()),
  getUserSettings: (userId) => dispatcher(getUserSettings(userId))
});

export default connect(mapStateToProps, mapDispatcherToProps)(Root);
