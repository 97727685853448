/* eslint no-underscore-dangle: 0 */

import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import * as qs from 'query-string';
import {get, size, cloneDeep} from 'lodash';
import ReactGA from 'react-ga';
import DvrTypes from '@peoplenet/vi-components-ui/src/enum/DvrTypes';
import {forwardSpeedMax, forwardDistanceLimit, initialForwardDistance, dvrUnknownSpeed} from '@peoplenet/vi-components-ui/src/util/DvrConstants';

import {isMobile} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import MobilePlayer from './MobilePlayer';
import DesktopPlayer from './DesktopPlayer';
import {flagVideo as onFlagVideo} from '../../flagVideo/FlagVideoActions';
import {openLibraryPage, getSettings, getMedia, searchReview, getReview, getCoach, getComments, postComment, deleteComment, updateFlag} from '../../actions';
import {searchMedias, clear as clearLibrary} from '../../library/LibraryActions';
import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';
import {clear, openMedia, saveLibraryView} from '../../actions/player';

const FLOWPLAYER_KEY = '$707785842659764';

const LIBRARY_VIEW = {
  pageSize: 10
};

class Player extends React.Component {
  constructor(props) {
    super(props);
    const {location: {search}} = this.props;
    const paramsParsed = qs.parse(search);
    this.state = {
      previusHeaderVisible: true,
      media: {},
      tableWidth: 0,
      mediaId: paramsParsed.mediaId,
      camera: paramsParsed.camera,
      page: 0,
      customer: null,
      clip: null,
      textSearch: '',
      reviewSort: 'mediaDate:desc',
      playerSecond: 0,
      selectedTab: {
        isVideoDetails: false,
        isDashboard: true,
        isMap: false,
        isComment: false
      },
      libraryView: {
        hasMorePages: false,
        hasLessPages: false,
        isLoading: false,
        to: null,
        from: null
      },
      tabIndex: paramsParsed.tabIndex || 0,
      subTabIndex: paramsParsed.subTabIndex || 0,
      commentText: '',
      mediaLoaded: false
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated()) {
      this.props.logout();
    } else if (this.props.isMobile || this.props.userSettings.isLoaded) {
      this.loadMedia();
    }
  }

  componentWillReceiveProps = nextProps => {
    if (!nextProps.isMobile && nextProps.userSettings.isLoaded && !this.state.mediaLoaded) {
      this.loadMedia();
    } else {
      if (nextProps.location.key !== this.props.location.key) {
        const nextParamsParsed = qs.parse(nextProps.location.search);
        this.setState({
          mediaId: nextParamsParsed.mediaId,
          camera: nextParamsParsed.camera,
          tabIndex: 0,
          subTabIndex: 0
        }, this.loadMedia);
        return;
      }

      const paramsParsed = qs.parse(this.props.location.search);
      if (nextProps.media != null) {
        let clip = this.getClipChannel(this.state.camera, nextProps.media);
        if (!clip) {
          clip = this.getFirstClipChannel(nextProps.media);
        }
        // TODO: remove this once ALL mosaic views has thumbnails
        if (!clip.thumbURL) {
          const firstChannel = this.getFirstClipChannel(nextProps.media);
          clip.thumbURL = firstChannel.thumbURL;
        }

        const perSecondDataParsed = this.getPerSecondData(nextProps.media);
        const captureStartDate = nextProps.media.captures[0].startDate;
        const captureEndDate = nextProps.media.captures[0].endDate;
        const machineType = get(nextProps, 'media.metadata.machineType', 0);

        this.setState({
          media: nextProps.media,
          clip,
          perSecondData: perSecondDataParsed,
          captureStartDate,
          captureEndDate,
          machineType,
          tabIndex: parseInt(paramsParsed.tabIndex || this.state.tabIndex || 0, 10),
          subTabIndex: parseInt(paramsParsed.subTabIndex || this.state.subTabIndex || 0, 10)
        });
      }

      if (this.state.customer === null) {
        this.setState({
          customer: nextProps.customer
        });
      }
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if ((!previousProps.media && this.props.media)
        || (previousProps.media && this.props.media
          && previousProps.media.mediaId !== this.props.media.mediaId)
    ) {
      this.onSearchReview({resetState: true});
    }

    if (this.props.media !== null) {
      this.updateLibraryView(previousProps, previousState);
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  loadMedia = () => {
    if (!this.state.mediaId) {
      this.props.history.goBack();
    } else {
      this.setState({mediaLoaded: true}, () => {
        this.props.getMedia({
          mediaId: this.state.mediaId,
          systemMeasure: this.props.userSettings.settings.systemMeasure
        });
        this.props.getReview({
          mediaId: this.state.mediaId
        });
        this.props.getCoach({
          mediaId: this.state.mediaId
        });
        this.props.getComments(this.state.mediaId);
        const mediaList = get(this.props.library, 'mediaList', []);
        if (mediaList.length > 0) {
          this.props.clearLibrary();
        }
      });
    }
  }

  onChangeCommentText = ({target: {value}}) => {
    this.setState({commentText: value});
  }

  getDvrType() {
    const machineType = get(this.props, 'media.metadata.machineType', 0);
    return DvrTypes.forMachineType(machineType);
  }

  getChannelNumber = playerCamera => {
    return this.getDvrType().getChannelNumber(playerCamera);
  }

  getClipChannel = (playerCamera, media = this.props.media) => {
    if (!media) {
      return null;
    }

    const channelNumber = this.getChannelNumber(playerCamera);

    if (!channelNumber) {
      return null;
    }

    const {clips} = media.captures[0];
    return clips.find(clip => clip.channel === channelNumber) || null;
  }

  getFirstClipChannelId = _media => {
    const clip = this.getFirstClipChannel(_media);
    if (clip) {
      return clip.channel;
    }
    return 1;
  }

  getFirstClipChannel = _media => {
    const media = _media || this.props.media;
    if (media) {
      return media.captures[0].clips[0];
    }
    return null;
  }

  getPerSecondData = _media => {
    const media = _media || this.props.media;
    const perSecondData = get(media, 'relatedItems.oerEvent.mainData.perSecondData');
    const {systemMeasure} = this.props.userSettings.settings;

    if (size(perSecondData) > 0) {
      const firstSecond = perSecondData[0];
      let usingTime = null;
      if (firstSecond.gpsTime && (Math.abs(firstSecond.gpsTime - firstSecond.rtcTime) < 20000)) {
        usingTime = 'gps';
      } else {
        usingTime = 'rtc';
      }

      const perSecond = media.relatedItems.oerEvent.mainData.perSecondData;
      const forwardCollision = media.relatedItems.oerEvent.forwardCollisionData;
      const accelerationData = media.relatedItems.oerEvent.configAccelerationData;
      const {speedLimit} = media.relatedItems;

      const video = {
        duration: media.captures[0].durationSeconds,
        start: parseInt(media.captures[0].startDate, 10),
        end: parseInt(media.captures[0].endDate, 10)
      };

      const perSecondParsed = {};
      const DVR_UNKNOWN_SPEED = dvrUnknownSpeed(systemMeasure);
      perSecond.forEach((e, i) => {
        const key = (usingTime === 'gps' ? e.gpsTime : e.rtcTime);
        const currentSecond = (key - video.start) / 1000;
        const obj = {
          video,
          isLegacy: false,
          time: {
            value: key,
            type: usingTime,
            second: currentSecond
          },
          rpm: (e.rpm === 16383.75 ? null : e.rpm),
          cruiseStatBrake: e.cruiseStatBrake,
          cruiseStatClutch: e.cruiseStatClutch,
          cruiseStatActive: e.cruiseStatActive,
          gpsSpeed: (e.gpsSpeed === DVR_UNKNOWN_SPEED ? null : e.gpsSpeed),
          j1708Speed: (e.j1708Speed === DVR_UNKNOWN_SPEED ? null : e.j1708Speed),
          forwardSpeed: null,
          forwardDistance: null,
          turnLightLeft: 'OFF',
          turnLightRight: 'OFF',
          location: e.location,
          gpsHeading: e.gpsHeading,
          rtcTime: e.rtcTime,
          gpsTime: e.gpsTime,
          tCount: e.tCount,
          speedLimit: null,
          accelerationPedal: null
        };

        if(e.accelerationPedal != null){
          if (!Number.isNaN(e.accelerationPedal)) {
            obj.accelerationPedal = parseFloat(e.accelerationPedal.toFixed(2));
          }
        }

        if (forwardCollision != null && forwardCollision.forwardCollisionEvents[i] != null) {
          // forward distance
          const FORWARD_DISTANCE_LIMIT = forwardDistanceLimit(systemMeasure);
          if (forwardCollision.forwardCollisionEvents[i].distance < FORWARD_DISTANCE_LIMIT) {
            const FORWARD_COLLISION_INITIAL = initialForwardDistance(systemMeasure);
            obj.forwardDistance = forwardCollision.forwardCollisionEvents[i].distance;
            const distance = ((obj.forwardDistance * 100) / FORWARD_COLLISION_INITIAL).toFixed(2);
            let percent = parseFloat(distance) - 100;
            if (percent >= 0) {
              percent = 0;
            } else {
              percent = Math.abs(percent);
            }
            obj.forwardDistance = obj.forwardDistance;
            obj.forwardDistancePercent = percent;
          }
          // forward speed
          if (forwardCollision.forwardCollisionEvents[i].speed < FORWARD_DISTANCE_LIMIT) {
            const FORWARD_SPEED_MAX = forwardSpeedMax(systemMeasure);
            obj.forwardSpeed = forwardCollision.forwardCollisionEvents[i].speed;
            obj.forwardSpeedPercent = Math.abs((obj.forwardSpeed * 100) / FORWARD_SPEED_MAX);
          }
        }

        if (accelerationData != null && accelerationData.accelerometerEvents[i] != null) {
          if (accelerationData.accelerometerEvents[i].turnLight1Enabled != null) {
            obj.turnLightLeft = accelerationData.accelerometerEvents[i].turnLight1Enabled;
          }
          if (accelerationData.accelerometerEvents[i].turnLight2Enabled != null) {
            obj.turnLightRight = accelerationData.accelerometerEvents[i].turnLight2Enabled;
          }
        }
        let speedLimitFound = [];
        if (speedLimit != null) {
          speedLimitFound = speedLimit.filter(o => o.tCount === obj.tCount);
        }

        if (speedLimitFound.length > 0) {
          obj.speedLimit = speedLimitFound[0].maxSpeed;
        }

        perSecondParsed[key] = obj;
      });

      return perSecondParsed;
    }

    return null;
  }

  handleCameraChange = channel => {
    const clip = this.getClipChannel(channel);
    this.setState({clip, camera: channel});
    this.handleGA(channel);
  }

  handleClickVideo = () => {
    this.setState({
      selectedTab: {
        isVideoDetails: true,
        isDashboard: false,
        isMap: false,
        isComment: false
      }
    });
  }

  handleClickDashboard = () => {
    this.setState({
      selectedTab: {
        isVideoDetails: false,
        isDashboard: true,
        isMap: false,
        isComment: false
      }
    });
  }

  handleClickMap = () => {
    this.setState({
      selectedTab: {
        isVideoDetails: false,
        isDashboard: false,
        isMap: true,
        isComment: false
      }
    });
  }

  handleClickComment = () => {
    this.setState({
      selectedTab: {
        isVideoDetails: false,
        isDashboard: false,
        isMap: false,
        isComment: true
      }
    });
  }

  handlePlayerTimeChanged = second => {
    this.setState({playerSecond: second});
  }

  handleTagSuccess = () => {
    if (this.props.coach && this.props.coach.completed) {
      setTimeout(() => {
        this.props.getCoach({
          mediaId: this.state.mediaId
        });
      }, 450);
    }
    if (this.props.review && this.props.review.completed) {
      setTimeout(() => {
        this.props.getReview({
          mediaId: this.state.mediaId
        });
      }, 450);
    }
  }

  handleGA = cameraSelected => {
    ReactGA.event({
      category: 'Player',
      action: cameraSelected,
      label: 'Top Header'
    });
  }

  onFlagVideo = (mediaId, flagged, trackInfo) => {
    const currentMediaId = get(this.state, 'media.mediaId', null);

    this.props.onFlagVideo(mediaId, flagged);
    if (currentMediaId === mediaId) {
      this.props.updateFlag(flagged);
    }

    ReactGA.event(trackInfo);
  }

  handleOnClickBack = () => {
    this.props.openLibraryPage();
  }

  handleChangeTab = (event, value) => {
    this.setState({tabIndex: value});
  }

  handleChangeTabIndex = index => {
    this.setState({tabIndex: index});
  }

  onChanteTextSearch = ({target: {value: textSearch}}) => this.setState({textSearch});

  onChangePage = (page, callBack) => {
    this.setState({page, tabIndex: 2, subTabIndex: 2}, () => this.onSearchReview({callBack}));
  }

  onSearchReview = ({callBack = () => {}, resetState = false}) => {
    const {page} = this.state;
    if (resetState) {
      this.setState({page: 0});
    }
    const {
      previouslyReviews,
      media: {
        mediaId: mustnot,
        metadata: {
          driverID1: driver1,
          driverID2: driver2,
          customerId: cid
        }
      }
    } = this.props;
    const params = {
      cid,
      mustnot,
      page: resetState ? 0 : page,
      pageSize: 15,
      sort: this.state.reviewSort,
      text: this.state.textSearch,
      startTime: moment().subtract(180, 'days').valueOf()
    };
    if (driver1) {
      params.driver1 = driver1;
    }
    if (driver2) {
      params.driver2 = driver2;
    }
    this.props.searchReview(params, resetState ? [] : previouslyReviews, callBack);
  }

  onChangeReviewSort = (field, direction, callBack) => {
    this.setState({reviewSort: `${field}:${direction}`}, () => this.onSearchReview({resetState: true, callBack}));
  }

  isSameSortField = field => {
    const {reviewSort} = this.state;

    return reviewSort.split(':')[0] === field;
  }

  getSortFieldDirection = field => {
    const {reviewSort} = this.state;
    return this.isSameSortField(field) ? `${reviewSort.split(':')[1]}` : null;
  }

  handleOpenVideo = mediaId => camera => {
    const {libraryView} = this.state;
    const mediaList = get(this.props.library, 'mediaList', []);
    const index = mediaList.findIndex(m => m.mediaId === mediaId);
    libraryView.index = index + libraryView.from + 1;
    this.setState({libraryView}, () => {
      this.props.saveLibraryView({mediaId, index: libraryView.index});
      this.props.openMedia({mediaId, camera});
    });
  }

  handleLoadLibraryView = () => {
    const {
      libraryFilters: {filters},
      library: {saved}
    } = this.props;
    const {pageSize} = LIBRARY_VIEW;
    const {libraryView, mediaId} = this.state;
    const _filters = cloneDeep(filters);

    let recovery = null;
    if (libraryView.index) {
      recovery = {
        from: libraryView.from,
        to: libraryView.to,
        index: libraryView.index,
        hasLessPages: libraryView.from >= pageSize
      };
      _filters.from = libraryView.from;
      _filters.to = libraryView.to;
    } else {
      // loading media index from library
      let index = get(saved, 'index', 0) + 1;
      if (saved.mediaId !== mediaId) {
        index = 1;
      }
      // loading media index from localstorage in case the page is refreshed
      const _media = get(this.props, 'libraryView.mediaId', null);
      if (_media && _media === mediaId) {
        const _index = get(this.props, 'libraryView.index', 1);
        index = _index;
      }

      const page = (index <= pageSize) ? 0 : Math.floor((index - 1) / pageSize);
      _filters.from = ((page + 1) - 1) * pageSize;
      _filters.to = _filters.from + pageSize;

      recovery = {
        from: _filters.from,
        to: _filters.to,
        index,
        hasLessPages: _filters.from >= pageSize
      };
    }

    const lv = {
      ...libraryView,
      ...recovery,
      mediaId
    };

    this.setState({libraryView: lv}, () => {
      this.props.saveLibraryView({mediaId, index: lv.index});
      this.props.searchMedias(this.getSearchParams(_filters));
    });
  }

  handleScrollLeftLibraryView = () => {
    const {pageSize} = LIBRARY_VIEW;
    const {libraryView} = this.state;
    if (libraryView.hasLessPages && !libraryView.isLoading) {
      const {libraryFilters: {filters}} = this.props;
      const _filters = cloneDeep(filters);

      _filters.from = libraryView.from - pageSize;
      _filters.to = libraryView.from;
      _filters.invertConcat = true;

      const page = ((libraryView.to - _filters.from) / pageSize) - 1;
      _filters.overwritePage = page;

      libraryView.from = _filters.from;
      this.setState({libraryView}, () => {
        this.props.searchMedias(this.getSearchParams(_filters));
      });
    }
  }

  handleScrollRightLibraryView = () => {
    const {pageSize} = LIBRARY_VIEW;
    const {libraryView} = this.state;
    if (libraryView.hasMorePages && !libraryView.isLoading) {
      const {libraryFilters: {filters}} = this.props;
      const _filters = cloneDeep(filters);

      _filters.from = libraryView.to;
      _filters.to = _filters.from + pageSize;
      _filters.invertConcat = false;

      const page = ((_filters.to - libraryView.from) / pageSize) - 1;
      _filters.overwritePage = page;

      libraryView.to = _filters.to;
      this.setState({libraryView}, () => {
        this.props.searchMedias(this.getSearchParams(_filters));
      });
    }
  }

  onLoadReview = mediaId => {
    this.setState({mediaId, page: 0}, () => {
      this.props.getMedia({
        mediaId: this.state.mediaId
      });
      this.props.getReview({
        mediaId: this.state.mediaId
      });
      this.props.getCoach({
        mediaId: this.state.mediaId
      });
      this.props.getComments(this.state.mediaId);
    });
  }

  onChangeHeaderVisibility = ({visible, width}) => {
    this.setState({previusHeaderVisible: visible, tableWidth: width});
  }

  getSearchParams = _terms => {
    const terms = _terms;

    const useTerminal = get(this.props, 'customer.data.settings.useTerminal', false);
    if (useTerminal) {
      terms.terminalRestriction = true;
    }

    terms.groupName = false;
    terms.perSecondData = false;
    terms.summary = true;
    terms.isInternal = this.props.isInternalSalesUser;
    terms.isAdmin = this.props.isAdmin;
    return terms;
  }

  updateLibraryView = ({library}) => {
    const prevMorePages = get(library, 'hasMorePages', false);
    const prevLoading = get(library, 'isLoading', false);
    const hasMorePages = get(this.props.library, 'hasMorePages', false);
    const isLoading = get(this.props.library, 'isLoading', false);

    const {libraryView: {from}} = this.state;
    const lv = {
      ...this.state.libraryView,
      hasMorePages,
      isLoading,
      hasLessPages: (from > 0)
    };

    if (hasMorePages !== prevMorePages ||
      isLoading !== prevLoading) {
      this.setState({libraryView: lv});
    }
  }

  render() {
    const {
      library,
      media,
      review,
      coach
    } = this.props;
    const {previusHeaderVisible, tableWidth, libraryView} = this.state;
    const showMedia = (this.props.isLoadingTop ? false : !this.props.errorMessage);

    const bottomNavOptions = {
      show: showMedia,
      events: {
        onClickVideo: this.handleClickVideo,
        onClickDashboard: this.handleClickDashboard,
        onClickMap: this.handleClickMap,
        onClickComments: this.handleClickComment
      }
    };

    const triggerType = get(media, 'metadata.triggerType', '').toLowerCase();
    const isOnDemand = (triggerType === 'ondemand' || triggerType === 'fetchrequest');

    const mapOptions = {
      show: this.state.selectedTab.isMap && showMedia,
      isMobile: this.props.isMobile
    };

    const mediaList = get(library, 'mediaList', []);

    const libraryViewOptions = {
      list: mediaList,
      mediaId: libraryView.mediaId,
      isLoading: libraryView.isLoading,
      hasMorePages: libraryView.hasMorePages,
      hasLessPages: libraryView.hasLessPages,
      events: {
        onLoad: this.handleLoadLibraryView,
        onOpenVideo: this.handleOpenVideo,
        onScrollLeft: this.handleScrollLeftLibraryView,
        onScrollRight: this.handleScrollRightLibraryView
      }
    };

    const mediaDetailsOptions = {
      show: this.state.selectedTab.isVideoDetails,
      events: {
        onTagSuccess: this.handleTagSuccess
      }
    };
    const props = {
      ...this.props,
      ...this.state,
      flowplayerKey: FLOWPLAYER_KEY,
      showMedia,
      handleOnClickBack: this.handleOnClickBack,
      handlePlayerTimeChanged: this.handlePlayerTimeChanged,
      handleChangeTab: this.handleChangeTab,
      handleChangeTabIndex: this.handleChangeTabIndex,
      onPlayerTimeChanged: this.handlePlayerTimeChanged,
      onCameraChange: this.handleCameraChange,
      onChangeCommentText: this.onChangeCommentText,
      mapOptions,
      bottomNavOptions,
      mediaDetailsOptions,
      libraryViewOptions,
      media,
      review,
      coach,
      isOnDemand
    };
    if (this.props.isMobile) {
      return (<MobilePlayer
        {...props}
        subTabIndex={this.state.subTabIndex}
        onFlagVideo={this.onFlagVideo}
        currentPage={this.state.page}
        textSearch={this.state.textSearch}
        onChanteTextSearch={this.onChanteTextSearch}
        onChangePage={this.onChangePage}
        onChangeReviewSort={this.onChangeReviewSort}
        onLoadReview={this.onLoadReview}
        getSortFieldDirection={this.getSortFieldDirection}
        previusHeaderVisible={previusHeaderVisible}
        tableWidth={tableWidth}
        onChangeHeaderVisibility={this.onChangeHeaderVisibility}
        isSameSortField={this.isSameSortField}
        onSearchReview={this.onSearchReview}
      />);
    }
    return (
      <DesktopPlayer
        {...props}
        camera={this.state.camera}
        currentPage={this.state.page}
        subTabIndex={this.state.subTabIndex}
        previusHeaderVisible={previusHeaderVisible}
        tableWidth={tableWidth}
        getSortFieldDirection={this.getSortFieldDirection}
        onChangeHeaderVisibility={this.onChangeHeaderVisibility}
        onChangeReviewSort={this.onChangeReviewSort}
        onLoadReview={this.onLoadReview}
        isSameSortField={this.isSameSortField}
        textSearch={this.state.textSearch}
        onChangePage={this.onChangePage}
        onChanteTextSearch={this.onChanteTextSearch}
        onSearchReview={this.onSearchReview}
        onFlagVideo={this.onFlagVideo}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    player,
    customer,
    user,
    library,
    libraryFilters,
    videoTagging,
    userSettings
  } = state;

  const canExtendVideo = hasAccess([
    'changeCustomer',
    'videoIntelligenceManager',
    'videoIntelligenceAdmin'
  ]);

  const isInternalUser = hasAccess([
    'changeCustomer',
    'videoIntelligenceInternal'
  ]);

  const isInternaPeoplelUser = hasAccess([
    'changeCustomer'
  ]);
  const isInternalSalesUser = hasAccess([
    'videoIntelligenceInternal'
  ]);
  const isAdmin = hasAccess([
    'videoIntelligenceAdmin'
  ]);

  return {
    ...player,
    customer,
    library,
    libraryFilters,
    tag: videoTagging,
    canExtendVideo,
    isInternalUser,
    isInternaPeoplelUser,
    isInternalSalesUser,
    isAdmin,
    user,
    isMobile: isMobile(state),
    userInformation: {...state.user.userInformation},
    userSettings
  };
}

export default connect(mapStateToProps, {
  openLibraryPage,
  openMedia,
  saveLibraryView,
  getSettings,
  getMedia,
  updateFlag,
  searchReview,
  searchMedias,
  clearLibrary,
  getReview,
  getCoach,
  logout,
  clear,
  getComments,
  postComment,
  deleteComment,
  onFlagVideo
})(Player);
