import moment from 'moment';
import {get, find, filter, merge} from 'lodash';
import {
  CONSTRAINED_FILTERS,
  FILTER_SEARCH_REQUEST,
  FILTER_SEARCH_SUCCESS,
  FILTER_SEARCH_FAIL,
  FILTER_UPDATE_CHIP,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_SUCCESS,
  LOAD_GROUPS_FAIL,
  LOAD_TERMINALS_REQUEST,
  LOAD_TERMINALS_SUCCESS,
  LOAD_TERMINALS_FAIL,
  LOAD_OER_REQUEST,
  LOAD_OER_SUCCESS,
  LOAD_OER_FAIL,
  APPLY_FILTER,
  CLEAR_FILTER,
  UNMOUNT_FILTER,
  ADD_DRIVER_FILTER_CLICK,
  ADD_VEHICLE_FILTER_CLICK,
  ADD_TERMINAL_FILTER_CLICK,
  ADD_OER_FILTER_CLICK,
  SET_ON_DEMAND_FILTER
} from './Actions/filterActionTypes';

const FILTERS_KEY = 'filters';

const initialState = {
  constrainedFilters: {},
  groups: [],
  terminals: [],
  oerTypes: [],
  isLoading: false,
  errorMessage: false,
  filters: {
    fetched: 'oeronly',
    startTime: moment().subtract(180, 'days').startOf('day').valueOf(),
    endTime: moment().subtract(1, 'days').endOf('day').valueOf(),
    chipsFilter: []
  },
  selectedFilters: {},
  chipsFilter: []
};

const saveFilter = ({filters, selectedFilters, chipsFilter}) => {
  localStorage.setItem(FILTERS_KEY, JSON.stringify({filters, selectedFilters, chipsFilter}));
};

const initializeState = () => {
  const savedFilters = localStorage.getItem(FILTERS_KEY);
  if (savedFilters) {
    try {
      const parsed = JSON.parse(savedFilters);
      const {filters} = initialState;
      const {
        startTime,
        endTime,
        trigger,
        terminal
      } = parsed.filters;

      if (startTime) {
        filters.startTime = startTime;
      }
      if (endTime) {
        filters.endTime = endTime;
      }
      if (terminal) {
        filters.terminal = terminal;
      }
      if (trigger) {
        filters.trigger = trigger;
      }
      return {
        ...initialState,
        ...parsed,
        filters,
        chipsFilter: get(parsed, 'selectedFilters.chipsFilter', []),
        constrainedFilters: get(parsed, 'selectedFilters.constrainedFilters', {})
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  return initialState;
};

const handleFilterChips = (chip, chips = []) => {
  if (find(chips, c => c.name === chip.name && c.value === chip.value)) {
    return chips;
  }
  return [...filter(chips, it => it.name !== chip.name), chip];
};

export default function filterBy(state = initializeState(), action) {
  const type = get(action, 'type');
  const {filters, selectedFilters} = action || {};
  const {groups, terminals, oerTypes} = state || {};
  let newState = {...state};
  switch (type) {
    case CONSTRAINED_FILTERS:
      return {...state, constrainedFilters: get(action, 'payload', initialState.constrainedFilters)};
    case FILTER_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        driversVehiclesList: get(action, 'payload', initialState.driversVehiclesList)
      };
    case FILTER_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        driversVehiclesList: get(action, 'payload', initialState.driversVehiclesList)
      };
    case FILTER_UPDATE_CHIP:
      return {
        ...state,
        driversVehiclesList: initialState.driversVehiclesList,
        chipsFilter: action.payload
      };
    case FILTER_SEARCH_FAIL:
      return {...state, isLoading: false, errorMessage: true};
    case LOAD_GROUPS_REQUEST:
      return {...state, isLoading: true};
    case LOAD_GROUPS_SUCCESS:
      return {...state, groups: get(action, 'payload', initialState.groups)};
    case LOAD_GROUPS_FAIL:
      return {...state, isLoading: false, errorMessage: true};
    case LOAD_TERMINALS_REQUEST:
      return {...state, isLoading: true};
    case LOAD_TERMINALS_SUCCESS:
      return {...state, terminals: get(action, 'payload', initialState.terminals)};
    case LOAD_TERMINALS_FAIL:
      return {...state, isLoading: false, errorMessage: true};
    case LOAD_OER_REQUEST:
      return {...state, isLoading: true};
    case LOAD_OER_SUCCESS:
      return {...state, isLoading: false, oerTypes: get(action, 'payload', initialState.oerTypes)};
    case LOAD_OER_FAIL:
      return {...state, isLoading: false, errorMessage: true};
    case APPLY_FILTER:
      saveFilter(action);
      if (!filters.startTime) {
        filters.startTime = initialState.filters.startTime;
      }
      if (!filters.endTime) {
        filters.endTime = initialState.filters.endTime;
      }
      return {...state, filters, selectedFilters};
    case CLEAR_FILTER:
      localStorage.removeItem(FILTERS_KEY);
      return {
        ...state,
        ...initialState,
        groups,
        terminals,
        oerTypes,
        filters: initialState.filters,
        selectedFilters: initialState.selectedFilters
      };
    case UNMOUNT_FILTER:
      return {
        ...state,
        ...initialState,
        filters: state.filters,
        selectedFilters: state.selectedFilters
      };
    case ADD_DRIVER_FILTER_CLICK:
      newState = {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          chipsFilter: handleFilterChips({
            name: 'driver',
            value: action.payload.driverId,
            label: action.payload.label
          }, state.selectedFilters.chipsFilter)
        },
        chipsFilter: handleFilterChips({
          name: 'driver',
          value: action.payload.driverId,
          label: action.payload.label
        }, state.selectedFilters.chipsFilter),
        filters: {
          ...state.filters,
          driver: action.payload.driverId
        }
      };
      saveFilter(newState);
      return newState;
    case ADD_VEHICLE_FILTER_CLICK:
      newState = {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          chipsFilter: handleFilterChips({
            name: 'vehicle',
            value: action.payload.vehicle,
            label: action.payload.vehicle
          }, state.selectedFilters.chipsFilter)
        },
        chipsFilter: handleFilterChips({
          name: 'vehicle',
          value: action.payload.vehicle,
          label: action.payload.vehicle
        }, state.selectedFilters.chipsFilter),
        filters: {
          ...state.filters,
          vehicle: action.payload.vehicle
        }
      };
      saveFilter(newState);
      return newState;
    case ADD_TERMINAL_FILTER_CLICK:
      newState = {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          terminalsFilter: [action.payload.terminal]
        },
        filters: {
          ...state.filters,
          terminal: [action.payload.terminal]
        }
      };
      saveFilter(newState);
      return newState;
    case ADD_OER_FILTER_CLICK:
      newState = {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          oerTypeFilter: [action.payload.oer]
        },
        filters: {
          ...state.filters,
          trigger: [action.payload.oer]
        }
      };
      saveFilter(newState);
      return newState;
    case SET_ON_DEMAND_FILTER:
      newState = {
        ...state,
        constrainedFilters: {
          ...state.constrainedFilters,
          onDemand: action.payload
        },
        selectedFilters: {
          ...state.selectedFilters,
          constrainedFilters: {
            ...get(state.selectedFilters, 'constrainedFilters', {}),
            onDemand: action.payload
          }
        },
        filters: {
          ...state.filters,
          fetched: action.payload
        }
      };
      saveFilter(newState);
      return newState;
    default:
      return state;
  }
}
