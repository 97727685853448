/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {get, set, map, isEqual} from 'lodash';
import {I18n} from 'react-redux-i18n';


class CoachingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coach: {
        completed: false
      }
    };
  }

  componentWillMount() {
    if (this.props.coach != null) {
      this.setState((state, props) => ({
        coach: props.coach
      }));
    }
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.coach != null) {
      const {coach} = this.state;
      set(coach, 'completed', nextProps.coach.completed);
      set(coach, 'id', nextProps.coach.id);

      if (!isEqual(get(this.props, 'coach.id', 0), get(nextProps, 'coach.id', 0))) {
        set(coach, 'saved', true);
      }

      this.setState({coach});
    }
  }

  handleChangeComplete = (ev, value) => {
    const {coach} = this.state;
    set(coach, 'completed', value);
    this.setState({coach});
  }

  handleChangeComment = ev => {
    this.props.onChangeComment(ev.target.value);
  }

  handleSubmit = () => {
    const media = get(this.props, 'player.media', {});
    const userInformation = get(this.props, 'user.userInformation', {});
    const {coach: {completed}} = this.state;
    const {coachingComment: comment} = this.props;
    this.props.onSubmit({completed, comment}, media, userInformation)
      .then(success => {
        this.setState({coach: {...this.state.coach, completed, saved: success}});
      });
  }

  renderReviewDescription = review => {
    if (review && review.score && review.score.filter(i => i.marked).length > 0) {
      return (
        map(review.score, (item, index) => item.marked && <p key={index}>{I18n.t(item.nameId, item.name)}</p>)
      );
    }
    return (<p>{I18n.t('page.playerPage.coachingForm.notReviewed')}</p>);
  }

  renderSavedMessage = () => {
    if (get(this.state, 'coach.saved', false)) {
      setTimeout(() => {
        const {coach} = this.state;
        set(coach, 'saved', false);
        this.setState({coach});
      }, 5000);
      return (
        <div className="reviewdesktop__feedbackmsg">
          <p>{I18n.t('page.playerPage.coachingForm.saved')}</p>
        </div>
      );
    }
    return null;
  }

  render() {
    const {coach} = this.state;
    const {
      review,
      coachingComment,
      isMobile,
      canSubmit
    } = this.props;

    return (
      <div className={classnames('reviewdesktop__tab-container', isMobile ? 'ismobile': null)}>
        <h2>{I18n.t('page.playerPage.coachingForm.review')}</h2>
        {this.renderReviewDescription(review)}
        <FormControl error aria-describedby="error-text" className="reviewdesktop__comments">
          <TextField id="coachComment" value={coachingComment} onChange={this.handleChangeComment} label={I18n.t('page.playerPage.coachingForm.comments')} margin="normal" type="text" fullWidth multiline />
        </FormControl>
        <div className="reviewdesktop__actions">
          {this.renderSavedMessage()}
          <label>
            {I18n.t('page.playerPage.coachingForm.coachingCompleted')}
            <Checkbox
              id="coachingCompleted"
              onChange={this.handleChangeComplete}
              checked={coach ? coach.completed : false}
              value="coaching complete"
            />
          </label>
          <Button
            id="submitCoachingComment"
            variant="contained"
            color="default"
            className="btn btn-primary"
            onClick={this.handleSubmit}
            disabled={!canSubmit}
            title={!canSubmit && I18n.t('defaults.noPermissionFunctionality')}
          >
            {I18n.t('page.playerPage.coachingForm.submit')}
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user, player} = state;
  return {user, player};
}

export default connect(mapStateToProps)(CoachingForm);
