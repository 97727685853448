export const SEARCH_VEHICLE_REQUEST = 'SEARCH_VEHICLE_REQUEST';
export const SEARCH_VEHICLE_SUCCESS = 'SEARCH_VEHICLE_SUCCESS';
export const SEARCH_VEHICLE_FAIL = 'SEARCH_VEHICLE_FAIL';
export const LOAD_CHANNELS_REQUEST = 'LOAD_CHANNELS_REQUEST';
export const LOAD_CHANNELS_SUCCESS = 'LOAD_CHANNELS_SUCCESS';
export const LOAD_CHANNELS_FAIL = 'LOAD_CHANNELS_FAIL';
export const CLEAR_CHANNELS = 'CLEAR_CHANNELS';
export const CLEAR_TABLES = 'CLEAR_TABLES';
export const CLEAR_TABLES_LIST = 'CLEAR_TABLES_LIST';
export const GET_TOTAL_REQUEST = 'GET_TOTAL_REQUEST';
export const GET_TOTAL_SUCCESS = 'GET_TOTAL_SUCCESS';
export const GET_TOTAL_FAIL = 'GET_TOTAL_FAIL';
export const FETCH_ASYNC_REQUEST = 'FETCH_ASYNC_REQUEST';
export const FETCH_ASYNC_SUCCESS = 'FETCH_ASYNC_SUCCESS';
export const FETCH_ASYNC_FAIL = 'FETCH_ASYNC_FAIL';
export const RESET = 'ON_DEMAND/RESET';
export const DVR_SCAN_VIDEO_REQUEST = 'DVR_SCAN_VIDEO_REQUEST';
export const DVR_SCAN_VIDEO_SUCCESS = 'DVR_SCAN_VIDEO_SUCCESS';
export const DVR_SCAN_VIDEO_FAIL = 'DVR_SCAN_VIDEO_FAIL';
export const GET_QUEUE_LIST_REQUEST = 'GET_QUEUE_LIST_REQUEST';
export const GET_QUEUE_LIST_SUCCESS = 'GET_QUEUE_LIST_SUCCESS';
export const GET_QUEUE_LIST_FAIL = 'GET_QUEUE_LIST_FAIL';
export const GET_PREVIOUSLY_QUEUE_LIST_REQUEST = 'GET_PREVIOUSLY_QUEUE_LIST_REQUEST';
export const GET_PREVIOUSLY_QUEUE_LIST_SUCCESS = 'GET_PREVIOUSLY_QUEUE_LIST_SUCCESS';
export const GET_PREVIOUSLY_QUEUE_LIST_FAIL = 'GET_PREVIOUSLY_QUEUE_LIST_FAIL';
export const RETRY_ONDEMAND_MEDIA_REQUEST = 'RETRY_ONDEMAND_MEDIA_REQUEST';
export const RETRY_ONDEMAND_MEDIA_SUCCESS = 'RETRY_ONDEMAND_MEDIA_SUCCESS';
export const RETRY_ONDEMAND_MEDIA_FAIL = 'RETRY_ONDEMAND_MEDIA_FAIL';
export const CANCEL_ONDEMAND_MEDIA_REQUEST = 'CANCEL_ONDEMAND_MEDIA_REQUEST';
export const CANCEL_ONDEMAND_MEDIA_SUCCESS = 'CANCEL_ONDEMAND_MEDIA_SUCCESS';
export const CANCEL_ONDEMAND_MEDIA_FAIL = 'CANCEL_ONDEMAND_MEDIA_FAIL';
export const GET_MATCH_DVR_REQUEST = 'GET_MATCH_DVR_REQUEST';
export const GET_MATCH_DVR_SUCCESS = 'GET_MATCH_DVR_SUCCESS';
export const GET_MATCH_DVR_FAIL = 'GET_MATCH_DVR_FAIL';
export const SEARCH_DVR_REQUEST = 'SEARCH_DVR_REQUEST';
export const SEARCH_DVR_SUCCESS = 'SEARCH_DVR_SUCCESS';
export const SEARCH_DVR_FAIL = 'SEARCH_DVR_FAIL';
export const CHANGE_WIFI_CHECKBOX = 'CHANGE_WIFI_CHECKBOX';
