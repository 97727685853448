import {get} from 'lodash';
import BaseService from '../../middleware/BaseService';
import store from '../../stores';

const GET_URL_ENDPOINT = '/medias/download/clip';

class DownloadVideoService extends BaseService {
  prefix = GET_URL_ENDPOINT;

  async getUrlVideo(clip) {
    const userInformation = get(store.getState(), 'user.userInformation', {});

    if (clip && clip.mediaId && clip.clipId && clip.url && clip.url.length > 2) {
      clip.userName = userInformation.userName; // eslint-disable-line no-param-reassign
      const response = await this.search(clip, GET_URL_ENDPOINT);

      return response;
    }

    return {};
  }
}

export default new DownloadVideoService();
