/* eslint-disable quotes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {I18n} from 'react-redux-i18n';
import {get} from 'lodash';
import ReactGA from 'react-ga';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import LanguageSelection from './LanguageSelection';
import {logout, setUserLanguage} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import SwitchAccountAutoComplete from '../switchCustomer/components/SwitchCustomerAutocomplete';
import PlatformSwitcher from './PlatformSwitcher';
import {isBeta} from '@peoplenet/vi-components-ui/src/app/configuration/util';

class Settings extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
    this.toggleMenu(true);
  };

  handleClose = () => {
    this.setState({anchorEl: null});
    this.toggleMenu(false);
  };

  handleLogout = () => {
    this.props.logout();
    this.handleGA('Logout');
  }

  handleGA = gaAction => {
    ReactGA.event({
      category: 'Main Menu',
      action: gaAction,
      label: 'Drawer'
    });
  }

  goTo = (page, gaAction) => () => {
    this.setState({anchorEl: null}, () => {
      this.toggleMenu(false);
      this.props.dispatch(push(page));
    });

    this.handleGA(gaAction);
  }

  toggleMenu = open => () => {
    if (open) {
      this.handleGA('Open Menu');
    } else {
      this.handleGA('Close Menu');
    }
  }

  handleSwitchLanguage = langId => () => {
    const userInformation = get(this.props, 'user.userInformation', {});
    this.props.setUserLanguage(userInformation.userId, langId);
  }

  renderCompanyName = () => {
    const customerInformation = get(this.props, 'customer.data.customerInfo', {});
    const customerId = customerInformation.customerId || customerInformation.cid;

    if (customerId && customerInformation.companyName) {
      return (
        <div className="profile-dropdown__desktopswitcher-cid">({customerId}) {customerInformation.companyName}</div>
      );
    }

    return null;
  }
  renderUserIconMenu = () => {
    return (
      <div className="profile-dropdown__desktopswitcher-menu">
        <Icon className="profile-dropdown__desktopswitcher-simple-avatar">account_circle</Icon>
      </div>
    );
  }

  renderCurrentUser = () => {
    const userInformation = get(this.props, 'user.userInformation', {});
    return (
      <div className="profile-dropdown__desktopswitcher-menu">
        <Icon className="profile-dropdown__desktopswitcher-avatar">person</Icon>
        <div>
          <div className="profile-dropdown__desktopswitcher-user">{userInformation.userName || userInformation.email}</div>

          {this.renderCompanyName()}
        </div>
        <Icon id="profile-dropdown" className="profile-dropdown__desktopswitcher-arrowdd">arrow_drop_down</Icon>
      </div>
    );
  }

  renderSwitchAccount = () => {
    return (
      <div className="profile-dropdown__accountswitcher profile-dropdown__desktopswitcher">
        <SwitchAccountAutoComplete
          className="profile-dropdown__accountfield"
          onChange={this.toggleMenu(false)}
        />
      </div>
    );
  }

  renderUserSettingsMenu = () => {
    return (
      <MenuItem id="mainMenuGoToUserSettings" button onClick={this.goTo('/settings', 'User Settings')}>
        <Icon className="profile-dropdown__lock-icon">settings</Icon>
        {I18n.t('page.userSettings.title')}
      </MenuItem>
    );
  }

  render() {
    const {anchorEl} = this.state;
    const canChangeCustomer = get(this.props, 'user.userInformation.changeCustomer', false);
    return (
      <div>
        <div onClick={this.handleClick}>
          {this.props.isBeta ? this.renderUserIconMenu() : this.renderCurrentUser()}
        </div>

        <Menu
          id="simple-menu"
          className="profile-dropdown"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          classes={{
            paper: 'profile-dropdown__desktopswitcher-cont'
          }}
        >
          {canChangeCustomer && !this.props.isBeta && this.renderSwitchAccount()}
          {this.renderUserSettingsMenu()}
          <LanguageSelection />
          {!this.props.isBeta &&
          <>
            <PlatformSwitcher />
            <Divider />
            <MenuItem id="logout" button onClick={this.handleLogout}>
              <Icon className="profile-dropdown__lock-icon">lock
              </Icon>{I18n.t('action.logout')}
            </MenuItem>
          </>
          }
        </Menu>
      </div>
    );
  }
}

const dispatcherToProps = dispatcher => ({
  dispatch: param => dispatcher(param),
  setUserLanguage: (userId, langId) => dispatcher(setUserLanguage(userId, langId)),
  logout: () => dispatcher(logout())
});

function mapStateToProps(state) {
  return {...state, isBeta: isBeta(state)};
}

export default connect(mapStateToProps, dispatcherToProps)(Settings);
