import React from 'react';
import {I18n} from 'react-redux-i18n';
import {get, isEqual} from 'lodash';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {isMobile} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import Cards from './components/Cards';
import {getDashboardSummary} from './DashboardActions';
import {applyFilter} from '../components/DesktopFilter/Actions/filterActions';
import ProgressSpinner from '../components/ProgressSpinner';
import ViAppBar from '../components/ViAppBar';
import FiltersContainer from '../components/DesktopFilter/FiltersContainer';
import {
  FILTER_OER,
  FILTER_TERMINAL,
  DATE_FILTER_PERIODS
} from '../components/DesktopFilter/components/constantFilters';

// Graphs
import TotalEventsVsReviewedVideos from './components/graphs/TotalEventsVsReviewedVideos';
import AverageDistancePerEvent from './components/graphs/AverageDistancePerEvent';
import TopOers from './components/graphs/TopOers';
import TopCoaching from './components/graphs/TopCoaching';
import IndustryAverageDistancePerEvent from './components/graphs/IndustryAverageDistancePerEvent';
import EventTypeBreakdown from './components/graphs/EventTypeBreakdown';

class Dashboard extends React.Component {
  componentDidMount() {
    const oerList = get(this.props.customer, 'data.settings.oerList', []);
    this.props.getDashboardSummary({...this.props.filter, oerList});
  }

  componentWillReceiveProps(props) {
    const {filter} = props;

    if (!isEqual(this.props.filter, filter)) {
      // this.props.clear();
      const oerList = get(props.customer, 'data.settings.oerList', []);
      this.props.getDashboardSummary({...props.filter, oerList});
    }
  }

  renderContent = () => (
    <div>
      <div className="kpi__container-dashboard">
        <Cards
          totalCoaching={this.props.summaryInformation.totalCoached}
          totalReview={this.props.summaryInformation.totalReviewed}
          avgDistance={this.props.summaryInformation.avgDistance}
          eventsGenerated={this.props.summaryInformation.eventsGenerated}
          systemMeasure={this.props.systemMeasure}
        />
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={11} xl={10} className="kpi__container">
          <Grid container spacing={16}>
            <Grid item xs={12} className="kpi__graph-cont">
              <TotalEventsVsReviewedVideos
                data={this.props.reviewsAndOers}
                key={this.props.locale}
              />
            </Grid>
            <Grid item xs={12} className="kpi__graph-cont">
              <TopOers
                data={this.props.topOers}
                key={this.props.locale}
              />
            </Grid>
            <Grid item xs={12} md={6} className="kpi__graph-cont">
              <AverageDistancePerEvent
                data={this.props.distance}
                systemMeasure={this.props.systemMeasure}
                key={this.props.locale}
              />
            </Grid>
            <Grid item xs={12} md={6} className="kpi__graph-cont">
              <IndustryAverageDistancePerEvent
                data={this.props.industryDistanceAverage}
                systemMeasure={this.props.systemMeasure}
                key={this.props.locale}
              />
            </Grid>
            <Grid item xs={12} md={6} className="kpi__graph-cont">
              <TopCoaching
                data={this.props.topCoachBehaviors}
                key={this.props.locale}
              />
            </Grid>
            <Grid item xs={12} md={6} className="kpi__graph-cont">
              <EventTypeBreakdown
                oers={this.props.oers}
                key={this.props.locale}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  render() {
    return (
      <div>
        <div className="whitecover" />
        <ViAppBar auth={this.props.auth} title={I18n.t('page.dashboard.title')} />
        <div className="bodycontent bodycontent__playerdesktop">
          <div className="kpi__container-filter">
            <FiltersContainer
              filter={this.props.filter}
              listOfFilters={[FILTER_OER, FILTER_TERMINAL]}
              filterDatePeriods={[
                DATE_FILTER_PERIODS.LAST_WEEK,
                DATE_FILTER_PERIODS.LAST_THIRTY_DAYS,
                DATE_FILTER_PERIODS.LAST_MONTH,
                DATE_FILTER_PERIODS.THREE_MONTHS,
                DATE_FILTER_PERIODS.SIX_MONTHS
              ]}
            />
          </div>
          <div className="kpi__container-dashboard">
            <ProgressSpinner show={this.props.isLoading} />
            {!this.props.isLoading && this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {filters} = state.filterReducer;
  filters.systemMeasure = get(state, 'userSettings.settings.systemMeasure');
  return {
    locale: state.i18n.locale,
    ...state.dashboard,
    isMobile: isMobile(state),
    customer: state.customer,
    systemMeasure: filters.systemMeasure,
    filter: filters
  };
}

export default connect(mapStateToProps, {
  getDashboardSummary,
  applyFilter
})(Dashboard);
