/* eslint-disable max-len */
import React from 'react';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {filter, size, get} from 'lodash';
import moment from 'moment';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ChipsFilter from './components/DateFilter/ChipsFilter';
import {filterHiddensOERs} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import {
  FILTER_OER,
  FILTER_TERMINAL,
  DATE_FILTER_PERIODS
} from './components/constantFilters';
import FilterDialog from './components/Dialog/FilterDialog';

// TODO: move common parts to an AbstractFilters, then create MobileFilters
// import {AbstractFilters} from './AbstractFilters';

class DesktopFilters extends React.Component {
  static propTypes = {
    listOfFilters: PropTypes.arrayOf(PropTypes.string),
    filterDatePeriods: PropTypes.arrayOf(PropTypes.string)
  }

  static defaultProps = {
    listOfFilters: [FILTER_OER, FILTER_TERMINAL],
    filterDatePeriods: [
      DATE_FILTER_PERIODS.LAST_WEEK,
      DATE_FILTER_PERIODS.LAST_THIRTY_DAYS,
      DATE_FILTER_PERIODS.LAST_MONTH,
      DATE_FILTER_PERIODS.THREE_MONTHS,
      DATE_FILTER_PERIODS.SIX_MONTHS
    ]
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filters: {
        oerTypeFilter: get(props.filter, 'trigger', []),
        groupsFilter: get(props.filter, 'groups', []),
        terminalsFilter: get(props.filter, 'terminal', []),
        mediaId: get(props.filter, 'mediaId', ''),
        constrainedFilters: get(props.filter, 'constrained', []),
        startTime: get(props.filter, 'startTime', moment().subtract(6, 'months').startOf('day').valueOf()),
        endTime: get(props.filter, 'endTime', moment().subtract(1, 'days').endOf('day').valueOf())
      }
    };
  }

  componentWillMount() {
    this.props.loadGroups();
    this.props.loadOerTypes();
    this.props.loadTerminals();
  }

  updateChipFilter = chips => {
    this.props.updateChipFilter(chips);
    this.applyFilter({chipsFilter: chips});
  }

  applyDateFilter = newFilters => {
    const {
      groupsFilter,
      terminalsFilter,
      oerTypeFilter,
      constrainedFilters,
      mediaId
    } = this.state.filters;

    const {startTime, endTime} = newFilters;

    this.setState({
      open: false,
      filters: {
        groupsFilter,
        terminalsFilter,
        oerTypeFilter,
        constrainedFilters,
        mediaId,
        startTime,
        endTime
      }
    }, () => this.applyFilter());
  }

  applyFilter = () => {
    const {
      groupsFilter,
      terminalsFilter,
      oerTypeFilter,
      constrainedFilters,
      mediaId,
      startTime,
      endTime
    } = this.state.filters;
    const {chipsFilter} = this.props;
    this.props.applyFilter({
      groupsFilter, constrainedFilters, terminalsFilter, oerTypeFilter, chipsFilter, mediaId, startTime, endTime
    });
  }

  filterCount() {
    const count = size(this.state.filters.groupsFilter) +
      size(this.state.filters.terminalsFilter) +
      size(this.state.filters.oerTypeFilter) +
      size(filter(this.state.filters.constrainedFilters, i => i === true)) +
      (this.state.filters.mediaId ? 1 : 0);
    return count;
  }

  handleOnClear = () => {
    const filters = {
      oerTypeFilter: [],
      groupsFilter: [],
      terminalsFilter: [],
      mediaId: '',
      constrainedFilters: []
    };
    this.props.clearFilter();
    this.handleOnApply(filters);
  }

  handleOnApply = newFilters => {
    const {
      groupsFilter,
      terminalsFilter,
      oerTypeFilter,
      constrainedFilters,
      mediaId
    } = newFilters;

    const {startTime, endTime} = this.state.filters;

    this.setState({
      open: false,
      filters: {
        groupsFilter,
        terminalsFilter,
        oerTypeFilter,
        constrainedFilters,
        mediaId,
        startTime,
        endTime
      }
    }, () => this.applyFilter());
  }

  handleOpen = () => {
    this.setState({open: true});
  }

  handleRequestClose = () => {
    this.setState({open: false});
  }

  render() {
    return (
      <div className="desktopfilters">
        <ChipsFilter
          startTime={this.state.filters.startTime}
          endTime={this.state.filters.endTime}
          updateChipFilter={this.updateChipFilter}
          chipsFilter={this.props.chipsFilter}
          handleOnDateApply={this.applyDateFilter}
          onClearFilter={this.handleOnClear}
          periods={this.props.filterDatePeriods}
        />
        <FilterDialog
          open={this.state.open}
          onClose={this.handleRequestClose}
          onClear={this.handleOnClear}
          onApply={this.handleOnApply}
          showFilters={this.props.listOfFilters}
          filters={this.state.filters}
          {...this.props}
        />
        <IconButton onClick={this.handleOpen} className="desktopfilters__filtericon" id="filterIcon">
          <Badge showZero badgeContent={this.filterCount()} className="viappbar__badge-yellow">
            <Icon color="inherit" className="desktopfilters__ddicon">filter_list</Icon>
          </Badge>
        </IconButton>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    listOfOerTypes: filterHiddensOERs(state, 'filterReducer.oerTypes'),
    listOfGroups: get(state, 'filterReducer.groups', []),
    listOfTerminals: get(state, 'filterReducer.terminals', [])
  };
}

export default connect(mapStateToProps)(DesktopFilters);
