import React, {Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {I18n, Translate} from 'react-redux-i18n';
import SingleDeleteDialogContent from '../../deleteVideo/component/DeleteDialogContent';

export default class DeleteConfirmationModal extends React.Component {
  static defaultProps = {
    isOpen: false,
    medias: []
  }
  constructor(props) {
    super(props);
    this.state = {
      deleteField: ''
    };
  }

  handleClose = () => {
    this.setState({deleteField: ''});
    return this.props.onClose();
  }

  handleDelete = () => {
    this.setState({deleteField: ''});
    return this.props.onDelete();
  };

  handleChange = ({currentTarget: {value}}) => {
    this.setState({deleteField: value});
  };

  renderMediasLengthWarning = () => (
    <DialogContent>
      <DialogContentText>
        <p>
          <Translate
            value="action.deleteVideo.massDelete.lengthWarning"
            dangerousHTML
          />
        </p>
      </DialogContentText>
      <DialogActions>
        <Button className="btn btn-secondary" onClick={this.handleClose} >{I18n.t('defaults.cancel')}</Button>
      </DialogActions>
    </DialogContent>
  );

  renderContent = mediasLength => (
    <DialogContent>
      <DialogContentText>
        <p>
          <Translate
            value="action.deleteVideo.massDelete.confirmation"
            dangerousHTML
            medias={mediasLength}
          />
        </p>
        <p>
          <Translate value="action.deleteVideo.massDelete.undone" dangerousHTML />
        </p>
        <p>
          <Translate
            value="action.deleteVideo.massDelete.fillIn"
            dangerousHTML
            delete={I18n.t('action.deleteVideo.massDelete.delete')}
          />
          <TextField
            className="field__outlined field-fullwidth"
            defaultValue={this.state.deleteField}
            onChange={this.handleChange}
          />
        </p>
      </DialogContentText>
      <DialogActions>
        <Button className="btn btn-secondary" onClick={this.handleClose} >{I18n.t('defaults.no')}</Button>
        <Button className="btn btn-danger" disabled={this.state.deleteField !== I18n.t('action.deleteVideo.massDelete.delete')} onClick={this.handleDelete}>
          {I18n.t('defaults.yes')}
        </Button>
      </DialogActions>
    </DialogContent>
  );

  renderMassDelete = mediasLength => {
    const lengthWarning = mediasLength > 1000;
    return (
      <Fragment>
        <DialogTitle className="dialog-titledanger">
          {I18n.t('action.deleteVideo.massDelete.title')}
        </DialogTitle>
        {(lengthWarning && this.renderMediasLengthWarning()) ||
          this.renderContent(mediasLength)}
      </Fragment>
    );
  }

  renderSingleDelete = () => {
    const {onDelete} = this.props;
    return (
      <SingleDeleteDialogContent
        title={I18n.t('page.library.deleteMedia.title')}
        onCancel={this.handleClose}
        onConfirm={onDelete}
      />
    );
  }

  render() {
    const {isOpen, medias} = this.props;
    const mediasLength = medias.filter(media => media.checked).length;
    if (mediasLength > 0) {
      const content = mediasLength === 1 ? this.renderSingleDelete() : this.renderMassDelete(mediasLength);
      return (
        <Dialog open={isOpen} onClose={this.handleClose}>
          {content}
        </Dialog>
      );
    }
    return null;
  }
}
