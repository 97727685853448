import React from 'react';
import {I18n} from 'react-redux-i18n';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PieGraph from '../../../components/graph/PieGraph';
import formatOer from '@peoplenet/vi-components-ui/src/app/formatter';

export default class EventTypeBreakdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = [];
    this.data = [];
    this.hasData = false;
    const {oers: {oer}} = props;

    if (oer) {
      if (Object.keys(oer).length > 0) {
        this.hasData = true;
        Object.keys(oer).forEach(k => {
          this.labels.push(formatOer(k));
          this.data.push(oer[k]);
        });
      }
    }
  }

  renderGraph = () => {
    return (
      <PieGraph
        labels={this.labels}
        data={this.data}
        locale={this.props.locale}
      />
    );
  }

  render() {
    if (!this.hasData) {
      return (
        <Card className="kpi__graph kpi__container-nodata">
          <Typography variant="title" component="p">
            {I18n.t('page.dashboard.graph.eventTypeBreakdown')}
          </Typography>
          <span>
            <Icon className="insert_chart">insert_chart</Icon>
            {I18n.t('page.dashboard.graph.noData')}
          </span>
        </Card>
      );
    }
    return (
      <Card className="kpi__graph">
        <Typography variant="title" component="h2">
          {I18n.t('page.dashboard.graph.eventTypeBreakdown')}
        </Typography>
        <CardContent>
          {this.renderGraph()}
        </CardContent>
      </Card>
    );
  }
}
