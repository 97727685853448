import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import {filter, isEqual} from 'lodash';
import ReactGA from 'react-ga';
import Chips from '@peoplenet/vi-components-ui/src/components/Chips';
import {DatePickerChip} from './DatePickerChip';

export default class ChipsFilter extends React.Component {
  static defaultProps = {
    chipsFilter: []
  }
  state = {
    focused: false,
    over: false
  }

  onMouseOver = () => this.setState({over: true});
  onMouseOut = () => this.setState({over: false});
  onBlur = () => this.setState({focused: false});
  onFocus = () => this.setState({focused: true});

  removeTerms = (term, terms) => {
    if (terms.length > 0) {
      return terms.filter(o => o.name !== term);
    }
    return terms;
  }

  addTerms = (_term, value, label, isUnique) => {
    let {chipsFilter} = this.props;
    if (isUnique) {
      chipsFilter = this.removeTerms(_term, chipsFilter);
    }

    const term = {
      name: _term,
      value,
      label
    };

    chipsFilter = [...chipsFilter, term];

    this.props.updateChipFilter(chipsFilter);
  }

  handleOnRemoveChip = (event, chip) => {
    let {chipsFilter} = this.props;
    chipsFilter = filter(chipsFilter, item => !isEqual(item, chip));
    this.props.updateChipFilter(chipsFilter);

    ReactGA.event({
      category: 'Filter',
      action: 'Remove Chip',
      label: 'Filter Chips'
    });
  }

  chipsFilter() {
    const {focused, over} = this.state;
    let chipClasses = ['desktopfields__chipscont'];
    if (focused) {
      chipClasses.push('focused');
    } else if (over) {
      chipClasses.push('over');
    }
    chipClasses = chipClasses.join(' ');
    return (
      <ListItem className="bringontop desktopfields__mainsearch">
        <div className={chipClasses}>
          <DatePickerChip
            startTime={this.props.startTime}
            endTime={this.props.endTime}
            handleOnDateApply={this.props.handleOnDateApply}
            datePeriods={this.props.periods}
          />
          <Chips
            list={this.props.chipsFilter}
            onRemove={this.handleOnRemoveChip}
            id="filterVehicleDriverResult"
          />
        </div>
      </ListItem>
    );
  }

  render() {
    return (
      <div className="dtcontainer">
        {this.chipsFilter()}
      </div>
    );
  }
}
