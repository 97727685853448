import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {I18n} from 'react-redux-i18n';
import {Icon} from '@material-ui/core';
import querystring from 'querystring';
import {push} from 'connected-react-router';
import IconButton from '@material-ui/core/IconButton';
import ScrollEvent from 'react-onscroll';

import ProgressSpinner from '../../components/ProgressSpinner';
import MobileRequestQueueItem from './MobileRequestQueueItem';

import {
  retryOndemandMedia,
  cancelOndemandMedia
} from '../OnDemandActions';

class MobileRequestQueue extends React.Component {
  getCurrentScrollPercent = () => {
    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  };

  handleLazyLoading = () => {
    const {requests, visible} = this.props;

    if (requests.isLoading || !requests.hasMorePages) {
      return;
    }

    if (visible === true && this.getCurrentScrollPercent() > 80) {
      this.props.loadRequests({
        page: requests.page + 1,
        sort: requests.sort,
        sortOrder: requests.sortOrder
      });
    }
  }

  openPlayer = mediaId => {
    const query = {mediaId};
    this.props.push(`/player?${querystring.stringify(query)}`);
  }

  renderActionButton(itemIndex, item) {
    if (this.canBeCanceled(item)) {
      return (
        <IconButton>
          <Icon>delete</Icon>
        </IconButton>
      );
    }

    return null;
  }

  renderPreviouslyRequestedQueueEmpty() {
    const {list, isLoading} = this.props.requests;

    if (list.length > 0 || isLoading) {
      return null;
    }

    return (
      <TableRow>
        <TableCell>
          <span className="ondemand__infomsg"><Icon className="ondemand__msgicon">info</Icon>{I18n.t('page.onDemand.requestQueue.previouslyListEmpty')}</span>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const {type, requests: {list, isLoading}} = this.props;

    const hideClass = classNames({
      hideme: !this.props.visible
    });

    return (
      <React.Fragment>
        <ScrollEvent handleScrollCallback={this.handleLazyLoading} />
        <ProgressSpinner show={isLoading} />

        <Table className={`${hideClass} table__contnh ondemand__requestqueue-mobile`}>
          <TableBody>
            {this.renderPreviouslyRequestedQueueEmpty()}
            {list.map(e => (
              <MobileRequestQueueItem
                type={type}
                requestItem={e}
                key={e.id}
                retryOndemandMedia={this.props.retryOndemandMedia}
                cancelOndemandMedia={this.props.cancelOndemandMedia}
                canChangeStatus={this.props.canChangeStatus}
                openPlayer={this.openPlayer}
              />
            ))
            }
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {onDemand, customer} = state;
  return {...state.i18n.locale, ...onDemand, customer};
}

export default connect(mapStateToProps, {
  retryOndemandMedia,
  cancelOndemandMedia,
  push
})(MobileRequestQueue);
