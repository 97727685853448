import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {IconButton} from '@material-ui/core';
import {Lock, LockOpen} from '@material-ui/icons';
import ReactGA from 'react-ga';

import {lockVideo, unLockVideo} from '../../lockVideo/LockVideoActions';

class LockVideo extends Component {
  handleVideoLockClick = () => {
    const {
      isAdmin,
      isInternalSalesUser,
      isInternaPeoplelUser,
      userInformation: {userId}, media
    } = this.props;

    if (isInternalSalesUser) return;
    if (!isAdmin && !isInternaPeoplelUser) return;

    const isLocked = media.metadata.locked === true;
    if (isLocked) this.props.unLockVideo(media.mediaId, userId);
    else this.props.lockVideo(media.mediaId, userId);

    const action = !isLocked ? 'Lock Video' : 'Unlock Video';
    const label = 'Video Admin Unset';
    const category = 'Player';
    const trackInfo = {category, action, label};
    ReactGA.event(trackInfo);
  }

  render() {
    const {
      isAdmin,
      isInternalSalesUser,
      isInternaPeoplelUser,
      media
    } = this.props;

    if ((!isAdmin && !isInternaPeoplelUser) || !media.metadata) return null;
    if (!['onDemand', 'efetch'].includes(media.metadata.triggerType)) return null;

    const isLocked = media.metadata.locked === true;

    const hintMessage = () => {
      if (isInternalSalesUser) return I18n.t('page.playerPage.actions.notAllowed');
      if (isLocked) return I18n.t('page.playerPage.actions.unLockVideo');

      return I18n.t('page.playerPage.actions.lockVideo');
    }
    return (
      <Fragment>
        <div title={hintMessage()}>
          <IconButton
            disabled={isInternalSalesUser}
            title={hintMessage()}
            onClick={this.handleVideoLockClick}
            id="videoLock"
          >
            {isLocked && (<Lock className="lock">lock</Lock>)}
            {!isLocked && (<LockOpen className="lock">lock</LockOpen>)}
          </IconButton>
        </div>
        {this.props.isMenu && <span onClick={this.handleVideoLockClick}>{!isLocked ? I18n.t('page.playerPage.actions.shortLockVideo') : I18n.t('page.playerPage.actions.unLockVideo')}</span>}
      </Fragment>
    );
  }
}
export default connect(null, {lockVideo, unLockVideo})(LockVideo);
