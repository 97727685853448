/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Coaching from '../../container/Coaching';
import PreviouslyReviewed from '../../container/PreviouslyReviewed';
import ReviewSummary from './ReviewSummary';
import ReviewForm from './ReviewFormDesktop';

function TabContainer(props) {
  return <div>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class ReviewDetails extends React.Component {
  constructor(props) {
    super(props);
    const {comment} = props.coach || {};
    this.state = {
      tabIndex: props.tabIndex || 0,
      coachingComment: comment || ''
    };
  }

  onChangeCoachingComment = value => {
    this.setState({coachingComment: value});
  };

  handleChangeTab = (event, value) => {
    this.setState({tabIndex: value});
  };

  handleChangeTabIndex = index => {
    this.setState({tabIndex: index});
  };

  render() {
    const {
      media,
      review,
      previouslyReviews,
      previouslyReviewsTotal,
      searchingReviews,
      savedReview,
      coach,
      address,
      handleCustomReviewChange,
      handleCheckReviewOption,
      handleReviewSubmit,
      openCloseReviewSubmittedChip,
      handleCheckReviewCompleted,
      cancelEditReview,
      showScore,
      textSearch,
      onChanteTextSearch,
      onSearchReview,
      onChangeReviewSort,
      getSortFieldDirection,
      searchingMoreReviews,
      isSameSortField,
      onLoadReview,
      onChangeHeaderVisibility,
      canSubmit,
      currentPage,
      onChangePage
    } = this.props;
    const {tabIndex} = this.state;
    const thereIsDriver = media.metadata.driverID1 || media.metadata.driverID2;

    return (
      <div className="reviewdesktop__cont" style={{position: 'relative'}}>
        <ReviewSummary
          address={address}
          media={media}
          review={review}
          savedReview={savedReview}
          coach={coach}
          showScore={showScore}
        />

        <AppBar position="static" color="default">
          <Tabs
            value={this.state.tabIndex}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              icon={<Icon>videocam</Icon>}
              label={I18n.t('page.playerPage.reviewDetails.reviewTab')}
              className="reviewdesktop__subtabs-tabbt"
            />
            <Tab
              icon={<Icon>people</Icon>}
              label={I18n.t('page.playerPage.reviewDetails.coachingTab')}
              className="reviewdesktop__subtabs-tabbt"
            />
            {thereIsDriver && (
              <Tab
                icon={<Icon>videocam</Icon>}
                label={I18n.t('page.playerPage.reviewDetails.previouslyReviewedTab')}
                className="reviewdesktop__subtabs-tabbt"
              />
            )}
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={this.state.tabIndex}
          draggable={false}
          onChangeIndex={this.handleChangeTabIndex}
        >
          <TabContainer>
            <ReviewForm
              handleCheckReviewOption={handleCheckReviewOption}
              handleReviewSubmit={handleReviewSubmit}
              review={review}
              openCloseReviewSubmittedChip={openCloseReviewSubmittedChip}
              handleCheckReviewCompleted={handleCheckReviewCompleted}
              cancelEditReview={cancelEditReview}
              showScore={showScore}
              handleCustomReviewChange={handleCustomReviewChange}
              canSubmit={canSubmit}
            />
          </TabContainer>
          <TabContainer>
            <Coaching
              onChangeComment={this.onChangeCoachingComment}
              coachingComment={this.state.coachingComment}
            />
          </TabContainer>
          {thereIsDriver && (
            <TabContainer>
              <PreviouslyReviewed
                currentPage={currentPage}
                textSearch={textSearch}
                tabIndex={tabIndex}
                onLoadReview={onLoadReview}
                onChangeReviewSort={onChangeReviewSort}
                getSortFieldDirection={getSortFieldDirection}
                onChangeHeaderVisibility={onChangeHeaderVisibility}
                isSameSortField={isSameSortField}
                onChanteTextSearch={onChanteTextSearch}
                onChangePage={onChangePage}
                onSearchReview={onSearchReview}
                searchingReviews={searchingReviews}
                searchingMoreReviews={searchingMoreReviews}
                previouslyReviews={previouslyReviews}
                previouslyReviewsTotal={previouslyReviewsTotal}
              />
            </TabContainer>
          )}
        </SwipeableViews>
      </div>
    );
  }
}

export default ReviewDetails;
