import React from 'react';
import {I18n} from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {each, toLower, includes, capitalize} from 'lodash';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import LineGraph from '../../../components/graph/LineGraph';

export default class AverageDistancePerOer extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }

  constructor(props) {
    super(props);
    this.labels = [];
    this.points = [];
    this.hasData = false;
    this.systemMeasure = capitalize(this.props.systemMeasure);
    const {data} = props;
    each(data, entry => {
      const label = moment(entry.key).utc().format(I18n.t('configuration.dateFormat'));
      const isWeek = includes(toLower(entry.key_as_string), 'week');
      this.labels.push(`${isWeek ? I18n.t('page.dashboard.graph.label.weekOf') : ''} ${label}`);
      let point = 0;

      if (entry.distance !== 0 && entry.oer_count !== 0) {
        point = (entry.distance / entry.oer_count).toFixed(1);
      }

      this.points.push(point);
      this.hasData = this.hasData || point !== 0;
    });
  }

  renderGraph = () => {
    if (!this.hasData) {
      return I18n.t('page.dashboard.graph.noData');
    }

    const dataset = {
      data: this.points,
      label: I18n.t(`page.dashboard.graph.label.average${this.systemMeasure}PerEvent`),
      borderWidth: 3,
      borderColor: 'rgba(230, 144, 56, 1)',
      backgroundColor: 'rgba(230, 144, 56, 0.3)'
    };

    return (
      <LineGraph
        labels={this.labels}
        dataset={dataset}
      />
    );
  }

  render() {
    if (!this.hasData) {
      return (
        <Card className="kpi__graph kpi__container-nodata">
          <Typography variant="title" component="p">
            {I18n.t(`page.dashboard.graph.average${this.systemMeasure}PerOer`)}
          </Typography>
          <span>
            <Icon className="insert_chart">insert_chart</Icon>
            {I18n.t('page.dashboard.graph.noData')}
          </span>
        </Card>
      );
    }

    return (
      <Card className="kpi__graph">
        <Typography variant="title" component="h2">
          {I18n.t(`page.dashboard.graph.average${this.systemMeasure}PerOer`)}
        </Typography>
        <CardContent>
          {this.renderGraph()}
        </CardContent>
      </Card>
    );
  }
}
