/* eslint-disable import/prefer-default-export */
import {I18n} from 'react-redux-i18n';
import DvrTypes from '@peoplenet/vi-components-ui/src/enum/DvrTypes';

export const getUnreadNotifications = notifications => {
  return (notifications || []).filter(notification => {
    if (notification.status !== 'unread') {
      return false;
    }

    return notification.readRequested !== true;
  });
};

export const getUnreadNotificationsCount = notifications => {
  return getUnreadNotifications(notifications).length;
};

export const getChannelName = (machineType, channelNumber) => {
  const channelData = DvrTypes.forMachineType(machineType)
    .getChannels(true)
    .find(channel => channel.number === channelNumber);

  return channelData && channelData.name;
};

export const getCameraViewName = ({machineType, channel}) => {
  const channelName = getChannelName(machineType, channel);

  if (!channelName) {
    return null;
  }

  return `${channelName}-camera`;
};

export const getDashboardDownloadShortDescripton = data => {
  let channelName = getChannelName(data.machineType, data.channel);

  if (channelName) {
    channelName = I18n.t(`notification.dashboardDownload.cameras.${channelName}`);
  }

  return [data.vehicleId, data.driverName, channelName];
};
