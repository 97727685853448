import React from 'react';
import {I18n} from 'react-redux-i18n';
import {get} from 'lodash';

import {CAMERA_VIEWS} from '@peoplenet/vi-components-ui/src/app/configuration/cameras';

class CameraViews extends React.PureComponent {
  getCameraViews = () => {
    return CAMERA_VIEWS.get(this.props.machineType);
  };

  hasCamera = cameraView => {
    const {channels} = this.props;
    return channels.indexOf(cameraView.channelIndex) >= 0;
  };

  renderIcon = cameraView => {
    const renderIcons = get(this.props, 'renderIcons', true);
    const hasCamera = this.hasCamera(cameraView);
    if (!hasCamera && !renderIcons) {
      return null;
    }
    return (<img
      key={cameraView.channelIndex}
      src={cameraView.icon}
      title={I18n.t(`page.onDemand.requestQueue.cameraViews.${cameraView.key}`)}
      alt=""
      className={this.hasCamera(cameraView) ? '' : 'disabled'}
    />);
  };

  render() {
    return (
      <React.Fragment>
        {this.getCameraViews().map(cameraView => this.renderIcon(cameraView))}
      </React.Fragment>
    );
  }
}

export default CameraViews;
