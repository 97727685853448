import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import uri from 'urijs';
import {pipe} from 'lodash/fp';
import {loadUserInformation} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import {popQueryParameter, REDIRECT_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';

class LoginTrimbleId extends React.PureComponent {
  componentDidMount() {
    const {
      dispatch,
      auth,
      hash,
      onLogin
    } = this.props;

    if (hash) {
      const {
        access_token: accessToken,
        expires_in: expiresIn,
        session_state: sessionState,
        id_token: idToken,
        state

      } = pipe(
        this.removeAnchorFromUrl,
        uri.parseQuery
      )(hash);
      if (accessToken) {
        auth.setLogin({
          accessToken,
          expiresIn,
          sessionState,
          idToken,
          state
        });
        onLogin({
          accessToken,
          expiresIn,
          sessionState,
          idToken,
          state
        });
      }
    } else {
      auth.handleAuthentication();
    }
    if (auth.isAuthenticated() && !this.props.loading) {
      // TODO: check whether or not this is necessary
      dispatch(loadUserInformation());
      dispatch(push(this.redirectPath));
    }
  }

  get redirectPath() {
    const redirectPath = popQueryParameter(REDIRECT_KEY);
    if (redirectPath) {
      return redirectPath;
    }
    return '/library';
  }

  removeAnchorFromUrl = url => {
    return url.replace('#', '?');
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({loading: state.customer.loading});

export default connect(mapStateToProps)(LoginTrimbleId);
