/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {get, padStart, toInteger, filter, each, cloneDeep} from 'lodash';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import {
  goToOnDemand,
  reset,
  loadChannels,
  checkMatchDVR,
  postFetchAsync
} from '../../onDemand/OnDemandActions';
import ChannelsAvailable from '../../onDemand/component/ChannelsAvailable'; // eslint-disable-line import/no-named-as-default
import {MACHINE_TYPE} from '@peoplenet/vi-components-ui/src/app/configuration/cameras';

const MAX_REQUESTED_SECONDS = 240;

const initialState = {
  step: 1,
  requestedSeconds: {
    left: 0,
    leftPercent: 0,
    right: 0,
    rightPercent: 0,
    total: 0
  },
  totalRequested: 0,
  cameras: [
    {
      front: false,
      right: false,
      back: false,
      left: false
    },
    {
      front: false,
      back: false
    }
  ],
  error: {
    maxRequestTime: false,
    equalMaxRequestTime: false,
    cantRemoveClipTime: false
  }
};

const defaultState = {
  open: false,
  isValidSubmit: false,
  timeSelected: 1,
  clipSeconds: 0,
  ...initialState
};

class ExtendVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = cloneDeep(defaultState);
  }

  componentDidMount() {
    const {media} = this.props;
    if (media != null) {
      this.props.loadChannels({vehicle: {name: media.vehicle}})
        .then(res => {
          if (res.data !== null) {
            this.props.checkMatchDVR({serialNo: media.metadata.serialNo, vehicle: media.vehicle});
          }
        });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleOpen = () => {
    const {captureStartDate, captureEndDate} = this.props;

    const startDateTime = moment(toInteger(captureStartDate));
    const endDateTime = moment(toInteger(captureEndDate));

    this.setState({
      open: true,
      clipSeconds: Math.floor(endDateTime.diff(startDateTime, 'seconds'))
    }, this.updateRequestedTime);
  }

  handleChangeTime = ({target}) => {
    const {value} = target;
    this.setState({timeSelected: value});
  }

  handleClickAddTime = dir => () => {
    const {
      requestedSeconds,
      timeSelected,
      clipSeconds,
      error
    } = this.state;
    requestedSeconds[dir] += timeSelected;

    requestedSeconds.total = requestedSeconds.left + requestedSeconds.right;
    const totalTime = clipSeconds + requestedSeconds.total;

    if (totalTime > MAX_REQUESTED_SECONDS) {
      requestedSeconds[dir] = requestedSeconds[dir] - (totalTime - MAX_REQUESTED_SECONDS);
      error.maxRequestTime = true;
    }

    this.setState({requestedSeconds, error}, this.updateRequestedTime);
  }

  handleClickRemoveTime = dir => () => {
    const {
      requestedSeconds,
      timeSelected,
      clipSeconds,
      error
    } = this.state;
    requestedSeconds[dir] -= timeSelected;

    requestedSeconds.total = requestedSeconds.left + requestedSeconds.right;
    const totalTime = clipSeconds + requestedSeconds.total;

    error.maxRequestTime = (totalTime > MAX_REQUESTED_SECONDS);

    if (requestedSeconds[dir] < 0) {
      error.canNotRemoveClipTime = true;
      requestedSeconds[dir] = 0;
    }

    this.setState({requestedSeconds, error}, this.updateRequestedTime);
  }

  handleOnChangeCamera = _cameras => {
    const {cameras} = this.state;
    cameras[MACHINE_TYPE.get(this.props.machineType)] = _cameras;
    this.setState({cameras}, this.updateRequestedTime);
  }

  updateRequestedTime = () => {
    const {
      clipSeconds,
      requestedSeconds,
      cameras,
      error
    } = this.state;

    let totalChannels = filter(cameras[MACHINE_TYPE.get(this.props.machineType)], c => {
      return c;
    }).length;

    if (totalChannels === 0) {
      totalChannels = 1;
    }

    requestedSeconds.total = requestedSeconds.left + requestedSeconds.right;

    const totalTime = clipSeconds + requestedSeconds.total;
    requestedSeconds.leftPercent = (requestedSeconds.left / totalTime) * 100;
    requestedSeconds.rightPercent = (requestedSeconds.right / totalTime) * 100;

    error.equalMaxRequestTime = (totalTime === MAX_REQUESTED_SECONDS);
    error.canNotRemoveClipTime = false;

    const totalRequested = (totalTime) * totalChannels;

    this.setState({totalRequested, requestedSeconds, error}, this.validateForm);
  }

  validateForm = () => {
    const {requestedSeconds, cameras} = this.state;
    const {dvrNotMatch, vehicleNotFound, isInternalSalesUser} = this.props;
    if (isInternalSalesUser || vehicleNotFound || dvrNotMatch) {
      this.setState({isValidSubmit: false});
      return;
    }

    const totalChannels = filter(cameras[MACHINE_TYPE.get(this.props.machineType)], c => {
      return c;
    }).length;

    if (totalChannels <= 0) {
      this.setState({isValidSubmit: false});
      return;
    }
    if (requestedSeconds.total <= 0) {
      this.setState({isValidSubmit: false});
      return;
    }

    this.setState({isValidSubmit: true});
  }

  submit = () => {
    const {
      media,
      machineType,
      captureStartDate,
      captureEndDate,
      userInformation: {
        userName: requestedBy
      }
    } = this.props;

    const {requestedSeconds, cameras} = this.state;

    let startDateTime = moment(toInteger(captureStartDate));
    let endDateTime = moment(toInteger(captureEndDate));

    if (requestedSeconds.left > 0) {
      startDateTime = moment(startDateTime.toDate()).add(-Math.abs(requestedSeconds.left), 'seconds');
    }
    if (requestedSeconds.right > 0) {
      endDateTime = moment(endDateTime.toDate()).add(requestedSeconds.right, 'seconds');
    }

    const channels = [];
    let i = 1;
    each(cameras[MACHINE_TYPE.get(machineType)], c => {
      if (c) {
        channels.push(i);
      }
      i++; // eslint-disable-line no-plusplus
    });

    const params = {
      vehicleNumber: media.vehicle,
      startDate: startDateTime.toDate().getTime(),
      endDate: endDateTime.toDate().getTime(),
      channels,
      machineType,
      wifi: false,
      parentMediaId: media.mediaId,
      requestedBy,
      pairingMethod: get(this.props.customer, 'data.dvrPairing.method', 'dsn')
    };
    this.props.postFetchAsync(params).then(() => this.setState({step: 2}));
  }

  resetForm = open => () => {
    this.setState({open}, () => {
      const initial = cloneDeep(initialState);
      this.setState({...initial}, this.updateRequestedTime);
    });
  }

  formatSeconds = _seconds => {
    const minutes = Math.floor(_seconds / 60);
    const seconds = Math.floor(_seconds - (minutes * 60));
    return `${padStart(minutes, 2, 0)}:${padStart(seconds, 2, 0)}`;
  }

  render() {
    const {
      step,
      timeSelected,
      requestedSeconds,
      clipSeconds,
      totalRequested,
      isValidSubmit,
      cameras,
      error
    } = this.state;
    const {
      media,
      machineType,
      isLoading,
      dvrNotMatch,
      vehicleNotFound
    } = this.props;
    const camType = MACHINE_TYPE.get(machineType);

    const cameraOptions = {
      order: this.props.channelsAvailable,
      machineType,
      cameras: cameras[camType],
      showLabel: false
    };

    return (
      <div>
        <Button color="default" className="btn-terciery" onClick={this.handleOpen} disabled={(media === null)} >
          <Icon color="inherit" className="videocamicon">switch_video</Icon> {I18n.t('page.playerPage.extendVideo.title')}
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          BackdropProps={{
            invisible: true
          }}
          classes={{
            root: 'extendvideo',
            paper: 'extendvideo__paper'
          }}
        >
          <DialogContent>
            <Grid container className={step === 1 ? '' : 'hideme'}>
              <Grid item md={12} className="extendvideo__timeline-cont">
                <div className="extendvideo__timeline">
                  <label className="label">{I18n.t('page.playerPage.extendVideo.videoDuration')}</label>
                  <div className="extendvideo__timeline-clips">
                    <div className="extendvideo__timeline-clips-prev" style={{width: `${requestedSeconds.leftPercent}%`}}>
                      {requestedSeconds.leftPercent > 0 && (
                        <React.Fragment>
                          <span className="extendvideo__timeline-timestamp-left">
                            00:00 - {this.formatSeconds(requestedSeconds.left)}
                          </span>
                          <span className="divider" />
                        </React.Fragment>
                      )}
                    </div>
                    <div className="extendvideo__timeline-clips-current">
                      {I18n.t('page.playerPage.extendVideo.currentClip')}
                    </div>
                    <div className="extendvideo__timeline-clips-next" style={{width: `${requestedSeconds.rightPercent}%`}}>
                      {requestedSeconds.rightPercent > 0 && (
                        <React.Fragment>
                          <span className="divider" />
                          <span className="extendvideo__timeline-timestamp-right">
                            {this.formatSeconds(requestedSeconds.left + clipSeconds)} - {this.formatSeconds(requestedSeconds.total + clipSeconds)}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} className="extendvideo__addtime">
                <Icon>keyboard_arrow_left</Icon>
                <Button id="addTimeLeft" className="btn btn-primary btn_timemanage" onClick={this.handleClickAddTime('left')} disabled={error.equalMaxRequestTime}>
                  <Icon>add</Icon>
                </Button>
                <Button id="removeTimeLeft" className="btn btn-secondary btn_timemanage" onClick={this.handleClickRemoveTime('left')} disabled={requestedSeconds.left <= 0}>
                  <Icon>remove</Icon>
                </Button>

                <FormControl className="extendvideo__addtime-field">
                  <InputLabel htmlFor="addTime">{I18n.t('page.playerPage.extendVideo.additionalTime')}</InputLabel>
                  <Select
                    id="addTime"
                    name="addTime"
                    MenuProps={{id: 'addTimeMenu'}}
                    value={timeSelected}
                    onChange={this.handleChangeTime}
                  >
                    <MenuItem id="1second" value={1}>1 {I18n.t('page.playerPage.extendVideo.second')}</MenuItem>
                    <MenuItem id="5seconds" value={5}>5 {I18n.t('page.playerPage.extendVideo.seconds')}</MenuItem>
                    <MenuItem id="30seconds" value={30}>30 {I18n.t('page.playerPage.extendVideo.seconds')}</MenuItem>
                    <MenuItem id="60seconds" value={60}>1 {I18n.t('page.playerPage.extendVideo.minute')}</MenuItem>
                  </Select>
                </FormControl>

                <Button id="removeTimeRight" className="btn btn-primary btn_timemanage" onClick={this.handleClickRemoveTime('right')} disabled={requestedSeconds.right <= 0}>
                  <Icon>remove</Icon>
                </Button>
                <Button id="addTimeRight" className="btn btn-secondary btn_timemanage" onClick={this.handleClickAddTime('right')} disabled={error.equalMaxRequestTime}>
                  <Icon>add</Icon>
                </Button>
                <Icon>keyboard_arrow_right</Icon>
              </Grid>
              <Grid item md={12} className="extendvideo__feedback-message">
                {dvrNotMatch && (
                  <label className="label label-error">{I18n.t('page.playerPage.extendVideo.error.dvrNotMatch')}</label>
                )}
                {vehicleNotFound && (
                  <label className="label label-error">{I18n.t('page.playerPage.extendVideo.error.vehicleNotFound')}</label>
                )}
                {error.maxRequestTime && (
                  <label className="label label-error">
                    <Translate
                      value="page.playerPage.extendVideo.error.maxRequestTime"
                      minutes={(MAX_REQUESTED_SECONDS / 60)}
                    />
                  </label>
                )}
                {error.canNotRemoveClipTime && (
                  <label className="label label-error">{I18n.t('page.playerPage.extendVideo.error.canNotRemoveClipTime')}</label>
                )}
              </Grid>
              <Grid item md={12} className="ondemand__selectedviews-cont extendvideo__cameras">
                <ChannelsAvailable
                  options={cameraOptions}
                  onChange={this.handleOnChangeCamera}
                  isLoading={isLoading}
                />
                <label className="label label-success">{I18n.t('page.playerPage.extendVideo.selectCamera')}</label>
              </Grid>
              <Grid item md={12} className="extendvideo__summary-cont">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>{I18n.t('page.playerPage.extendVideo.currentClip')}</TableCell>
                      <TableCell>{this.formatSeconds(clipSeconds)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{I18n.t('page.playerPage.extendVideo.timeRequested')}</TableCell>
                      <TableCell>{this.formatSeconds(requestedSeconds.total)}</TableCell>
                    </TableRow>
                    <TableRow className="bold-cell">
                      <TableCell>{I18n.t('page.playerPage.extendVideo.totalTime')}</TableCell>
                      <TableCell>{this.formatSeconds(totalRequested)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className="extendvideo__info-cont">
                  <Icon className="info">info</Icon>
                  <label className="label">{I18n.t('page.playerPage.extendVideo.requestMessage')}</label>
                </div>
              </Grid>
              <Grid item md={12} className="extendvideo__actions">
                <Button id="reset" className="btn btn-secondary" onClick={this.resetForm(true)}>{I18n.t('page.playerPage.extendVideo.reset')}</Button>
                <Button id="submitRequest" className="btn btn-primary" disabled={!isValidSubmit} onClick={this.submit}>{I18n.t('page.playerPage.extendVideo.requestSubmit')}</Button>
              </Grid>
            </Grid>
            <Grid container className={step === 2 ? '' : 'hideme'}>
              <Grid item md={12} className="extendvideo__confirmation-message">
                <Icon className="check_circle">check_circle</Icon>
                <p>{I18n.t('page.playerPage.extendVideo.requestSuccess')}</p>
              </Grid>
              <Grid item md={12} className="extendvideo__actions">
                <Button id="done" className="btn btn-secondary" onClick={this.resetForm(false)}>{I18n.t('page.playerPage.extendVideo.done')}</Button>
                <Button id="goToRequest" className="btn btn-primary" onClick={this.props.goToOnDemand}>{I18n.t('page.playerPage.extendVideo.goToRequest')}</Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {onDemand, customer} = state;
  return {
    ...onDemand,
    ...state.i18n.locale,
    customer
  };
}

export default connect(mapStateToProps, {
  goToOnDemand,
  reset,
  loadChannels,
  checkMatchDVR,
  postFetchAsync
})(ExtendVideo);
