/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScrollEvent from 'react-onscroll';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import querystring from 'querystring';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {filter, toLower, each, replace, padStart, get, cloneDeep} from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoCompleteInput from '@peoplenet/vi-components-ui/src/Form/AutoCompleteInput';

import moment from '@peoplenet/vi-components-ui/src/app/moment';
import PreviouslyQueue from '../component/PreviouslyQueue';
import ChannelsAvailable from '../component/ChannelsAvailable'; // eslint-disable-line import/no-named-as-default
import CameraViews from '../component/CameraViews';
import TextMaskMinuteSeconds from '../component/TextMaskMinuteSeconds';
import ProgressSpinner from '../../components/ProgressSpinner';
import {MACHINE_TYPE} from '@peoplenet/vi-components-ui/src/app/configuration/cameras';
import {VEHICLE_ONDEMAND_KEY} from '@peoplenet/vi-components-ui/src/app/configuration/localstorageKeys';
import {popQueryParameter, VEHICLE_NUMBER_KEY} from '@peoplenet/vi-components-ui/src/app/historyUtil';

function TabContainer(props) {
  return <div>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const initialState = {
  step: 1,
  tabIndex: 0,
  vehicleInvalid: false,
  dvrInvalid: false,
  vehicle: null,
  dvr: null,
  cameras: [
    {
      front: false,
      right: false,
      back: false,
      left: false
    },
    {
      front: false,
      back: false
    }
  ],
  startDate: moment().format('YYYY-MM-DD'),
  startDateTime: moment(),
  startTime: moment().format('HH:mm:ss'),
  endDateTime: moment(),
  duration: '00:01',
  durationTotal: null,
  durationNotFound: false,
  durationInvalid: false,
  durationInFuture: false,
  camerasInvalid: false,
  dvrScanIntervals: [],
  dvrScanTimeLabels: [],
  dvrScanStatus: false,
  dvrScanRequested: {},
  submitDisabled: true,
  autocompleteValue: '',
  serialAutocompleteValue: '',
  camerasTotalSize: 0,
  pairingMethod: 'dsn',
  workflow: 'vehicle'
};

class DesktopOnDemand extends React.Component {
  constructor(props) {
    super(props);

    initialState.pairingMethod = get(props.customer, 'data.dvrPairing.method', 'dsn');
    initialState.workflow = get(props.customer, 'data.onDemand.workflow', 'vehicle');

    this.state = initialState;

    const vehicleNumber = popQueryParameter(VEHICLE_NUMBER_KEY);
    if (vehicleNumber) {
      localStorage.setItem(VEHICLE_ONDEMAND_KEY, vehicleNumber);
    }
  }

  componentDidMount = () => {
    this.props.loadTotalRequested();
    this.props.loadQueueList({page: 0});

    this.setVehicleByQuery();
  }

  componentWillReceiveProps = nextProps => {
    this.updateScanIntervals(nextProps);
  }

  componentDidUpdate = () => {
    const {requestQueue} = this.props;
    const isQueueTab = (this.state.tabIndex === 0);
    const height = window.innerHeight;
    const innerH = document.body.clientHeight;

    if (height > innerH &&
      isQueueTab &&
      !requestQueue.isLoading &&
      requestQueue.list.length > 0 &&
      (requestQueue.page + 1 < requestQueue.pages)) {
      this.props.loadQueueList({page: requestQueue.page + 1});
    }
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.clearTables();
  }

  setVehicleByQuery = () => {
    const vehicleNumber = localStorage.getItem(VEHICLE_ONDEMAND_KEY);
    if (vehicleNumber) {
      if (initialState.workflow === 'dvr') {
        this.props.searchDVRs({text: vehicleNumber}).then(dvrs => {
          const name = get(dvrs, '[0].name', null);
          const serial = get(dvrs, '[0].serial', null);
          if (name && serial) {
            this.setState({serialAutocompleteValue: name}, () => {
              this.handleOnSelectedDvrAutocomplete(null, {suggestion: {name, serial, type: 'dvr'}});
            });
          }
        });
      } else {
        const suggestion = {serial: vehicleNumber, name: vehicleNumber, type: 'vehicle'};
        this.setState({autocompleteValue: vehicleNumber}, () => {
          this.handleOnSelectedAutocomplete(null, {suggestion});
        });
      }
    }
  }

  getScanIntervalWidth = (start, end) => {
    const diff = moment.duration(moment(end).diff(moment(start))).asMilliseconds();
    return (diff * 100) / 43200000;
  }

  getDurationTotal = (startDateTime, endDateTime) => {
    const totalSeconds = (endDateTime.diff(startDateTime, 's') * this.state.camerasTotalSize);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - (minutes * 60);
    return `${padStart(minutes, 2, 0)}:${padStart(seconds, 2, 0)}`;
  }

  getUsername = item => {
    return ((item.metadata && item.metadata.userNameRequested) || item.userId || item.uid);
  }

  resetForm = () => {
    this.props.reset();
    this.props.loadTotalRequested();
    this.setState(initialState, this.setVehicleByQuery);
  }

  fetchTimer = null;
  handleOnFetchAutocomplete = value => {
    if (this.fetchTimer) {
      window.clearTimeout(this.fetchTimer);
    }

    this.fetchTimer = window.setTimeout(() => {
      this.props.searchVehicles({text: value});
      this.fetchTimer = null;
    }, 550);
  }

  handleOnFetchDvrAutocomplete = value => {
    if (this.fetchTimer) {
      window.clearTimeout(this.fetchTimer);
    }
    this.fetchTimer = window.setTimeout(() => {
      this.props.searchDVRs({text: value});
      this.fetchTimer = null;
    }, 550);
  }

  handleOnSelectedAutocomplete = (event, {suggestion}) => {
    this.setState({
      vehicle: suggestion,
      vehicleInvalid: false,
      cameras: cloneDeep(initialState.cameras)
    }, this.validateForm);
    this.props.loadChannels({vehicle: {...suggestion}});
    localStorage.setItem(VEHICLE_ONDEMAND_KEY, suggestion.name);
  }

  handleOnSelectedDvrAutocomplete = (event, {suggestion}) => {
    this.setState({
      dvr: suggestion,
      dvrInvalid: false,
      cameras: cloneDeep(initialState.cameras)
    }, this.validateForm);
    this.props.loadChannels({dvr: {...suggestion}});
    localStorage.setItem(VEHICLE_ONDEMAND_KEY, suggestion.name);
  }

  handleOnChangeCamera = _cameras => {
    const {cameras} = this.state;
    cameras[MACHINE_TYPE.get(this.props.machineType)] = _cameras;
    this.setState({cameras}, this.validateForm);
  }

  handleChangeWifi = event => {
    this.props.changeWifiCheckbox(event.target.checked);
  }

  handleOnBlurAutocomplete = event => {
    if (typeof event.target.value !== 'undefined') {
      this.props.clearChannels();
      this.setState({
        vehicleInvalid: true,
        dvrInvalid: true,
        dvr: null,
        vehicle: null
      });
    }
  }

  handleOnChangeStartDate = event => {
    const startDate = event.target.value;
    if (startDate.length > 0) {
      this.setState({
        startDate
      }, this.validateForm);
    } else {
      this.setState({
        startDate: moment().format('YYYY-MM-DD')
      }, this.validateForm);
    }
  }

  handleOnChangeStartTime = event => {
    const startTime = event.target.value;
    if (startTime.length > 0) {
      this.setState({
        startTime
      }, this.validateForm);
    } else {
      this.setState({
        startTime: moment().format('HH:mm:ss')
      }, this.validateForm);
    }
  }

  handleOnChangeDuration = event => {
    const duration = event.target.value;

    this.setState({
      duration
    }, this.validateForm);
  }

  handleOnBlurDuration = event => {
    let duration = event.target.value;
    duration = replace(duration, new RegExp('_', 'g'), '0');
    this.setState({duration}, this.validateForm);
  }

  handleOnSubmit = () => {
    const {
      vehicle,
      dvr,
      cameras,
      startDate,
      startTime,
      duration,
      tabIndex,
      pairingMethod
    } = this.state;
    const {
      machineType,
      vehicle: vehicleNumber,
      user: {
        userInformation: {
          userName: requestedBy
        }
      },
      wifiEnabled,
      customer
    } = this.props;
    const channels = [];
    let i = 1;
    each(cameras[MACHINE_TYPE.get(machineType)], c => {
      if (c) {
        channels.push(i);
      }
      i++; // eslint-disable-line no-plusplus
    });

    const startDateTime = moment(`${startDate} ${startTime}`);
    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);
    const customerWifi = get(customer, 'data.onDemand.wifi.enabled', false);
    const params = {
      vehicleNumber: dvr ? vehicleNumber : vehicle.name,
      startDate: moment(startDateTime).toDate().getTime(),
      endDate: moment(startDateTime).add({minutes, seconds}).toDate().getTime(),
      channels,
      machineType,
      wifi: wifiEnabled && customerWifi,
      pairingMethod,
      requestedBy
    };
    if (dvr) {
      params.serialNo = dvr.serial;
    }
    this.props.postFetchAsync(params);
    if (tabIndex === 0) {
      setTimeout(() => {
        this.props.loadQueueList({page: 0});
      }, 2000);
    }
  }

  handleOnCheckAvailability = () => {
    const {
      vehicle,
      dvr,
      workflow,
      startDate,
      startTime,
      duration,
      pairingMethod
    } = this.state;

    const startDateTime = moment(`${startDate} ${startTime}`);
    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);

    const params = {
      pairingMethod,
      startDate: moment(startDateTime).toDate().getTime(),
      endDate: moment(startDateTime).add({minutes, seconds}).toDate().getTime()
    };
    if (workflow === 'vehicle') {
      params.truckNumber = vehicle.name;
    }

    if (workflow === 'dvr') {
      params.serial = dvr.serial;
    }
    this.props.scanVideoAvailable(params);
  }

  handleOnClear = () => {
    localStorage.removeItem(VEHICLE_ONDEMAND_KEY);
    this.props.reset();
    this.setState(initialState)
  }

  handleOnConfirmation = () => {
    const {
      startDate,
      startTime,
      duration
    } = this.state;

    const startDateTime = moment(`${startDate} ${startTime}`);
    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);
    const endDateTime = moment(startDateTime).add({minutes, seconds});

    const durationTotal = this.getDurationTotal(startDateTime, endDateTime);
    this.setState({
      step: 2,
      startDateTime,
      endDateTime,
      durationTotal
    });
  }

  handleOnCancel = () => {
    this.setState({step: 1});
  }

  handleAutocompleteChange = (event, {newValue}) => {
    this.setState({autocompleteValue: newValue});
  }

  handleSerialAutocompleteChange = (event, {newValue}) => {
    this.setState({serialAutocompleteValue: newValue});
  }

  handleChangeTab = (event, value) => {
    this.setState({tabIndex: value});

    if (value === 0) {
      this.props.loadQueueList({page: 0});
    } else {
      const {previouslyRequestedQueue: {sort, sortOrder}} = this.props;
      this.props.loadPreviouslyQueueList({page: 0, sort, sortOrder});
    }
  }

  handleChangeTabIndex = index => {
    this.setState({tabIndex: index});
  }

  handleLazyLoading = () => {
    const {requestQueue} = this.props;
    const isQueueTab = (this.state.tabIndex === 0);
    if (isQueueTab && !requestQueue.isLoading && requestQueue.hasMorePages) {
      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';
      const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      if (percent > 80) {
        this.props.loadQueueList({page: requestQueue.page + 1});
      }
    }
  }

  handleCancelMedia = media => () => {
    this.props.cancelOndemandMedia(media);
  }

  handleSortTable = sort => () => {
    const {previouslyRequestedQueue: queue} = this.props;
    const sortOrder = (queue.sort === sort && queue.sortOrder === 'desc') ? 'asc' : 'desc';

    this.props.loadPreviouslyQueueList({page: 0, sort, sortOrder});
  }

  validateTime = () => {
    const {startDate, startTime, duration} = this.state;

    const durationArr = duration.split(':');
    const minutes = parseInt(durationArr[0], 10);
    const seconds = parseInt(durationArr[1], 10);

    let durationNotFound = (!Number.isInteger(minutes) || !Number.isInteger(seconds));
    if (!durationNotFound) {
      durationNotFound = (minutes <= 0 && seconds <= 0);
    }

    let durationInvalid = false;
    if (!durationNotFound) {
      durationInvalid = (minutes > 30) || (minutes === 30 && seconds > 0);
    }

    const durationInFuture = !durationInvalid && moment(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss').add({minutes, seconds}).isAfter(moment());

    this.setState({durationNotFound, durationInvalid, durationInFuture});

    return !durationNotFound && !durationInvalid && !durationInFuture;
  }

  validateForm = () => {
    const invalidDateTimeDuration = !this.validateTime();
    const {
      vehicle,
      dvr,
      workflow,
      cameras
    } = this.state;
    const camerasTotalSize = filter(cameras[MACHINE_TYPE.get(this.props.machineType)], c => {
      return c;
    }).length;
    const camerasCheckeds = camerasTotalSize > 0;
    const submitDisabled =
      workflow === 'dvr'
        ? dvr == null || !camerasCheckeds || invalidDateTimeDuration
        : vehicle == null || !camerasCheckeds || invalidDateTimeDuration;

    this.setState({
      camerasTotalSize,
      submitDisabled
    });
  }

  updateScanIntervals = props => {
    const dvrScanTimeLabels = [];
    const requestedTime = get(props, 'dvrScanRequested.start', false);

    if (props.dvrScanErrorMessage) {
      return;
    }

    if (requestedTime) {
      // labels
      const time = moment(requestedTime);
      const format = I18n.t('page.onDemand.form.videoAvailability.hourFormat');
      dvrScanTimeLabels.push(time.format(format));
      for (let i = 0; i < 12; i++) {
        dvrScanTimeLabels.push(time.add(1, 'hour').format(format));
      }
    }

    if (props.dvrScanIntervals.length > 0) {
      const dvrScanIntervals = props.dvrScanIntervals.map(e => {
        const start = e.startTime * 1000;
        const end = e.endTime * 1000;
        const intervalDateTimeFormat = I18n.t('page.onDemand.form.videoAvailability.intervalDateTimeFormat');
        return {
          start,
          end,
          label: `${moment(start).format(intervalDateTimeFormat)} - ${moment(end).format(intervalDateTimeFormat)}`,
          width: `${this.getScanIntervalWidth(start, end)}%`,
          blank: false
        };
      }, this);

      // intervals
      const dvrScanIntervalsResult = [];
      let intervalAux = props.dvrScanRequested.start;
      dvrScanIntervals.forEach(e => {
        if (intervalAux < e.start) {
          dvrScanIntervalsResult.push({
            start: intervalAux,
            end: e.start,
            width: `${this.getScanIntervalWidth(intervalAux, e.start)}%`,
            blank: true
          });
        }
        intervalAux = e.end;
        dvrScanIntervalsResult.push(e);
      });
      this.setState({
        dvrScanIntervals: dvrScanIntervalsResult,
        dvrScanTimeLabels
      });
    } else {
      this.setState({
        dvrScanIntervals: [],
        dvrScanTimeLabels
      });
    }
  }

  formatDuration = total => {
    const minutes = parseInt(total / 60, 0);
    const seconds = total % 60;

    const minStr = (minutes > 0) ? `${minutes} ${I18n.t('page.onDemand.requestQueue.minute')} ` : '';
    const secStr = (seconds > 0) ? `${seconds} ${I18n.t('page.onDemand.requestQueue.second')}` : '';

    return minStr + secStr;
  }

  openPlayer = media => () => {
    const query = {mediaId: media.id};
    this.props.push(`/player?${querystring.stringify(query)}`);
  }

  renderSortHeader = (sort, translationKey) => {
    const {previouslyRequestedQueue: queue} = this.props;
    return (
      <div className={queue.sort === sort ? 'table__sortcont table__sortcont-sorted' : 'table__sortcont'} onClick={this.handleSortTable(sort)}>
        {I18n.t(`page.onDemand.requestQueue.${translationKey}`)}
        {this.renderSortIcon(sort)}
      </div>
    );
  }

  renderSortIcon = sort => {
    const {previouslyRequestedQueue: queue} = this.props;

    let iconClass = 'sortupward';
    let iconName = 'arrow_downward';

    if (queue.sort === sort && queue.sortOrder === 'asc') {
      iconClass = 'sortdownward';
      iconName = 'arrow_downward';
    }

    return (
      <Icon className={`table__arrow ${iconClass}`}>{iconName}</Icon>
    );
  }

  renderQueueEmpty() {
    const {requestQueue} = this.props;
    if (!requestQueue.isLoading && requestQueue.list.length <= 0) {
      return (
        <TableRow>
          <TableCell>
            <span className="ondemand__infomsg"><Icon className="ondemand__msgicon">info</Icon>{I18n.t('page.onDemand.requestQueue.listEmpty')}</span>
          </TableCell>
        </TableRow>
      );
    }

    return null;
  }

  renderActionButtonFor = e => {
    const statusLower = toLower(e.status);
    const toCancelList = ['reopened', 'created', 'waiting'];

    if (toCancelList.includes(statusLower)) {
      const disabled = this.props.isInternalUser;

      return (
        <IconButton
          onClick={this.handleCancelMedia(e)}
          disabled={disabled}
          title={disabled && I18n.t('defaults.noPermissionFunctionality')}
        >
          <Icon>delete</Icon>
        </IconButton>
      );
    } else if (statusLower === 'completed') {
      return (
        <IconButton onClick={this.openPlayer(e)}>
          <Icon className="play-icon testclass">play_circle_filled</Icon>
        </IconButton>
      );
    }

    return null;
  }

  renderQueueItem = (e, i) => {
    const statusLower = toLower(e.status);

    const tblClasses = classNames({
      table__success: (statusLower === 'completed'),
      table__error: (statusLower === 'expired' || statusLower === 'notavailable')
    });

    let status = I18n.t(`page.onDemand.requestQueue.statuses.${statusLower}`);
    if (statusLower === 'created' || statusLower === 'waiting' || statusLower === 'reopened') {
      status = I18n.t('page.onDemand.requestQueue.statuses.requested');
    }

    return (
      <TableRow className={tblClasses} key={i}>
        <TableCell className="ondemand__actionscell">{this.renderActionButtonFor(e)}</TableCell>
        <TableCell className={`${tblClasses}-cell ondemand__statuscell`}>{status}</TableCell>
        <TableCell className="ondemand__vehiclecell">{e.vehicleNumber}</TableCell>
        <TableCell className="ondemand__whocell">{this.getUsername(e)}</TableCell>
        <TableCell className="ondemand__datetimecell">{moment(e.requestDate).format(I18n.t('configuration.fullDateTimeFormat'))}</TableCell>
        <TableCell className="ondemand__durationcell">{this.formatDuration(e.durationSeconds)}</TableCell>
        <TableCell className="ondemand__datetimecell">{moment(e.startDate).format(I18n.t('configuration.fullDateTimeFormat'))}</TableCell>
        <TableCell className="ondemand__smallcamicons ondemand__viewscell">
          <CameraViews channels={e.channels} machineType={get(e, 'metadata.machineType', 0)} renderIcons={false} />
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const {requestQueue, previouslyRequestedQueue, machineType} = this.props;
    const camType = MACHINE_TYPE.get(machineType);
    const {
      step,
      vehicle,
      dvr,
      workflow,
      startDateTime,
      endDateTime,
      cameras,
      duration,
      durationTotal,
      tabIndex
    } = this.state;

    const isQueueTab = (tabIndex === 0);
    const isPreviouslyTab = (tabIndex === 1);

    const cameraOptions = {
      order: this.props.channelsAvailable,
      machineType,
      cameras: cameras[camType]
    };

    const customerWifi = get(this.props, 'customer.data.onDemand.wifi.enabled', false);
    const hasWifi = get(this.props, 'hasWifi', false);
    const wifiCheckEnabled = customerWifi && hasWifi;

    /* eslint-disable max-len */
    return (
      <div>
        <ScrollEvent handleScrollCallback={this.handleLazyLoading} />
        {!this.props.isLoadingRequest && (
          <Grid container>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid item className={step === 1 ? 'ondemand' : 'hideme'}>
                <Card>
                  <CardHeader title={I18n.t('page.onDemand.form.title')} />
                  <CardContent>
                    <List>
                      {workflow === 'dvr' &&
                      <ListItem className="ondemand__listitem-first ondemand-zindex">
                        <FormControl error aria-describedby="error-text" className="ondemand__field">
                          <AutoCompleteInput
                            id="serialNumber"
                            label={I18n.t('page.onDemand.form.serialVehicleNumber')}
                            list={this.props.dvrList}
                            keepValueSelected
                            onSelect={this.handleOnSelectedDvrAutocomplete}
                            onFetchRequested={this.handleOnFetchDvrAutocomplete}
                            onBlur={this.handleOnBlurAutocomplete}
                            isLoading={this.props.isLoading}
                            value={this.state.serialAutocompleteValue}
                            onChange={this.handleSerialAutocompleteChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <Tooltip title={I18n.t('page.onDemand.form.serialVehicleNumberInfo')}>
                                  <Icon>info</Icon>
                                </Tooltip>
                              </InputAdornment>
                            }
                          />
                          {this.state.dvrInvalid && <FormHelperText id="error-text"><span>{I18n.t('page.onDemand.form.serialVehicleNumberMissing')}</span></FormHelperText>}
                        </FormControl>
                      </ListItem>
                      }
                      {workflow === 'vehicle' &&
                      <ListItem className="ondemand__listitem-first ondemand-zindex">
                        <FormControl error aria-describedby="error-text" className="ondemand__field">
                          <AutoCompleteInput
                            id="vehicleNumber"
                            label={I18n.t('page.onDemand.form.vehicleNumber')}
                            list={this.props.vehiclesList}
                            keepValueSelected
                            onSelect={this.handleOnSelectedAutocomplete}
                            onFetchRequested={this.handleOnFetchAutocomplete}
                            onBlur={this.handleOnBlurAutocomplete}
                            isLoading={this.props.isLoading}
                            value={this.state.autocompleteValue}
                            onChange={this.handleAutocompleteChange}
                          />
                          {this.state.vehicleInvalid && <FormHelperText id="error-text"><span>{I18n.t('page.onDemand.form.vehicleNumberMissing')}</span></FormHelperText>}
                        </FormControl>
                      </ListItem>
                      }
                      <ListItem className="ondemand__listitem">
                        <FormControl error aria-describedby="error-text" className="ondemand__field">
                          <ChannelsAvailable
                            options={cameraOptions}
                            onChange={this.handleOnChangeCamera}
                            isLoading={this.props.isLoading}
                          />
                          {this.props.channelsNotFound && <FormHelperText id="error-text"><span>{I18n.t('page.onDemand.form.camerasNotFound')}</span></FormHelperText>}
                        </FormControl>
                      </ListItem>
                      <ListItem>
                        <FormControl error aria-describedby="error-text" className="ondemand__date">
                          <TextField id="startDate" label={I18n.t('page.onDemand.form.date')} margin="normal" type="date" onChange={this.handleOnChangeStartDate} value={this.state.startDate} InputLabelProps={{shrink: true}} />
                        </FormControl>
                        <FormControl error aria-describedby="error-text" className="ondemand__time">
                          <TextField id="startTime" label={I18n.t('page.onDemand.form.time')} margin="normal" type="time" onChange={this.handleOnChangeStartTime} value={this.state.startTime} inputProps={{step: 1}} InputLabelProps={{shrink: true}} />
                        </FormControl>
                        <FormControl error={this.state.durationNotFound || this.state.durationInvalid || this.state.durationInFuture} aria-describedby="error-text" className="ondemand__duration">
                          <div className="ondemand__durationcont">
                            <InputLabel htmlFor="duration">{I18n.t('page.onDemand.form.duration')}</InputLabel>
                            <Input
                              id="duration"
                              value={duration}
                              onChange={this.handleOnChangeDuration}
                              onBlur={this.handleOnBlurDuration}
                              inputComponent={TextMaskMinuteSeconds}
                            />
                          </div>
                          {this.state.durationNotFound && <FormHelperText id="error-text">{I18n.t('page.onDemand.form.required')}</FormHelperText>}
                          {this.state.durationInvalid && <FormHelperText id="error-text">{I18n.t('page.onDemand.form.videoTooLongError')}</FormHelperText>}
                          {!this.state.durationNotFound && this.state.durationInFuture && <FormHelperText id="error-text">{I18n.t('page.onDemand.form.videoInFutureError')}</FormHelperText>}
                        </FormControl>
                      </ListItem>
                      <ListItem className="availablevideo">
                        {this.props.dvrScanVisible && <label className="availablevideo__label">{I18n.t('page.onDemand.form.videoAvailability.label')}</label>}
                        {this.props.dvrScanVisible &&
                          <div className="availablevideo__cont">
                            <div className="availablevideo__timerangecont">
                              {this.state.dvrScanIntervals.map((item, index) => {
                                if (!item.blank) {
                                  return (<div key={`dvrScanInterval-${index}`} className="availablevideo__timerange" title={item.label} style={{width: item.width}} />);
                                }
                                return (<div key={`dvrScanInterval-${index}`} className="availablevideo__timerange-blank" title={item.label} style={{width: item.width}} />);
                              })}
                            </div>
                            <div className="availablevideo__markercont">
                              {this.state.dvrScanTimeLabels.map((item, index) => {
                                return (
                                  <div key={`dvrScanInterval-${index}`} className="availablevideo__marker">
                                    <span className="availablevideo__time">{item}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        }
                        {this.props.dvrScanErrorMessage &&
                          <p className="availablevideo__feedback">
                            <Icon className="availablevideo__feebackicon">videocam_off</Icon>
                            {I18n.t('page.onDemand.form.videoAvailability.error')}
                          </p>
                        }
                        {(this.props.dvrScanStatus === false && this.props.dvrScanErrorMessage === false) &&
                          <p className="availablevideo__feedback">
                            <Icon className="availablevideo__feebackicon">videocam_off</Icon>
                            {I18n.t('page.onDemand.form.videoAvailability.missing')}
                          </p>
                        }
                        <Button
                          id="checkVideoAvailabilityButton"
                          disabled={this.state.submitDisabled}
                          onClick={this.handleOnCheckAvailability}
                          variant="contained"
                          color="default"
                          className="btn btn-terciery"
                        >
                          {this.props.dvrScanLoading &&
                            <CircularProgress className="availablevideo__loading" />
                          }
                          {!this.props.dvrScanLoading &&
                            <Icon className="availablevideo__cameicon">videocam</Icon>
                          }
                          {I18n.t('page.onDemand.form.videoAvailability.button')}
                        </Button>
                        <div className="col-md-12" />
                      </ListItem>
                    </List>
                  </CardContent>
                  <CardActions className="ondemand__actions">
                    <label className={!wifiCheckEnabled && 'hideme'}>
                      {I18n.t('page.onDemand.form.wifi')}
                      <Checkbox
                        id="wifiUpload"
                        disabled={!wifiCheckEnabled}
                        checked={this.props.wifiEnabled}
                        onChange={this.handleChangeWifi}
                      />
                    </label>
                    <Button id="onClearSubmit" onClick={this.handleOnClear} variant="contained" color="default" className="btn btn-secondary">{I18n.t('page.onDemand.form.clear')}</Button>
                    <Button id="onDemandSubmit" disabled={this.state.submitDisabled} onClick={this.handleOnConfirmation} variant="contained" color="primary" className="btn btn-primary">{I18n.t('page.onDemand.form.submit')}</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item className={!this.props.fetchSuccess && step === 2 ? 'ondemand' : 'hideme'}>
                <Card>
                  <CardHeader title={I18n.t('page.onDemand.confirmation.title')} />
                  <CardContent>
                    <List>
                      <ListItem className="ondemand__row">
                        <Grid item md={6}>
                          {workflow === 'dvr' && I18n.t('page.onDemand.form.serialVehicleNumber')}
                          {workflow === 'vehicle' && I18n.t('page.onDemand.form.vehicleNumber')}
                        </Grid>
                        <Grid item md={6}>
                          {workflow === 'dvr' && dvr && dvr.name}
                          {workflow === 'vehicle' && vehicle && vehicle.name}
                        </Grid>
                      </ListItem>
                      <ListItem className="ondemand__row">
                        <Grid item md={6}>
                          {I18n.t('page.onDemand.form.date')}
                        </Grid>
                        <Grid item md={6}>
                          {startDateTime.format(I18n.t('configuration.dateFormat'))}
                          {
                            (startDateTime.day() !== endDateTime.day()) && (
                              ` - ${endDateTime.format(I18n.t('configuration.dateFormat'))}`
                            )
                          }
                        </Grid>
                      </ListItem>
                      <ListItem className="ondemand__row">
                        <Grid item md={6}>
                          {I18n.t('page.onDemand.form.time')}
                        </Grid>
                        <Grid item md={6}>
                          {startDateTime.format(I18n.t('configuration.timeFormat'))} - {endDateTime.format(I18n.t('configuration.timeFormat'))}
                        </Grid>
                      </ListItem>
                      <ListItem className="ondemand__row">
                        <Grid item md={6}>
                          {I18n.t('page.onDemand.form.duration')}
                        </Grid>
                        <Grid item md={6}>
                          {duration}
                        </Grid>
                      </ListItem>
                      <ListItem className="ondemand__row">
                        <Grid item md={6}>
                          {I18n.t('page.onDemand.confirmation.selectedCameras')}
                        </Grid>
                        <Grid item md={6} className="ondemand__selectedviews-cont">
                          {cameras[camType].front &&
                            <label>
                              <div className="ondemand__cameraviews ff-cam">
                                <Icon className="videothumb__camview">arrow_upward</Icon>{I18n.t('page.onDemand.form.camera.ff')}
                              </div>
                            </label>
                          }
                          {cameras[camType].back &&
                            <label>
                              <div className="ondemand__cameraviews re-cam">
                                <Icon className="videothumb__camview">arrow_downward</Icon>{I18n.t('page.onDemand.form.camera.re')}
                              </div>
                            </label>
                          }
                          {cameras[camType].left &&
                            <label>
                              <div className="ondemand__cameraviews dr-cam">
                                <Icon className="videothumb__camview">arrow_back</Icon>{I18n.t('page.onDemand.form.camera.dr')}
                              </div>
                            </label>
                          }
                          {cameras[camType].right &&
                            <label>
                              <div className="ondemand__cameraviews pa-cam">
                                {I18n.t('page.onDemand.form.camera.pa')}<Icon className="videothumb__camview">arrow_forward</Icon>
                              </div>
                            </label>
                          }
                        </Grid>
                      </ListItem>
                      <ListItem className="ondemand__row ondemand__totalcharges">
                        <Grid item md={6}>
                          {I18n.t('page.onDemand.confirmation.totalMinutes')}
                        </Grid>
                        <Grid item md={6}>
                          {durationTotal}
                        </Grid>
                      </ListItem>
                      <ListItem className="ondemand__inforow">
                        <Grid item md={12} className="ondemand__info">
                          <Icon className="ondemand__info-icon">info</Icon><span>{I18n.t('page.onDemand.chargeWarning')}</span>
                        </Grid>
                      </ListItem>
                      {this.props.requestedSeconds != null && (
                        <ListItem className="ondemand__info">
                          <Icon className="ondemand__timeicon">access_time</Icon>
                          <label>
                            <Translate
                              value="page.onDemand.confirmation.totalMinutesRequested"
                              dangerousHTML
                              count={Math.round(this.props.requestedSeconds / 60)}
                            />
                          </label>
                        </ListItem>
                      )}
                    </List>
                  </CardContent>
                  <CardActions className="ondemand__actions">
                    <Button id="onDemandCancel" onClick={this.handleOnCancel} variant="contained" color="default" className="btn btn-secondary">{I18n.t('page.onDemand.form.cancel')}</Button>
                    <Button id="onDemandConfirm" disabled={this.props.isInternalUser} onClick={this.handleOnSubmit} variant="contained" color="primary" className="btn btn-primary">{I18n.t('page.onDemand.form.confirm')}</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item className={this.props.fetchSuccess && step === 2 ? 'ondemand' : 'hideme'}>
                <Card>
                  <CardContent>
                    <ListItem className="ondemand__centerrow">
                      <Icon className="ondemand__successiconrow">check_circle</Icon>
                    </ListItem>
                    <List>
                      <ListItem className="ondemand__centerrow">
                        {I18n.t('page.onDemand.successMessage')}
                      </ListItem>
                    </List>
                  </CardContent>
                  <CardActions className="ondemand__actions">
                    <Button id="onDemandRequestAnother" onClick={this.resetForm} variant="contained" color="default" className="btn btn-secondary">{I18n.t('page.onDemand.requestAnother')}</Button>
                    <Button id="onDemandGoToLibrary" onClick={this.props.goToLibrary} variant="contained" color="primary" className="btn btn-primary">{I18n.t('page.onDemand.goToLibrary')}</Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12} className={(isQueueTab && requestQueue.list.length > 0) || (isPreviouslyTab && previouslyRequestedQueue.list.length > 0) ? 'ondemand__parent' : 'ondemand__parent__empty'}>
              <div className="ondemand__tabs-cont">
                <AppBar position="static" color="default">
                  <Tabs
                    value={tabIndex}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label={I18n.t('page.onDemand.tabs.requestQueue')} id="requestQueueTab" />
                    <Tab label={I18n.t('page.onDemand.tabs.previouslyRequested')} id="previouslyRequestedTab" />
                  </Tabs>
                </AppBar>
                {((isQueueTab && requestQueue.list.length > 0) || (isPreviouslyTab && previouslyRequestedQueue.list.length > 0)) && (
                  <Table className="table__contnh ondemand__tableheader">
                    <TableHead>
                      <TableRow className="table__head">
                        <TableCell className="ondemand__actionscell">&nbsp;</TableCell>
                        <TableCell className="ondemand__statuscell">{I18n.t('page.onDemand.requestQueue.status')}</TableCell>
                        <TableCell className="ondemand__vehiclecell">{I18n.t('page.onDemand.requestQueue.vehicleNumber')}</TableCell>
                        <TableCell className="ondemand__whocell">{I18n.t('page.onDemand.requestQueue.whoRequested')}</TableCell>
                        <TableCell className="ondemand__datetimecell">
                          {isPreviouslyTab && this.renderSortHeader('requestDate', 'dateTimeRequested')}
                          {isQueueTab && (
                            <React.Fragment>
                              {I18n.t('page.onDemand.requestQueue.dateTimeRequested')}
                            </React.Fragment>
                          )}
                        </TableCell>
                        <TableCell className="ondemand__durationcell">
                          {isPreviouslyTab && this.renderSortHeader('durationSeconds', 'durationRequested')}
                          {isQueueTab && (
                            <React.Fragment>
                              {I18n.t('page.onDemand.requestQueue.durationRequested')}
                            </React.Fragment>
                          )}
                        </TableCell>
                        <TableCell className="ondemand__datetimecell">
                          {isPreviouslyTab && this.renderSortHeader('startDate', 'videoStartTime')}
                          {isQueueTab && (
                            <React.Fragment>
                              {I18n.t('page.onDemand.requestQueue.videoStartTime')}
                            </React.Fragment>
                          )}
                        </TableCell>
                        <TableCell className="ondemand__viewscell">{I18n.t('page.onDemand.requestQueue.cameraViewsRequested')}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                )}
              </div>

              <SwipeableViews
                index={tabIndex}
                onChangeIndex={this.handleChangeTabIndex}
              >
                <TabContainer>
                  <ProgressSpinner show={requestQueue.isLoading} />

                  <Table className="table__contnh">
                    <TableBody>
                      {this.renderQueueEmpty()}

                      {
                        requestQueue.list.map((e, i) => (
                          this.renderQueueItem(e, i)
                        ))
                      }
                    </TableBody>
                  </Table>
                </TabContainer>

                <TabContainer>
                  <PreviouslyQueue
                    isEnabled={isPreviouslyTab}
                    isLoading={previouslyRequestedQueue.isLoading}
                    canChangeStatus={!this.props.isInternalUser}
                  />
                </TabContainer>
              </SwipeableViews>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default DesktopOnDemand;
