import React from 'react';
import {I18n} from 'react-redux-i18n';
import {toLower, get} from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Icon} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CameraViews from './CameraViews';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import {formatDuration} from '@peoplenet/vi-components-ui/src/app/formatter';

const getUsername = item => {
  return ((item.metadata && item.metadata.userNameRequested) || item.userId || item.uid);
};

const getTranslatedStatus = status => {
  let lowerStatus = toLower(status);
  if (lowerStatus === 'created' || lowerStatus === 'waiting' || lowerStatus === 'reopened') {
    lowerStatus = 'requested';
  }
  return I18n.t(`page.onDemand.requestQueue.statuses.${lowerStatus}`);
};

export default class OnDemandDetails extends React.Component {

  listenHistoryChange = event => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = () => {
      this.props.onClose();
    };
  }

  stopListeningHistoryChange = () => {
    window.onpopstate = () => {};
    this.normalizeNavigation();
  }

  normalizeNavigation() {
    window.history.go(1);
    window.history.go(-1);
  }

  render() {
    const {requestItem, open, onClose} = this.props;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        onEntered={this.listenHistoryChange}
        onExit={this.stopListeningHistoryChange}
        className="libraryfilter libraryfilter__details"
      >
        <AppBar position="static" color="default">
          <Toolbar>
            <IconButton onClick={onClose}>
              <Icon>arrow_back</Icon>
            </IconButton>

            <Typography variant="title" type="title" color="inherit">{I18n.t('page.onDemand.requestQueue.details')}</Typography>
          </Toolbar>
        </AppBar>

        <Table className="table__details ondemand__details-mobile">
          <TableBody>
            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.status')}</TableCell>
              <TableCell>{getTranslatedStatus(requestItem.status)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.vehicleNumber')}</TableCell>
              <TableCell>{requestItem.vehicleNumber}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.whoRequested')}</TableCell>
              <TableCell>{getUsername(requestItem)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.dateTime')}</TableCell>
              <TableCell>{moment(requestItem.requestDate).format(I18n.t('configuration.fullDateTimeFormat'))}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.duration')}</TableCell>
              <TableCell>{formatDuration(requestItem.durationSeconds)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.videoStartTime')}</TableCell>
              <TableCell>{moment(requestItem.startDate).format(I18n.t('configuration.fullDateTimeFormat'))}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{I18n.t('page.onDemand.requestQueue.cameraViewsLabel')}</TableCell>
              <TableCell className="ondemand__smallcamicons ondemand__viewscell">
                <div className="ondemand__camerasmobile-cont">
                  <div className="ondemand__camerasmobile">
                    <CameraViews channels={requestItem.channels} machineType={get(requestItem, 'metadata.machineType', 0)} />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Dialog>
    );
  }
}
