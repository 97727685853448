import {
  TAG_POST_REQUEST,
  TAG_POST_SUCCESS,
  TAG_POST_FAIL
} from '@peoplenet/vi-components-ui/src/actions/constants';
import VideoTaggingService from './service/VideoTaggingService';

const postTag = (mediaId, params) => async dispatch => {
  try {
    dispatch({type: TAG_POST_REQUEST});
    const response = await VideoTaggingService.postTag(mediaId, params);
    dispatch({type: TAG_POST_SUCCESS, payload: {response, mediaId, params}});
  } catch (exception) {
    dispatch({type: TAG_POST_FAIL, exception});
  }
};

export default (mediaId, params) => dispatch => {
  return dispatch(postTag(mediaId, params));
};
