/* eslint-disable max-len */

import {get, filter, toInteger, concat} from 'lodash';
import {
  SEARCH_VEHICLE_REQUEST,
  SEARCH_VEHICLE_SUCCESS,
  SEARCH_VEHICLE_FAIL,
  SEARCH_DVR_REQUEST,
  SEARCH_DVR_SUCCESS,
  SEARCH_DVR_FAIL,
  LOAD_CHANNELS_REQUEST,
  LOAD_CHANNELS_SUCCESS,
  LOAD_CHANNELS_FAIL,
  GET_MATCH_DVR_REQUEST,
  GET_MATCH_DVR_SUCCESS,
  GET_MATCH_DVR_FAIL,
  CLEAR_CHANNELS,
  CLEAR_TABLES,
  CLEAR_TABLES_LIST,
  GET_TOTAL_REQUEST,
  GET_TOTAL_SUCCESS,
  GET_TOTAL_FAIL,
  FETCH_ASYNC_REQUEST,
  FETCH_ASYNC_SUCCESS,
  FETCH_ASYNC_FAIL,
  RESET,
  DVR_SCAN_VIDEO_REQUEST,
  DVR_SCAN_VIDEO_SUCCESS,
  DVR_SCAN_VIDEO_FAIL,
  GET_QUEUE_LIST_REQUEST,
  GET_QUEUE_LIST_SUCCESS,
  GET_QUEUE_LIST_FAIL,
  GET_PREVIOUSLY_QUEUE_LIST_REQUEST,
  GET_PREVIOUSLY_QUEUE_LIST_SUCCESS,
  GET_PREVIOUSLY_QUEUE_LIST_FAIL,
  RETRY_ONDEMAND_MEDIA_SUCCESS,
  RETRY_ONDEMAND_MEDIA_FAIL,
  CANCEL_ONDEMAND_MEDIA_SUCCESS,
  CANCEL_ONDEMAND_MEDIA_FAIL,
  CHANGE_WIFI_CHECKBOX
} from './OnDemandActionsType';

const initialState = {
  vehiclesList: [],
  dvrList: [],
  channelsAvailable: [],
  dvrScanLoading: false,
  dvrScanVisible: false,
  dvrScanErrorMessage: false,
  dvrScanStatus: null,
  dvrScanIntervals: [],
  dvrNotMatch: false,
  hasWifi: false,
  wifiEnabled: false,
  machineType: 0,
  vehicle: null,
  requestedSeconds: null,
  channelsNotFound: false,
  vehicleNotFound: false,
  dvrNotFound: false,
  fetchSuccess: false,
  fetchError: false,
  actionError: false,
  requestQueue: {
    isLoading: false,
    list: [],
    page: 0,
    pages: 0,
    hasMorePages: false,
    isCanceled: false
  },
  previouslyRequestedQueue: {
    isLoading: false,
    list: [],
    sort: 'requestDate',
    sortOrder: 'desc',
    page: 0,
    pages: 0,
    hasMorePages: false,
    retried: false,
    isRetried: false
  }
};

export default function onDemand(state = initialState, action) {
  const type = get(action, 'type');
  const media = get(action, 'media', {});
  let {requestQueue, previouslyRequestedQueue, vehicleNotFound} = state;

  const previouslyQueueKeepingSort = {
    ...initialState.previouslyRequestedQueue,
    sort: previouslyRequestedQueue.sort,
    sortOrder: previouslyRequestedQueue.sortOrder
  };

  state.actionError = false;

  let code;
  let currentPage;
  let hasMorePages = false;
  let queueList = [];
  switch (type) {
    case SEARCH_DVR_REQUEST:
      return {
        ...state,
        isLoading: true,
        dvrList: initialState.dvrList,
        dvrNotFound: initialState.dvrNotFound,
        channelsNotFound: initialState.channelsNotFound
      };
    case SEARCH_DVR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dvrList: get(action, 'payload', initialState.dvrList),
        dvrNotFound: initialState.dvrNotFound,
        channelsNotFound: initialState.channelsNotFound,
        dvrScanVisible: initialState.dvrScanVisible
      };
    case SEARCH_DVR_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMessage: true,
        dvrNotFound: true
      };
    case SEARCH_VEHICLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        vehiclesList: initialState.vehiclesList,
        vehicleNotFound: initialState.vehicleNotFound,
        channelsNotFound: initialState.channelsNotFound
      };
    case SEARCH_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehiclesList: get(action, 'payload', initialState.vehiclesList),
        vehicleNotFound: initialState.vehicleNotFound,
        channelsNotFound: initialState.channelsNotFound,
        dvrScanVisible: initialState.dvrScanVisible
      };
    case SEARCH_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMessage: true,
        vehicleNotFound: true
      };
    case LOAD_CHANNELS_REQUEST:
      return {
        ...state,
        isLoading: true,
        dvrScanVisible: initialState.dvrScanVisible,
        channelsAvailable: initialState.channelsAvailable,
        hasWifi: get(action, 'payload.hasWifi', initialState.hasWifi),
        machineType: get(action, 'payload.dvrIdentification.machineType', initialState.machineType),
        channelsNotFound: initialState.channelsNotFound
      };
    case LOAD_CHANNELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        channelsAvailable: get(action, 'payload.data.channels', initialState.channelsAvailable),
        vehicle: get(action, 'payload.data.vehicle', initialState.vehicle),
        hasWifi: get(action, 'payload.data.hasWifi', initialState.hasWifi),
        wifiEnabled: get(action, 'payload.data.hasWifi', initialState.hasWifi),
        machineType: get(action, 'payload.data.dvrIdentification.machineType', initialState.machineType),
        channelsNotFound: initialState.channelsNotFound,
        vehicleNotFound: false
      };
    case LOAD_CHANNELS_FAIL:
      code = get(action, 'payload.meta.code', null);
      if (code === 0 || code === 2) {
        vehicleNotFound = true;
      }
      return {
        ...state,
        isLoading: false,
        errorMessage: true,
        vehicleNotFound,
        channelsNotFound: true
      };
    case CHANGE_WIFI_CHECKBOX:
      return {
        ...state,
        wifiEnabled: get(action, 'payload.wifi', false)
      };
    case CLEAR_CHANNELS:
      return {
        ...state,
        isLoading: false,
        isLoadingRequest: false,
        channelsAvailable: initialState.channelsAvailable,
        hasWifi: initialState.hasWifi,
        machineType: initialState.machineType,
        channelsNotFound: initialState.channelsNotFound
      };
    case GET_TOTAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        requestedSeconds: initialState.requestedSeconds
      };
    case GET_TOTAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestedSeconds: get(action, 'payload.seconds', initialState.requestedSeconds)
      };
    case GET_TOTAL_FAIL:
      return {...state, isLoading: false, errorMessage: true};
    case FETCH_ASYNC_REQUEST:
      return {
        ...state,
        isLoadingRequest: true,
        fetchSuccess: initialState.fetchSuccess,
        fetchError: initialState.fetchError
      };
    case FETCH_ASYNC_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        fetchSuccess: true,
        fetchError: initialState.fetchError,
        dvrScanVisible: false,
        dvrScanErrorMessage: false,
        dvrScanIntervals: [],
        dvrScanRequested: {},
        dvrScanStatus: false,
        requestQueue: initialState.requestQueue,
        previouslyRequestedQueue: previouslyQueueKeepingSort
      };
    case FETCH_ASYNC_FAIL:
      return {
        ...state,
        isLoadingRequest: false,
        errorMessage: true,
        fetchError: true
      };
    case DVR_SCAN_VIDEO_REQUEST:
      return {
        ...state,
        dvrScanLoading: true
      };
    case DVR_SCAN_VIDEO_SUCCESS:
      return {
        ...state,
        dvrScanLoading: false,
        dvrScanVisible: true,
        dvrScanErrorMessage: false,
        dvrScanIntervals: action.payload.intervals,
        dvrScanRequested: action.payload.requested,
        dvrScanStatus: action.payload.avaliable
      };
    case DVR_SCAN_VIDEO_FAIL:
      return {
        ...state,
        dvrScanLoading: false,
        dvrScanVisible: false,
        dvrScanErrorMessage: true,
        dvrScanIntervals: [],
        dvrScanRequested: {},
        dvrScanStatus: false
      };
    case GET_QUEUE_LIST_REQUEST:
      requestQueue.isLoading = true;
      return {
        ...state,
        requestQueue,
        isCanceled: false,
        previouslyRequestedQueue: previouslyQueueKeepingSort
      };
    case GET_QUEUE_LIST_SUCCESS:
      currentPage = toInteger(get(action, 'payload.meta.paging.page', 0));
      hasMorePages = get(action, 'payload.meta.paging.pages', 1) > currentPage + 1;

      queueList = get(action, 'payload.data', initialState.requestQueue.list);

      requestQueue = {
        list: currentPage > 0 ? concat(state.requestQueue.list, queueList) : queueList,
        page: currentPage,
        pages: get(action, 'payload.meta.paging.pages', 0),
        isLoading: false,
        hasMorePages,
        isCanceled: false
      };

      return {
        ...state,
        requestQueue,
        previouslyRequestedQueue: {...previouslyQueueKeepingSort, isLoading: false}
      };
    case GET_QUEUE_LIST_FAIL:
      requestQueue.isLoading = false;
      return {
        ...state,
        requestQueue,
        errorMessage: true,
        isCanceled: false
      };
    case GET_PREVIOUSLY_QUEUE_LIST_REQUEST:
      previouslyRequestedQueue.isLoading = true;
      previouslyRequestedQueue.sort = get(action, 'payload.sort', 'requestDate');
      previouslyRequestedQueue.sortOrder = get(action, 'payload.sortOrder', 'desc');
      if (get(action, 'payload.page', 0) === 0) {
        previouslyRequestedQueue.list = initialState.previouslyRequestedQueue.list;
      }

      return {
        ...state,
        previouslyRequestedQueue,
        isRetried: false,
        requestQueue: initialState.requestQueue
      };
    case GET_PREVIOUSLY_QUEUE_LIST_SUCCESS:
      currentPage = toInteger(get(action, 'payload.queueList.meta.paging.page', 0));
      hasMorePages = get(action, 'payload.queueList.meta.paging.pages', 1) > currentPage + 1;

      queueList = get(action, 'payload.queueList.data', initialState.previouslyRequestedQueue.list);

      previouslyRequestedQueue = {
        list: currentPage > 0 ? concat(state.previouslyRequestedQueue.list, queueList) : queueList,
        page: currentPage,
        sort: get(action, 'payload.params.sort', 'requestDate'),
        sortOrder: get(action, 'payload.params.sortOrder', 'desc'),
        pages: get(action, 'payload.queueList.meta.paging.pages', 0),
        isLoading: false,
        hasMorePages,
        isRetried: false
      };

      return {
        ...state,
        previouslyRequestedQueue,
        requestQueue: {...initialState.requestQueue, isLoading: false}
      };

    case GET_PREVIOUSLY_QUEUE_LIST_FAIL:
      previouslyRequestedQueue.isLoading = false;
      return {...state, previouslyRequestedQueue, errorMessage: true};

    case RETRY_ONDEMAND_MEDIA_SUCCESS:
      previouslyRequestedQueue = {...state.previouslyRequestedQueue};
      previouslyRequestedQueue.list = filter(previouslyRequestedQueue.list, it => it.id !== media.id);
      return {
        ...state,
        isRetried: true,
        previouslyRequestedQueue
      };

    case RETRY_ONDEMAND_MEDIA_FAIL:
      return {
        ...state,
        actionError: true
      };

    case CANCEL_ONDEMAND_MEDIA_SUCCESS:
      requestQueue = {...state.requestQueue};
      requestQueue.list = filter(requestQueue.list, it => it.id !== media.id);
      return {
        ...state,
        requestQueue
      };

    case CANCEL_ONDEMAND_MEDIA_FAIL:
      return {
        ...state,
        actionError: true
      };
    case GET_MATCH_DVR_REQUEST:
      return {
        ...state,
        dvrNotMatch: false
      };
    case GET_MATCH_DVR_SUCCESS:
      return {
        ...state,
        dvrNotMatch: false
      };
    case GET_MATCH_DVR_FAIL:
      return {
        ...state,
        dvrNotMatch: true
      };
    case RESET:
      return {
        ...state,
        ...initialState,
        requestQueue,
        previouslyRequestedQueue
      };
    case CLEAR_TABLES:
      return {
        ...state,
        requestQueue: initialState.requestQueue,
        previouslyRequestedQueue: initialState.previouslyRequestedQueue
      };
    case CLEAR_TABLES_LIST:
      return {
        ...state,
        requestQueue: initialState.requestQueue,
        previouslyRequestedQueue: previouslyQueueKeepingSort
      };
    default:
      return state;
  }
}
