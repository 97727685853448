import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import {get, filter} from 'lodash';
import ReactGA from 'react-ga';
import {withStyles} from '@material-ui/core/styles';
import {changeCustomer, loadCustomers, clear} from '../SwitchCustomerActions';


export class SwitchCustomerAutocomplete extends React.Component {
  static propTypes = {
    select: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  }

  state = {
    suggestions: [],
    value: ''
  }

  componentWillMount() {
    this.props.loadCustomers();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({suggestions: get(nextProps, 'customers', [])});
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onSuggestionsFetchRequested = ({value}) => {
    let suggestions = this.props.customers;
    if (value) {
      const matcher = value.replace(/(\.|\*|\[|\]|\(|\)|\\|\/)/ig, '\\$1').replace(/\s/ig, '.*?\\s(\\[)?');
      const regex = new RegExp(matcher, 'i');

      suggestions = filter(this.props.customers, it => {
        const fullName = `${it.name} [${it.id}]`;
        return regex.test(fullName);
      });
    }
    this.setState({suggestions});
  }

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []});
  }

  onChange = (e, {newValue}) => {
    this.setState({value: newValue});
  }

  getSuggestionValue = suggestion => {
    return suggestion.name;
  }

  handleGA = gaAction => {
    ReactGA.event({
      category: 'Main Menu',
      action: gaAction,
      label: 'Drawer'
    });
  }

  handleSelected = (event, {suggestion}) => {
    this.handleGA('Customer Account');
    this.props.select(suggestion);
    this.props.onChange(suggestion);
  }

  shouldRenderSuggestions = () => true

  renderSuggestionsContainer(options) {
    const {containerProps, children} = options;

    return (
      <Paper {...containerProps} square style={{zIndex: 999, marginTop: '30px', marginBottom: '58px', overflow: 'auto'}}>
        {children}
      </Paper>
    );
  }

  renderSuggestion = (suggestion, {query, isHighlighted}) => {
    const fullName = `${suggestion.name} [${suggestion.id}]`;
    const matches = match(fullName, query);
    const parts = parse(fullName, matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div data-type={suggestion.type} style={{display: 'flex'}}>
          {
            parts.map((part, index) => {
              const text = part.text.replace(/ /g, '\u00a0');
              if (part.highlight) {
                return <span key={index}>{text}</span>;
              }
              return <strong key={index}>{text}</strong>;
            })
          }
        </div>
      </MenuItem>
    );
  }

  renderInput = inputProps => {
    const {
      home,
      value,
      ref,
      ...other
    } = inputProps;
    return (
      <TextField
        autoFocus={home}
        className="mainmenu__accountfield"
        value={value}
        label={get(this.props.customer, 'data.customerInfo.companyName')}
        placeholder="Customer account"
        inputRef={ref}
        InputProps={{

          className: 'mainmenu__accountfield__input',
          id: 'changeCustomer',
          ...other
        }}
      />
    );
  }

  render() {
    const inputProps = {
      onChange: this.onChange,
      value: this.state.value
    };

    const {classes} = this.props;
    return (
      <Autosuggest
        classes={{
          root: classes.root
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        suggestions={this.state.suggestions}
        renderInputComponent={this.renderInput}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.handleSelected}
      />
    );
  }
}

const styleSheet = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%'
  }
});

export const mapStateToProps = state => {
  const {switchCustomer, customer} = state;
  return {...switchCustomer, customer};
};

export const mapDispatchToProps = dispacher => ({
  select: customer => dispacher(changeCustomer(customer)),
  loadCustomers: () => dispacher(loadCustomers()),
  clear: () => dispacher(clear())
});

const stylished = withStyles(styleSheet)(SwitchCustomerAutocomplete);
export default connect(mapStateToProps, mapDispatchToProps)(stylished);
