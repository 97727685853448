/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {ToastContainer} from 'react-toastify';

import {get} from 'lodash';
import trimbleAuth from '../components/Auth/TrimbleId';
import MainMenu from './MainMenu';
import Settings from './Settings';
import NotificationMenu from '../notification/NotificationMenu';
import {isBeta} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import TrimbleLogo from '../images/trimblelogo.svg';

class ViAppBar extends React.Component {
  navigateHome = () => this.props.dispatch(push('library'));

  navigateHaystack = () => {
    const customerId = get(this.props, 'userInformation.customerId', null);
    const userId = get(this.props, 'userInformation.userId', null);
    const systemId = get(this.props, 'system.settings.systemId', null);

    if (systemId === 'togs') {
      const togsToken = localStorage.getItem('togstoken');
      localStorage.clear();
      window.location.assign(`${API_URL}/togs/ui/?isBeta=false&token=${togsToken}&customerId=${customerId}#/`);
    } else {
      trimbleAuth.logout();
      window.location.assign(`${API_URL}/h/?ref=webapp&customerId=${customerId}&userId=${userId}#/`);
    }
  }

  renderSettings = () => {
    if (!this.props.configuration.isMobile) {
      return <Settings />;
    }

    return null;
  };

  renderNotificationsMenu = () => {
    if (!this.props.configuration.isMobile) {
      return <NotificationMenu />;
    }

    return null;
  }

  render() {
    const trimbleLogo = this.props.configuration.isMobile ? (
      ''
    ) : (
      <img
        src={TrimbleLogo}
        onClick={this.navigateHome}
        style={{cursor: 'pointer'}}
        alt="Trimble"
      />
    );

    return (
      <div>
        <AppBar position="static" className="viappbar__header">
          <ToastContainer position="bottom-center" style={{width: 'auto'}} />
          <Toolbar className="viappbar__toolbar">
            <div className="mainmenu">
              <MainMenu auth={this.props.auth} />
              {trimbleLogo}
            </div>
            <div className="viappbar__titlecont">
              <Typography
                type="title"
                variant="title"
                color="inherit"
                className="flex"
              >
                {this.props.title}
              </Typography>
            </div>
            <div className="viappbar__actions">
              {this.props.children}
              {this.props.isBeta && (
                <Button onClick={this.navigateHaystack} variant="contained" color="primary" className="btn btn-primary">BACK TO CURRENT VERSION</Button>
              )}
              {this.renderNotificationsMenu()}
              {this.renderSettings()}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    userInformation: get(state, 'user.userInformation', null),
    isBeta: isBeta(state)
  };
}

export default connect(mapStateToProps)(ViAppBar);
