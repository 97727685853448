import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {isEqual} from 'lodash';
import {I18n} from 'react-redux-i18n';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    margin: 0
  },
  textField: {
    width: '100%'
  }
});

export class DateAndTimePickers extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    mode: PropTypes.string,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    value: PropTypes.number
  }

  static defaultProps = {
    startTime: moment().subtract(29, 'days').startOf('day').valueOf(),
    endTime: moment().endOf('day').valueOf(),
    label: 'Last 30 Days'
  }

  constructor(props) {
    super(props);
    const {
      mode
    } = props;

    this.state = {
      open: false,
      mode
    };
  }

  componentWillReceiveProps(props) {
    if (!isEqual(this.props.value, props.value)) {
      const {value, min, max} = props;
      // When value is on the border values, probably, it fallbacked to it
      const error = (value === min || value === max);
      this.setState({error});
    }
  }

  handleChange = ({target}) => {
    const {value} = target;
    if (value) {
      let error = true;
      let time = moment(value, 'YYYY-MM-DD');
      if (this.state.mode === 'start') {
        time = time.startOf('day');
      } else if (this.state.mode === 'end') {
        time = time.endOf('day');
      }
      time = time.valueOf();

      if (time >= this.props.min && time <= this.props.max) {
        error = false;
        this.props.onChange(time);
      } else if (time >= this.props.min) {
        this.props.onChange(this.props.max);
      } else if (time <= this.props.max) {
        this.props.onChange(this.props.min);
      }
      this.setState({error});
    }
  }

  render() {
    const {classes, id} = this.props;
    return (
      <FormControl className={classes.container} noValidate error>
        <TextField
          error={this.state.error}
          id="error"
          label={this.props.label}
          type="date"
          className={classes.textField}
          onChange={this.handleChange}
          value={moment(this.props.value).format('YYYY-MM-DD')}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            min: this.props.min,
            max: this.props.max,
            pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}',
            id
          }}
        />
        {this.state.error &&
          <FormHelperText error>
            {I18n.t('filter.date.error.within180days')}
          </FormHelperText>
        }
      </FormControl>
    );
  }
}

DateAndTimePickers.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(DateAndTimePickers);
