import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';

import {hasAccess} from '@peoplenet/vi-components-ui/src/app/user';
import {isMobile} from '@peoplenet/vi-components-ui/src/app/configuration/util';
import DesktopOnDemand from './DesktopOnDemand';
import MobileOnDemand from './MobileOnDemand';

import {
  searchVehicles,
  searchDVRs,
  loadChannels,
  loadQueueList,
  loadPreviouslyQueueList,
  clearChannels,
  clearTables,
  clearTablesList,
  loadTotalRequested,
  postFetchAsync,
  reset,
  scanVideoAvailable,
  goToLibrary,
  cancelOndemandMedia,
  changeWifiCheckbox
} from '../OnDemandActions';
import {logout} from '@peoplenet/vi-components-ui/src/app/user/UserActions';

class OnDemand extends React.Component {
  render() {
    const props = {
      ...this.props,
      ...this.state
    };
    return props.isMobile ? <MobileOnDemand {...props} /> : <DesktopOnDemand {...props} />;
  }
}

function mapStateToProps(state) {
  const {onDemand, customer, user} = state;
  const isInternalUser = hasAccess(['videoIntelligenceInternal']);
  return {
    ...onDemand,
    ...state.i18n.locale,
    isInternalUser,
    customer,
    user,
    isMobile: isMobile(state)
  };
}

export default connect(mapStateToProps, {
  searchVehicles,
  searchDVRs,
  loadChannels,
  loadQueueList,
  loadPreviouslyQueueList,
  clearChannels,
  clearTables,
  clearTablesList,
  loadTotalRequested,
  postFetchAsync,
  logout,
  reset,
  push,
  scanVideoAvailable,
  goToLibrary,
  cancelOndemandMedia,
  changeWifiCheckbox
})(OnDemand);
