import {push} from 'connected-react-router';
import querystring from 'querystring';
import * as qs from 'query-string';
import {CALL_API} from '../middleware/api';
import PlayerService from '../player/service/PlayerService';

import {
  MEDIA_GET_REQUEST,
  MEDIA_GET_SUCCESS,
  MEDIA_GET_FAIL,
  MEDIA_FLAG_UPDATE,
  REVIEW_SEARCH_REQUEST,
  REVIEW_SEARCH_SUCCESS,
  REVIEW_SEARCH_FAIL,
  REVIEW_GET_REQUEST,
  REVIEW_GET_SUCCESS,
  REVIEW_GET_FAIL,
  COACH_GET_REQUEST,
  COACH_GET_SUCCESS,
  COACH_GET_FAIL,
  COACH_SET_REQUEST,
  COACH_SET_SUCCESS,
  COACH_SET_FAIL,
  CLEAR_PLAYER,
  COMMENTS_GET_REQUEST,
  COMMENTS_GET_SUCCESS,
  COMMENTS_GET_FAIL,
  COMMENTS_POST_SUCCESS,
  COMMENTS_DELETE_SUCCESS,
  REVIEW_POST_REQUEST,
  REVIEW_POST_SUCCESS,
  REVIEW_POST_FAIL,
  REVIEW_HIDE_SUCCESS,
  LIBRARY_VIEW_SAVE
} from '@peoplenet/vi-components-ui/src/actions/constants';


const MEDIA_ENDPOINT = '/medias';


// Get Medias by using params
const fetchMedia = params => ({
  [CALL_API]: {
    types: [MEDIA_GET_REQUEST, MEDIA_GET_SUCCESS, MEDIA_GET_FAIL],
    endpoint: `${MEDIA_ENDPOINT}?${params}`
  }
});

const fetchCoach = mediaId => ({
  [CALL_API]: {
    types: [COACH_GET_REQUEST, COACH_GET_SUCCESS, COACH_GET_FAIL],
    endpoint: `${MEDIA_ENDPOINT}/${mediaId}/coach`
  }
});

// Get Media by using params
export const getMedia = params => dispatch => {
  const query = Object.assign(params, {format: 'json', fetched: 'all'});
  return dispatch(fetchMedia(qs.stringify(query)));
};

export const openMedia = params => dispatch => {
  const {mediaId, camera} = params;
  const query = {mediaId, camera};
  dispatch(push(`/player?${querystring.stringify(query)}`));
};

export const searchReview = (params, previouslyReviews, callBack = () => {}) => async dispatch => {
  dispatch({type: REVIEW_SEARCH_REQUEST, payload: {page: params.page}});
  try {
    const data = await PlayerService.searchReview(params);
    const {review, total} = data;
    dispatch({
      type: REVIEW_SEARCH_SUCCESS,
      payload: {
        review, total, page: params.page, previouslyReviews
      }
    });
    callBack(data);
  } catch (e) {
    dispatch({type: REVIEW_SEARCH_FAIL, e});
  }
};

export const getReview = params => async dispatch => {
  if (!params.mediaId) {
    return;
  }
  dispatch({type: REVIEW_GET_REQUEST});
  try {
    const review = await PlayerService.getReview(params.mediaId);
    dispatch({type: REVIEW_GET_SUCCESS, payload: review});
  } catch (e) {
    dispatch({type: REVIEW_GET_FAIL, e});
  }
};

export const postReview = params => async dispatch => {
  try {
    dispatch({type: REVIEW_POST_REQUEST});
    const review = await PlayerService.postReview(params);
    dispatch({type: REVIEW_POST_SUCCESS, payload: {...params, review: {...params.review, ...review}}});
  } catch (exception) {
    dispatch({type: REVIEW_POST_FAIL, exception});
  }
};

export const hidePostSuccess = () => dispatch => dispatch({type: REVIEW_HIDE_SUCCESS});

export const getCoach = params => dispatch => {
  if (!params.mediaId) {
    return;
  }
  dispatch(fetchCoach(params.mediaId));
};

export const setCoach = body => async dispatch => {
  dispatch({type: COACH_SET_REQUEST});
  try {
    const post = await PlayerService.postCoach(body);
    dispatch({type: COACH_SET_SUCCESS, payload: post, body});
    return true;
  } catch (e) {
    console.error('Unable to create a coach', e);
    dispatch({type: COACH_SET_FAIL});
    return false;
  }
};

export const getComments = mediaId => async dispatch => {
  dispatch({type: COMMENTS_GET_REQUEST});
  try {
    const payload = await PlayerService.getComments(mediaId);
    dispatch({type: COMMENTS_GET_SUCCESS, payload});
  } catch (e) {
    console.error('Unable to fetch a comments', e);
    dispatch({type: COMMENTS_GET_FAIL});
  }
};

export const postComment = (mediaId, body, commentId) => async dispatch => {
  try {
    const payload = await PlayerService.postComment(mediaId, body, commentId);
    let postData = {};
    if (commentId) {
      postData = {text: body.text};
    } else {
      postData = {...body};
    }
    dispatch({type: COMMENTS_POST_SUCCESS, payload: {id: commentId, ...postData, ...payload}});
    return true;
  } catch (e) {
    console.error('Unable to post comments', e);
    return false;
  }
};

export const updateFlag = (flagged) => async dispatch => {
  dispatch({type: MEDIA_FLAG_UPDATE, payload: {flagged}});
};

export const deleteComment = commentId => async dispatch => {
  try {
    await PlayerService.deleteComment(commentId);
    dispatch({type: COMMENTS_DELETE_SUCCESS, payload: {id: commentId}});
    return true;
  } catch (e) {
    console.error('Unable to delete comment', e);
    return false;
  }
};

export const saveLibraryView = params => async dispatch => {
  dispatch({type: LIBRARY_VIEW_SAVE, payload: params});
};

export const openLibraryPage = () => dispatch => dispatch(push('/library'));

export const clear = () => dispatch => dispatch({type: CLEAR_PLAYER});
