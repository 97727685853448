/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {I18n} from 'react-redux-i18n';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Icon from '@material-ui/core/Icon';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

import ErrorSnackbar from '../../components/ErrorSnackbar';
import MobileRequestQueue from '../component/MobileRequestQueue';
import RequestForm from '../component/RequestForm';

function TabContainer(props) {
  return <div>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class MobileOnDemand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      actionError: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.actionError) {
      this.setState({actionError: true});
    }
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.clearTables();
  }

  loadQueueList(tabIndex) {
    if (tabIndex === 1) {
      this.props.loadQueueList({page: 0});
    } else if (tabIndex === 2) {
      const {previouslyRequestedQueue: {sort, sortOrder}} = this.props;
      this.props.loadPreviouslyQueueList({page: 0, sort, sortOrder});
    } else {
      this.props.clearTablesList();
    }
  }

  handleChangeTab = (event, index) => {
    this.setState({tabIndex: index});
    this.loadQueueList(index);
  }

  showListHeaders = () => {
    return (this.state.tabIndex > 0 &&
    (this.props.previouslyRequestedQueue.list.length > 0 ||
      this.props.requestQueue.list.length > 0));
  }

  handleChangeTabIndex = index => {
    this.setState({tabIndex: index});
    this.loadQueueList(index);
  }

  handleSortTable = sort => () => {
    const {previouslyRequestedQueue: queue} = this.props;
    const sortOrder = (queue.sort === sort && queue.sortOrder === 'desc') ? 'asc' : 'desc';

    this.props.loadPreviouslyQueueList({page: 0, sort, sortOrder});
  }

  handleActionErrorClose = () => {
    this.setState({actionError: false});
  }

  renderHeader = () => {
    if (this.state.tabIndex === 2) {
      return this.renderSortHeader('requestDate', 'dateTime');
    }

    return I18n.t('page.onDemand.requestQueue.dateTime');
  }

  renderSortHeader = (sort, translationKey) => {
    const {previouslyRequestedQueue: queue} = this.props;
    return (
      <div className={queue.sort === sort ? 'table__sortcont table__sortcont-sorted' : 'table__sortcont'} onClick={this.handleSortTable(sort)}>
        {I18n.t(`page.onDemand.requestQueue.${translationKey}`)}
        {this.renderSortIcon(sort)}
      </div>
    );
  }

  renderSortIcon = sort => {
    const {previouslyRequestedQueue: queue} = this.props;

    let iconClass = 'sortupward';
    let iconName = 'arrow_downward';

    if (queue.sort === sort && queue.sortOrder === 'asc') {
      iconClass = 'sortdownward';
      iconName = 'arrow_downward';
    }

    return (
      <Icon className={`table__arrow ${iconClass}`}>{iconName}</Icon>
    );
  }

  render() {
    return (
      <Grid container className="ondemand__mobile">
        <Grid item xs={12}>
          <AppBar position="static" color="default" className="ondemand__mobiletabs">
            <Tabs
              value={this.state.tabIndex}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label={I18n.t('page.onDemand.form.title')} className="ondemand__mobiletabs-tab" id="requestForm" />
              <Tab label={I18n.t('page.onDemand.tabs.requestQueue')} className="ondemand__mobiletabs-tab" id="requestQueueTab" />
              <Tab label={I18n.t('page.onDemand.tabs.previouslyRequested')} className="ondemand__mobiletabs-tab" id="previouslyRequestedTab" />
            </Tabs>
          </AppBar>
          {this.showListHeaders() &&
          <div className="ondemand__tableheader-mobile">
            <Table className="table__contnh">
              <TableHead>
                <TableRow className="table__head">
                  <TableCell className="ondemand__vehiclecell-mobile" style={{paddingLeft: '48px'}}>
                    {I18n.t('page.onDemand.requestQueue.vehicle')}
                  </TableCell>
                  <TableCell className="ondemand__datetimecell-mobile">{this.renderHeader()}</TableCell>
                  <TableCell className="ondemand__viewscell-mobile">{I18n.t('page.onDemand.requestQueue.cameraViewsLabel')}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>}
          <SwipeableViews
            index={this.state.tabIndex}
            onChangeIndex={this.handleChangeTabIndex}
          >
            <TabContainer>
              <RequestForm isInternalUser={this.props.isInternalUser} />
            </TabContainer>
            <TabContainer>
              <MobileRequestQueue
                type="current"
                visible={(this.state.tabIndex === 1)}
                requests={this.props.requestQueue}
                loadRequests={this.props.loadQueueList}
                canChangeStatus={!this.props.isInternalUser}
              />
            </TabContainer>
            <TabContainer>
              <MobileRequestQueue
                type="previously"
                visible={(this.state.tabIndex === 2)}
                requests={this.props.previouslyRequestedQueue}
                shouldSort
                loadRequests={this.props.loadPreviouslyQueueList}
                canChangeStatus={!this.props.isInternalUser}
              />
            </TabContainer>
          </SwipeableViews>

          <ErrorSnackbar
            open={this.state.actionError}
            onClose={this.handleActionErrorClose}
          />
        </Grid>
      </Grid>
    );
  }
}

export default MobileOnDemand;
