import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import {concat, difference, every, find, filter, flatMap, flattenDeep, get, includes, join, map, uniq, uniqBy, union} from 'lodash';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export default class MultipleSelectFilter extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object)
    }))
  }

  static defaultProps = {
    value: [],
    options: []
  }

  constructor(props) {
    super(props);
    const {value, options} = props;

    this.state = {
      selected: [],
      leveredOptions: this.getAvailableOptions(options)
    };

    let selectedValues = [];
    for (let i = 0; i < value.length; i++) {
      if ((filter(this.state.leveredOptions, {id: value[i]})).length > 0) {
        selectedValues.push(value[i]);
      }
    }

    const withChildrenSelected = this.getRootWhereAllChildrenAreSelected(selectedValues);
    selectedValues = concat(selectedValues, withChildrenSelected);
    this.state.selected = uniq(selectedValues);
  }

  getOptionById = id => {
    return find(this.state.leveredOptions, {id});
  }

  getAvailableOptions = options => {
    const levelOptions = op => {
      return flatMap(op, option => {
        const {
          id, name, root, children, selectedLabel
        } = option;
        return concat({
          id, name, root, children, selectedLabel
        }, levelOptions(get(option, 'children')));
      });
    };

    return levelOptions(options);
  }

  getRootWhereAllChildrenAreSelected = values => {
    return flatMap(this.state.leveredOptions, it => {
      if (it.root && !includes(values, it.id)) {
        if (every(get(it, 'children', []), child => includes(values, child.id))) {
          return [it.id];
        }
      }
      return [];
    });
  }

  handleChange = event => {
    let values = get(event, 'target.value');
    const deselect = difference(this.state.selected, values);

    const getChildrenIds = (option, includeRoots) => map(get(option, 'children', []), it => {
      if (includeRoots || !it.root) {
        return union([it.id], getChildrenIds(it));
      }
      return union([], getChildrenIds(it));
    });
    const toRemove = flattenDeep(map(deselect, value => {
      const selected = this.getOptionById(value);
      if (selected.root) {
        return getChildrenIds(selected);
      }
      return value;
    }));

    const getRootWithUnselectedChild = option => {
      const populatedOption = this.getOptionById(option);
      return map(get(populatedOption, 'children', []), child => {
        if (includes(toRemove, child.id)) {
          return option;
        }
        return map(get(child, 'children', []), getRootWithUnselectedChild);
      });
    };

    const rootsToRemove = flattenDeep(map(this.state.selected, getRootWithUnselectedChild));
    values = filter(values, it => !includes(toRemove, it) && !includes(rootsToRemove, it));
    values = concat(values, this.getRootWhereAllChildrenAreSelected(values));

    let selected = uniqBy(flattenDeep(map(values, op => {
      const option = find(this.state.leveredOptions, {id: op});
      const children = ch => map(get(ch, 'children', []), it => concat([it], children(it)));
      return concat([option], children(option));
    })), 'id');


    values = uniq(flattenDeep(map(selected, op => {
      if (op.root) {
        return getChildrenIds(op);
      }
      return op.id;
    })));

    selected = map(selected, it => it.id);

    this.setState({selected});
    this.props.onChange(values);
  }

  renderValue = value => {
    const getSelectedValue = id => {
      const selected = find(this.state.leveredOptions, op => op.id === id);
      if (selected.root) {
        return [];
      }
      return get(selected, 'selectedLabel', get(selected, 'name'));
    };

    return join(flatMap(value, getSelectedValue), ', ');
  }

  renderOption = (option, paddingLeft = 0, checked) => {
    const isChecked = checked || includes(this.state.selected, option.id);
    let options = [];
    options = (
      <li
        key={option.id}
        value={option.id}
        style={{paddingLeft: `${paddingLeft}px`}}
      >
        {/* TODO: remove checkbox when rendering selected values */}
        <Checkbox checked={isChecked} />
        {option.name}
      </li>
    );

    const children = get(option, 'children', []);
    options = concat(options,
      map(children, child => this.renderOption(child, paddingLeft + 20, isChecked)));
    return options;
  }

  renderOptions = () => {
    return map(this.props.options, item => this.renderOption(item));
  }

  render() {
    const {isMobile} = this.props;

    return (
      <FormControl className="librarylist__formcontrol">
        <InputLabel htmlFor="name-multiple">{this.props.label}</InputLabel>
        <Select
          multiple
          label={this.props.label}
          value={this.state.selected}
          onChange={this.handleChange}
          input={<Input id={this.props.id} />}
          inputProps={{
            id: this.props.id
          }}
          renderValue={this.renderValue}
          MenuProps={{
            PaperProps: {
              style: {
                minWidth: 'auto'
              }
            },
            classes: {
              paper: 'multipleselect__paper-' + (isMobile?'mobile':'desktop')
            }
          }}
        >
          { this.renderOptions() }
        </Select>
      </FormControl>
    );
  }
}
