import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import moment from 'moment';
import {get} from 'lodash';
import ReactGA from 'react-ga';
import {I18n} from 'react-redux-i18n';
import Review from '../container/Review';
import ReviewDetailsMobile from './review/ReviewDetailsMobile';
import Coaching from '../container/Coaching';
import formatOer from '@peoplenet/vi-components-ui/src/app/formatter';
import VideoTagging from '../../components/VideoTagging';

function TabContainer(props) {
  return <div className="media__details">{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styleSheet = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  label: {
    fontWeight: 'bold',
    fontSize: '1rem'
  }
});

class MediaDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  handleChange = (event, value) => {
    this.setState({value});
    this.trackEvent(value);
  };

  handleChangeIndex = index => {
    this.setState({value: index});
    this.trackEvent(index);
  };

  trackEvent = index => {
    ReactGA.event({
      category: 'Player',
      action: (index === 0) ? 'Review and Coaching Tab' : 'Full Details Tab',
      label: 'Video Details Tab'
    });
  }

  formatDate = date => {
    let dateAux = date;
    if (typeof (date) === 'string') {
      dateAux = parseInt(date, 10);
    }

    return moment(new Date(dateAux)).format(I18n.t('configuration.dateTimeFormat'));
  };

  formatAddress = address => `${address.street}, ${address.city}, ${address.state}`;

  renderTriggerTypeAndOer = () => {
    const {relatedItems} = this.props.media;
    const {metadata} = this.props.media;

    const renderTriggerType = () => {
      if (metadata.triggerType === 'onDemand') {
        return null;
      } else if (relatedItems && relatedItems.oerEvent) {
        return `${formatOer(relatedItems.oerEvent.trigger)} / `;
      } else if (metadata.EventType) {
        return `${metadata.EventType}} / `;
      }
      return null;
    };


    return (
      <TableRow>
        <TableCell className="fulldetailstabledesktop__firstcol">
          {I18n.t('page.playerPage.mediaDetails.oerType')}
        </TableCell>

        <TableCell>
          {renderTriggerType()}
          {this.props.media.metadata.triggerType}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const {
      classes,
      options,
      media,
      coach
    } = this.props;

    if (media != null && options.show) {
      const hasMetadata = media.metadata != null;

      const pairingMethod = get(media, 'metadata.link.type', 'dsn').toUpperCase();
      const linkValue = get(media, 'metadata.link.value', get(media, 'dsn'));

      const componentsKey = get(this.props.review, 'updated') + get(coach, 'id');

      return (
        <div className={classes.root} style={{width: '100%'}} >
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label={I18n.t('page.playerPage.mediaDetails.review').toUpperCase()} id="playerReviewTab" />
              <Tab label={I18n.t('page.playerPage.mediaDetails.coaching').toUpperCase()} id="playerCoachingTab" />
              <Tab label={I18n.t('page.playerPage.mediaDetails.fullDetails').toUpperCase()} id="playerFulldetailsTab" />
            </Tabs>
          </AppBar>
          <SwipeableViews index={this.state.value} onChangeIndex={this.handleChangeIndex}>
            <TabContainer>
              <div>
                <VideoTagging isInternalSalesUser={this.props.isInternalSalesUser} media={media} coach={coach} onSuccess={options.events.onTagSuccess} />
                <Review
                  visible={this.state.value === 0}
                  component={ReviewDetailsMobile}
                  key={componentsKey}
                />
              </div>
            </TabContainer>
            <TabContainer>
              <Coaching
                key={this.props.review.updated}
                onChangeComment={this.props.onChangeCoachingComment}
                coachingComment={this.props.coachingComment}
                isMobile
              />
            </TabContainer>
            <TabContainer>
              <div>
                <Table className="fulldetailstable">
                  <TableBody>
                    {linkValue != null && (
                      <TableRow>
                        <TableCell>
                          {pairingMethod === 'DSN' &&
                            (I18n.t('page.playerPage.mediaDetails.dsn'))
                          }
                          {pairingMethod === 'VIN' &&
                            (`VIN`)
                          }
                        </TableCell>
                        <TableCell>
                          {linkValue}
                        </TableCell>
                      </TableRow>
                    )}
                    {media.vehicle != null && (
                      <TableRow>
                        <TableCell>
                          {I18n.t('page.playerPage.mediaDetails.vehicle')}
                        </TableCell>
                        <TableCell>
                          {media.vehicle}
                        </TableCell>
                      </TableRow>
                    )}
                    {hasMetadata && media.metadata.driverName1 != null && (
                      <TableRow>
                        <TableCell>
                          {I18n.t('page.playerPage.mediaDetails.driver')}
                        </TableCell>
                        <TableCell>
                          {media.metadata.driverName1}
                        </TableCell>
                      </TableRow>
                    )}
                    {hasMetadata &&
                      media.metadata.terminal != null &&
                      media.metadata.terminal.name &&
                      (
                        <TableRow>
                          <TableCell>
                            {I18n.t('page.playerPage.mediaDetails.terminal')}
                          </TableCell>
                          <TableCell>
                            {media.metadata.terminal.name}
                          </TableCell>
                        </TableRow>
                      )}
                    {media.mediaDate != null && (
                      <TableRow>
                        <TableCell>
                          {I18n.t('page.playerPage.mediaDetails.eventTime')}
                        </TableCell>
                        <TableCell>
                          {this.formatDate(media.mediaDate)}
                        </TableCell>
                      </TableRow>
                    )}
                    {this.renderTriggerTypeAndOer()}
                    {media.location != null && (
                      <TableRow>
                        <TableCell>
                          {I18n.t('page.playerPage.mediaDetails.geolocation')}
                        </TableCell>
                        <TableCell>
                          {media.location.lat}, {media.location.lon}
                        </TableCell>
                      </TableRow>
                    )}
                    {media.address != null && (
                      <TableRow>
                        <TableCell>
                          {I18n.t('page.playerPage.mediaDetails.location')}
                        </TableCell>
                        <TableCell>
                          {this.formatAddress(media.address)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TabContainer>
          </SwipeableViews>
        </div>
      );
    }
    return null;
  }
}

MediaDetails.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired
};

export default withStyles(styleSheet)(MediaDetails);
