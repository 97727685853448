/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {I18n} from 'react-redux-i18n';
import {pipe} from 'lodash/fp';
import {get} from 'lodash';
import ReviewStatus from './ReviewStatus';
import moment from '@peoplenet/vi-components-ui/src/app/moment';


const parseDate = pipe(
  date => parseInt(date, 10),
  date => moment(date).format(I18n.t('configuration.dateTimeFormat'))
);


const displayCoach = ({coachName, date}) => `${coachName} ${I18n.t('page.playerPage.reviewDetails.on')} ${parseDate(date)}`;
const displayReviewer = ({reviewerName, date}) => `${reviewerName} ${I18n.t('page.playerPage.reviewDetails.on')} ${parseDate(date)}`;


const calculateReviewOptions = reviewOptions =>
  reviewOptions.reduce((acc, reviewOption) => {
    if ((reviewOption.marked) && reviewOption.points) {
      return acc + reviewOption.points;
    }
    return acc;
  }, 0);

const calculatePoints = ({mainCategory, otherCategories}) => {
  let points = calculateReviewOptions(get(mainCategory, 'reviewOptions', []));
  if (otherCategories) {
    Object.keys(otherCategories)
      .forEach(key => {
        points += calculateReviewOptions(otherCategories[key].reviewOptions);
        return key;
      });
  }
  return points;
};

const mapLocation = pipe(
  (...location) => location.filter(hasValue => hasValue),
  address => address.join(', ')
);


const reviewSummary = props => {
  const {
    address = {},
    showScore = false,
    review,
    coach,
    savedReview
  } = props;
  const {street, city, state} = address || {};

  const {
    media: {
      mediaDate,
      metadata: {
        driverName1,
        vehicle
      },
      tag,
      metadata
    }
  } = props;

  return (
    <section className="reviewdesktop__summary">
      {showScore &&
      <div className="reviewdesktop__summary-row">
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.videoReviewTotal')}</label>
          <span>{review ? calculatePoints(review) : ''}</span>
        </p>
        <p>
          <ReviewStatus
            tagReviewed={get(tag, 'review.value', false)}
            coachCompleted={get(coach, 'completed', false)}
            reviewCompleted={savedReview.completed}
            reviewed={get(metadata, 'review', false)}
          />
        </p>
      </div>
      }
      <div className="reviewdesktop__summary-row">
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.driverName')}</label>
          <span>{driverName1}</span>
        </p>
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.reviewer')}</label>
          <span>{savedReview && savedReview.date ? displayReviewer(savedReview) : ''}</span>
        </p>
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.dateTime')}</label>
          <span>{parseDate(mediaDate)}</span>
        </p>
      </div>
      <div className="reviewdesktop__summary-row">
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.vehicle')}</label>
          <span>{vehicle}</span>
        </p>
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.coach')}</label>
          <span>{coach && coach.date ? displayCoach(coach) : ''}</span>
        </p>
        <p>
          <label>{I18n.t('page.playerPage.reviewDetails.location')}</label>
          <span>{mapLocation(street, city, state)}</span>
        </p>
      </div>
    </section>
  )
};

export default reviewSummary;
