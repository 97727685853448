import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {get, find} from 'lodash';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {defaultLanguage} from '@peoplenet/vi-components-ui/src/app/translation';
import {setUserLanguage} from '@peoplenet/vi-components-ui/src/app/user/UserActions';
import EnglishUS from '../images/flags/Flag-EnglishUS.svg';
import PortugueseBR from '../images/flags/Flag-PortugueseBR.svg';
import DutchFlag from '../images/flags/Flag-Dutch.svg';
import FrenchFlag from '../images/flags/Flag-French.svg';
import GermanFlag from '../images/flags/Flag-German.svg';
import PolishFlag from '../images/flags/Flag-Polish.svg';

const langOptions = [
  {
    id: 'pt-br',
    name: 'page.settings.language.portugueseBR',
    flag: PortugueseBR
  },
  {
    id: 'en-us',
    name: 'page.settings.language.englishUS',
    flag: EnglishUS
  },
  {
    id: 'pl',
    name: 'page.settings.language.polish',
    flag: PolishFlag
  },
  {
    id: 'nl',
    name: 'page.settings.language.dutch',
    flag: DutchFlag
  },
  {
    id: 'fr',
    name: 'page.settings.language.french',
    flag: FrenchFlag
  },
  {
    id: 'de',
    name: 'page.settings.language.german',
    flag: GermanFlag
  }
];

class LanguageSelection extends React.Component {
  state = {
    open: false,
    currentLang: {}
  };

  componentWillMount() {
    let currentLangId = localStorage.getItem('language');
    if (!currentLangId) {
      currentLangId = navigator.language.toLowerCase();
    }

    let currentLang = find(langOptions, {id: currentLangId});
    if (!currentLang) {
      currentLang = find(langOptions, {id: defaultLanguage});
    }
    this.setState({currentLang});
  }

  handleClickLang = () => {
    this.setState({open: !this.state.open});
  };

  handleSwitchLanguage = lang => () => {
    const userInformation = get(this.props, 'user.userInformation', {});
    this.props.setUserLanguage(userInformation.userId, lang.id);
    this.setState({open: false, currentLang: lang});
  }

  renderLanguageSelector = () => {
    const {currentLang: {id: currentLangId}} = this.state;
    return langOptions.map(e => {
      if (e.id === currentLangId) {
        return null;
      }

      return (
        <ListItem button key={`lang-${e.id}`} className="profile-dropdown__langswitch-item" onClick={this.handleSwitchLanguage(e)}>
          <ListItemIcon>
            <img src={e.flag} alt={`flag-${e.id}`} />
          </ListItemIcon>
          <ListItemText inset primary={I18n.t(e.name)} className="profile-dropdown__langswitch-text" />
        </ListItem>
      );
    });
  }

  render() {
    const {currentLang} = this.state;
    return (
      <List component="nav">
        <ListItem button onClick={this.handleClickLang}>
          <ListItemIcon>
            <img src={currentLang.flag} className="profile-dropdown__langswitch-item" alt={`flag-${currentLang.id}`} />
          </ListItemIcon>
          <ListItemText inset primary={I18n.t(currentLang.name)} className="profile-dropdown__langswitch-text" />
          {this.state.open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {this.renderLanguageSelector()}
          </List>
        </Collapse>
      </List>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {setUserLanguage})(LanguageSelection);
