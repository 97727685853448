import React from 'react';
import {I18n} from 'react-redux-i18n';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import {each, get, toLower, includes} from 'lodash';
import PropTypes from 'prop-types';
import moment from '@peoplenet/vi-components-ui/src/app/moment';
import formatOer from '@peoplenet/vi-components-ui/src/app/formatter';
import LineGraph from '../../../components/graph/LineGraph';

const colours = [
  {background: 'rgba(243, 60, 45, 0.3)', border: 'rgba(243, 60, 45, 1)'},
  {background: 'rgba(0, 166, 90, 0.3)', border: 'rgba(0, 166, 90, 1)'},
  {background: 'rgba(3, 169, 244, 0.3)', border: 'rgba(3, 169, 244, 1)'}
];

export default class TopOers extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({}).isRequired,
    amount: PropTypes.number
  }

  static defaultProps = {
    amount: 3
  }

  constructor(props) {
    super(props);
    this.labels = [];
    this.points = [];
    this.hasData = false;
    this.prepareData();
  }

  prepareData = () => {
    const {data, amount} = this.props;

    if (data.oer && data.oer.length > 0) {
      for (let i = 0; i < amount; i++) {
        const entry = {
          data: [],
          backgroundColor: get(colours[i], 'background'),
          borderColor: get(colours[i], 'border'),
          borderWidth: 3,
          label: formatOer(get(data.oer, `[${i}].key`, null))
        };
        // populate array with labels and colors
        this.points.push(entry);
      }


      const entry = data.oer[0];
      each(entry.dates, (dateEntry, dateKey) => {
        const label = moment(dateEntry.key).utc().format(I18n.t('configuration.dateFormat'));
        const isWeek = includes(toLower(dateEntry.key_as_string), 'week');
        this.labels.push(`${isWeek ? I18n.t('page.dashboard.graph.label.weekOf') : ''} ${label}`);

        for (let i = 0; i < amount; i++) {
          const sub = get(data.oer, `[${i}].dates[${dateKey}].doc_count`, 0);
          this.points[i].data.push(sub);
          this.hasData = this.hasData || sub !== 0;
        }
      });
    }
  }

  renderGraph = () => {
    return (
      <LineGraph
        labels={this.labels}
        datasets={this.points}
        locale={this.props.locale}
        height={140}
      />
    );
  }

  render() {
    if (!this.hasData) {
      return (
        <Card className="kpi__graph kpi__container-nodata">
          <Typography variant="title" component="p">
            {I18n.t('page.dashboard.graph.topOers')}
          </Typography>
          <span>
            <Icon className="insert_chart">insert_chart</Icon>
            {I18n.t('page.dashboard.graph.noData')}
          </span>
        </Card>
      );
    }

    return (
      <Card className="kpi__graph">
        <Typography variant="title" component="h2">
          {I18n.t('page.dashboard.graph.topOers')}
        </Typography>
        <CardContent>
          {this.renderGraph()}
        </CardContent>
      </Card>
    );
  }
}
