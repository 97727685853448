import {push} from 'connected-react-router';
import DeleteService from './service/DeleteVideoService';
import {
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE
} from './DeleteActionsType';

export default videoId => async dispatcher => {
  dispatcher({type: DELETE_VIDEO_REQUEST});
  try {
    await DeleteService.remove(videoId);
    dispatcher({type: DELETE_VIDEO_SUCCESS});
    dispatcher(push('/library'));
  } catch (e) {
    console.error('Unable to delete video', e);
    dispatcher({type: DELETE_VIDEO_FAILURE});
  }
};
